import { HttpParams } from "@angular/common/http";
import { Component, Inject, ViewChild, forwardRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import * as _ from 'lodash';
import { ErrorMessages } from "../../../common/constants";
import { Command, Firmware, Recipe } from "../../../model";
import { BulkUpdateType } from "../../../model/bulk-update";
import { BulkUpdateResourcesResponseItem } from "../../../model/bulk-update-resource-response-item";
import { ErrorUtility } from "../../../utility/error-utility";
import { LocalizationPipe } from "../../pipe";
import { BulkOperationDialogService } from "./bulk-operation-dialog.service";

@Component({
    selector: 'bulk-operation-dialog',
    template: require('./bulk-operation-dialog.component.html'),
    styles: [require('./bulk-operation-dialog.component.css')]
})
export class BulkOperationDialogComponent {

    @ViewChild('form') form: NgForm;

    @ViewChild(MatStepper) stepper: MatStepper;

    resourceItems: BulkUpdateResourcesResponseItem[];
    operationType: BulkUpdateType;
    error: string;
    title: string;
    description: string;
    placeholder: string;
    emptyMessage: string;
    noActionMessage: string;
    loaded: boolean;
    updating: boolean;

    private data: BulkOperationDialogData;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<BulkOperationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => BulkOperationDialogService)) private bulkOperationDialogService: BulkOperationDialogService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
    ) {
        this.data = _.cloneDeep(data);
        this.operationType = data.operationType;
        this.title = this.getTitle();
        this.description = this.getDescription();
        this.emptyMessage = this.getEmptyMessage();
        this.noActionMessage = this.getNoActionMessage();
        this.placeholder = this.getPlaceholder();
        let resourcePromise: Promise<BulkUpdateResourcesResponseItem[]>;
        if (data.bulkUpdateItems) {
            resourcePromise = Promise.resolve(data.bulkUpdateItems);
        } else if (data.mapTagging) {
            resourcePromise = this.bulkOperationDialogService.getResourceItemsByArea(this.operationType, data.selectedCoordinates, data.areaCoordinates, data.searchParams);
        } else {
            resourcePromise = Promise.resolve([]);
        }
        resourcePromise.then(items => {
            this.resourceItems = items;
            this.error = null;
            this.loaded = true;
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            this.loaded = true;
        });
    }


    private getTitle(): string {
        switch (this.operationType) {
            case BulkUpdateType.FIRMWARE:
                return "updateFirmwareProperty";
            case BulkUpdateType.COMMAND:
                return "executeCommandProperty";
            case BulkUpdateType.RECIPE:
                return "applyRecipeProperty";
            default:
                return "";
        }
    }

    private getDescription(): string {
        switch (this.operationType) {
            case BulkUpdateType.FIRMWARE:
                return "updateFirmwareDescriptionProperty";
            case BulkUpdateType.COMMAND:
                return "executeCommandDescriptionProperty";
            case BulkUpdateType.RECIPE:
                return "applyRecipeDescriptionProperty";
            default:
                return "";
        }
    }

    private getEmptyMessage(): string {
        switch (this.operationType) {
            case BulkUpdateType.FIRMWARE:
                return "firmwareEmptyMessage";
            case BulkUpdateType.COMMAND:
                return "commandsEmptyMessage";
            case BulkUpdateType.RECIPE:
                return "recipesEmptyMessage";
            default:
                return "";
        }
    }

    private getNoActionMessage(): string {
        switch (this.operationType) {
            case BulkUpdateType.FIRMWARE:
                return "noActionUpdateFirmware";
            case BulkUpdateType.COMMAND:
                return "noActionExecuteCommand";
            case BulkUpdateType.RECIPE:
                return "noActionApplyRecipe";
            default:
                return "";
        }
    }

    back(): void {
        if (this.stepper?.selectedIndex == 0) {
            this.dialogRef.close();
        } else {
            this.stepper.previous();
        }
    }

    next(): void {
        if (this.stepper?.selectedIndex == 1) {
            this.performBulkUpdate();
        } else {
            this.stepper.next();
        }
    }

    private performBulkUpdate(): void {
        this.updating = true;
        const rawValues = this.form.form.getRawValue();
        const thingDefinitionValues = rawValues['operationSelection'];
        const operationSchedulingValues = rawValues['operationScheduling'];
        let thingDefinitionResourceMap: { [thingDefinitionId: string]: string } = {};
        this.resourceItems.forEach(resource => {
            if (thingDefinitionValues[resource.thingDefinition.id]) {
                thingDefinitionResourceMap[resource.thingDefinition.id] = thingDefinitionValues[resource.thingDefinition.id];
            }
        });
        let body = {
            thingDefinitionResourceMap: thingDefinitionResourceMap,
            name: thingDefinitionValues.name || this.placeholder,
            type: this.operationType,
        };
        if (operationSchedulingValues.timing == 'POSTPONED') {
            const date = new Date(operationSchedulingValues.date + " " + operationSchedulingValues.time).getTime();
            body['scheduleTimestamp'] = date;
        }

        let bulkOperationPromise;
        if (this.data.mapTagging) {
            body['areaCoordinates'] = this.data.areaCoordinates;
            body['selectedCoordinates'] = this.data.selectedCoordinates;
            bulkOperationPromise = this.bulkOperationDialogService.performBulkOperationByArea(body, this.data.searchParams);
        } else {
            if (!this.data.allElementsSelected) {
                body['thingIds'] = this.data.selectedThingIds;
            }
            bulkOperationPromise = this.bulkOperationDialogService.performBulkOperationByIds(body, this.data.searchParams, this.data.allElementsSelected);
        }
        bulkOperationPromise.then(() => {
            this.error = null;
            this.dialogRef.close(operationSchedulingValues.timing);
        }).catch(err => {
            this.updating = false;
            this.error = ErrorUtility.getMessage(err, ErrorMessages.UPDATE_DATA_ERROR);
        });
    }

    printThingCount(item: BulkUpdateResourcesResponseItem) {
        return " (" + item.thingCount + " " + this.localizationPipe.transform("things found") + ")";
    }

    printName(resource: (Command | Recipe | Firmware)): string {
        switch (this.operationType) {
            case BulkUpdateType.FIRMWARE:
                const name = this.localizationPipe.transform(resource.name);
                const version = resource['version'] ? (' - ' + resource['version']) : "";
                const suffix = resource['currentVersion'] ? (' (' + this.localizationPipe.transform('Current') + ')') : '';
                return name + version + suffix;
            default:
                return this.localizationPipe.transform(resource.name);
        }
    }

    getDefaultValue(resource: BulkUpdateResourcesResponseItem): any {
        switch (this.operationType) {
            case BulkUpdateType.FIRMWARE:
                const defaultElement = resource.resources.find(el => el['currentVersion']);
                return defaultElement?.id;
            default:
                return null;
        }
    }

    private getPlaceholder(): string {
        switch (this.operationType) {
            case BulkUpdateType.FIRMWARE:
                return this.localizationPipe.transform("Update firmware: ");
            case BulkUpdateType.COMMAND:
                return this.localizationPipe.transform("Execute Commands: ");
            case BulkUpdateType.RECIPE:
                return this.localizationPipe.transform("Apply Recipes: ");
            default:
                return "";
        }
    }

    updatePlaceholder() {
        const rawValues = this.form.form.getRawValue();
        const thingDefinitionValues = rawValues['operationSelection'];
        let result = new Set<string>();
        this.resourceItems.forEach(resource => {
            if (thingDefinitionValues[resource.thingDefinition.id]) {
                result.add(
                    resource.resources.find(
                        res => res.id == thingDefinitionValues[resource.thingDefinition.id]
                    ).name
                );
            }
        });

        this.placeholder = this.getPlaceholder() + [...result].join(", ");
    }
}

export class BulkOperationDialogData {
    operationType: BulkUpdateType;
    selectedThingIds: string[];
    allElementsSelected: boolean;
    searchParams: HttpParams;
    selectedCoordinates: any[];
    areaCoordinates: any[];
    mapTagging: boolean;
    bulkUpdateItems: BulkUpdateResourcesResponseItem[];
}