import { FlatTreeControl } from "@angular/cdk/tree";
import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, Inject, Input, Output, forwardRef } from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { ErrorMessages } from "../../../common/constants";
import { ProductModel, ProductModelTechnicalScheme } from "../../../model";
import { FlatTreeNode } from "../../../service/flat-tree.service";
import { ProductModelPartService } from "../../../service/product-model-part.service";
import { ProductModelService } from "../../../service/product-model.service";
import { TreeNode } from "../../../shared/component/tree-list/tree-list.component";
import { ErrorUtility } from "../../../utility/error-utility";

@Component({
    selector: 'product-model-technical-scheme-selector',
    template: require('./product-model-technical-scheme-selector.component.html'),
    styles: [require('./product-model-technical-scheme-selector.component.css')]
})
export class ProductModelTechnicalSchemeSelectorComponent {

    @Input() set productModelId(value: string) {
        this._productModelId = value;
        this.selectedProductModelPartId = null;
        if (value) {
            this.init();
        } else {
            this.loaded = true;
        }
    }

    @Input() productModelPartId: string;

    @Output() productModelPartSelected = new EventEmitter();

    error: string;
    productModel: ProductModel;
    loaded: boolean;
    _productModelId: string;
    selectedProductModelPartId: string;
    showTree: boolean;
    visibleSchemes: ProductModelTechnicalScheme[];

    private transformer = (node: TreeNode, level: number) => {
        return {
            id: node.id,
            name: node.name,
            level: level,
            expandable: (!!node.children && node.children.length > 0),
            element: node.element
        };
    }
    treeControl = new FlatTreeControl<FlatTreeNode>(
        node => node.level, node => node.expandable);
    treeFlattener = new MatTreeFlattener(
        this.transformer, node => node.level,
        node => node.expandable, node => node.children);
    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    constructor(
        @Inject(forwardRef(() => ProductModelService)) private productModelService: ProductModelService,
        @Inject(forwardRef(() => ProductModelPartService)) private productModelPartService: ProductModelPartService
    ) { }

    hasChild = (_: number, node: FlatTreeNode) => node.expandable;

    private init(): void {
        this.loaded = false;
        Promise.all([
            this.productModelService.getById(this._productModelId),
            this.productModelPartService.getRecursivelyAllProductModelParts(0, [], this.getExtraParams())
        ]).then(results => {
            this.productModel = results[0];
            const partialTree = this.productModelPartService.fillTreeNodes(results[1]);
            const newRoot: TreeNode = { id: null, name: this.productModel.name, description: this.productModel.description, children: partialTree, element: results[0] };
            this.dataSource.data = [newRoot];
            this.showTree = partialTree?.length > 0;
            let selectedNode: FlatTreeNode;
            if (this.productModelPartId) {
                const nodeIndex = this.treeControl.dataNodes.findIndex(node => node.id === this.productModelPartId);
                if (nodeIndex > -1) {
                    selectedNode = this.treeControl.dataNodes[nodeIndex];
                    this.selectedProductModelPartId = this.productModelPartId;
                    this.visibleSchemes = nodeIndex > -1 ? selectedNode.element?.technicalSchemes : null;
                    this.expandAncestors(nodeIndex);
                } else {
                    this.error = "Invalid model part";
                }
            } else {
                this.visibleSchemes = this.productModel.technicalSchemes;
                selectedNode = this.treeControl.dataNodes[0];
                this.treeControl.expand(selectedNode);
            }
            this.updateSelectedProductModelPartId(selectedNode);
            this.loaded = true;
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            this.loaded = true;
        });
    }

    private getExtraParams(): HttpParams {
        return new HttpParams().set('productModelId', this._productModelId);
    }

    selectProductModelPart(node: FlatTreeNode): void {
        if (!this.productModelPartId) {
            this.updateSelectedProductModelPartId(node);
        }
    }

    private updateSelectedProductModelPartId(node: FlatTreeNode): void {
        this.selectedProductModelPartId = node.id;
        this.visibleSchemes = node.element?.technicalSchemes;
        this.productModelPartSelected.emit({ id: this.selectedProductModelPartId, hasTechnicalSchemes: this.visibleSchemes?.length > 0 });
    }

    resetSelectedProductModelPartId(): void {
        this.selectedProductModelPartId = null;
        this.visibleSchemes = this.productModel.technicalSchemes;
        this.productModelPartSelected.emit({ id: this.selectedProductModelPartId, hasTechnicalSchemes: this.visibleSchemes?.length > 0 });
    }

    private expandAncestors(index: number): void {
        for (let i = 0; i < index + 1; i++) {
            this.treeControl.expand(this.treeControl.dataNodes[i])
        }
    }
}