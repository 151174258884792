import { AfterContentInit, Component, ContentChildren, forwardRef, Inject, Input, OnInit, QueryList } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { PropertyInfo } from "../../../common/properties";
import { EventDetailsPageDialogComponent } from "../../../dashboard-area/event-details/event-details-page-dialog.component";
import { AlertService } from "../../../dashboard-area/shared/alert.service";
import { HistoricalAlertWorkSessionMatPaginatorIntl } from "../../../dashboard-area/shared/historical-alert-work-session-mat-paginator-intl.service";
import { Alert, AlertDefinition, ListRangeSelectionModeType, PagedList } from "../../../model";
import { AbstractExportContextService } from "../../../service/abstract-export-context.service";
import { AppService } from "../../../service/app.service";
import { AuthenticationService } from "../../../service/authentication.service";
import { NavigationService } from "../../../service/navigation.service";
import { AbstractThingContextService } from "../../../shared/class/abstract-thing-context-service.class";
import { SearchTargetType } from "../../../shared/component/search-field/search-field.component";
import { ButtonActionValue } from "../../../shared/custom-table";
import { COMPONENT_DEFINITION_REF } from "../../../shared/utility/component-definition-token";
import { ErrorUtility } from "../../../utility/error-utility";
import { AdvancedSearchLayoutType, ListWidgetV2Component } from "../../list-widget-v2/list-widget-v2.components";
import { AlertListModeType, DetailsModeType } from "../../shared/alert-work-session-list";
import { ThingListWidgetV2Service } from "../../thing-list/thing-list-widget-v2.service";
import { HistoricalAlertListWidgetV2Service } from "./historical-alert-list-widget-v2.service";

@Component({
    selector: 'historical-alert-list-widget-v2',
    template: require('./historical-alert-list-widget-v2.component.html'),
    providers: [HistoricalAlertListWidgetV2Service, ThingListWidgetV2Service, AlertService, { provide: MatPaginatorIntl, useClass: HistoricalAlertWorkSessionMatPaginatorIntl }],
    styles: [require('../../list-widget-v2/list-widget-v2.css')]
})
export class HistoricalAlertListWidgetV2Component extends ListWidgetV2Component<Alert> implements OnInit, AfterContentInit {

    @Input() searchFields: string[] = ["name", "title", "description", "thing.name", "thingDefinition.name"];

    @Input() enableActions = true;

    @Input() detailsMode: DetailsModeType = DetailsModeType.POPUP;

    @Input() includeSubThingsAlerts: boolean;

    @Input() mode: AlertListModeType = AlertListModeType.TABLE;

    @Input() expandable: boolean = true;

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<any>;

    private defaultProperties: { [name: string]: PropertyInfo } = {
        severity: { label: 'alertDefinitionSeverityProperty', path: 'severity', defaultFilter: null, defaultSorting: null },
        category: { label: 'alertDefinitionCategoryProperty', path: 'category', defaultFilter: null, defaultSorting: null },
        name: { label: 'alertDefinitionNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        title: { label: 'alertDefinitionTitleProperty', path: 'title', defaultFilter: null, defaultSorting: null },
        description: { label: 'alertDefinitionDescriptionProperty', path: 'description', defaultFilter: null, defaultSorting: null },
        date: { label: 'alertDateProperty', path: 'date', defaultFilter: null, defaultSorting: null },
        duration: { label: 'alertDurationProperty', path: 'duration', defaultFilter: null, defaultSorting: null }
    };

    alertDefinitions: AlertDefinition[] = [];
    initCompleted: boolean;
    hidden: boolean;

    constructor(
        @Inject(forwardRef(() => HistoricalAlertListWidgetV2Service)) private historicalAlertListWidgetV2Service: HistoricalAlertListWidgetV2Service,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => ThingListWidgetV2Service)) private thingListWidgetV2Service: ThingListWidgetV2Service,
        @Inject(forwardRef(() => AlertService)) private alertService: AlertService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService
    ) {
        super(appService, authenticationService, exportService, null);
    }

    ngOnInit() {
        this.checkIfHidden();
        this.checkIsMobile();
        if (this.isMobile) {
            this.mode = AlertListModeType.LIST;
        }
        this.handleAdvancedSearchLayoutType("historical-alert-list-popup-advanced-search", SearchTargetType.HISTORICAL_ALERTS);
        if (this.exportEnabled) {
            this.subscribeToExportServices();
        }
    }

    private checkIfHidden(): void {
        if (!this.thingContextService.getCurrentThing()) {
            if (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForOrgPartner');
            } else {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForCustomer');
            }
        }
    }

    ngAfterContentInit(): void {
        this.displayedColumns = this.historicalAlertListWidgetV2Service.getVisibleColumns(this.columnComponents.toArray(), this.defaultProperties, 'Alert');
        this.descriptions = this.historicalAlertListWidgetV2Service.getColumnDescriptions(this.columnComponents.toArray());
        this.sort = [];
        this.elements = [];
        this.alertService.getAlertDefinitions(true).then(alerts => {
            this.alertDefinitions = alerts;
            this.initCompleted = true;
            const storedFieldsValues = localStorage.getItem(this.queryFieldRef || 'historicalAlertAdvancedSearchFieldsValues');
            const savedFieldsValues = storedFieldsValues ? JSON.parse(storedFieldsValues) : null;
            if (!savedFieldsValues && !this.query && !this.thingContextService.getCurrentWorkSession() && !this.queryFieldRef && this.advancedSearchLayout != AdvancedSearchLayoutType.POPUP) {
                this.getAlertList();
            }
        });
    }

    protected updatePaginatorClass(): void {
        this.matPaginatorClass = "mat-paginator-hidden-last ";
        if (this.authenticationService.getTenant().listRangeSelectionMode == ListRangeSelectionModeType.PAGES) {
            this.matPaginatorClass += "mat-paginator-pages-mode";
        } else {
            this.matPaginatorClass += "mat-paginator-hidden-range-label";
        }
    }

    private getAlertList(): void {
        this.historicalAlertListWidgetV2Service.getPagedList(this.pageIndex, this.pageSize, null, null, this.searchFields, this.advancedSearchBody, null, this.includeSubThingsAlerts).then(pagedList => {
            this.error = null;
            this.historicalAlertListWidgetV2Service.updatePageListContent(pagedList.content, this.alertDefinitions, this.displayedColumns, true, this.detailsMode);
            this.updateElementList(pagedList);
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    updateElementList(pagedList: PagedList<Alert>): void {
        if (this.isMobile) {
            this.elements = [...this.elements, ...pagedList.content];
        } else {
            this.elements = pagedList.content;
        }
        this.fillerRowCount = pagedList.numberOfElements;
        this.dataSource = new MatTableDataSource<Alert>(this.elements);
        this.length = (pagedList.number * pagedList.size) + pagedList.numberOfElements + (pagedList.last ? 0 : 1);
        this.pageSize = pagedList.size;
        this.pageIndex = pagedList.number;
        this.totalPages = pagedList.totalPages;
        this.loaded = true;
    }

    refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        this.loaded = false;
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.getAlertList();
    }

    export(): void {
        const params = this.historicalAlertListWidgetV2Service.getParams(this.searchFields, this.advancedSearchBody, true, this.includeSubThingsAlerts);
        this.historicalAlertListWidgetV2Service.downloadCSV(params, this.exportService.resolveExportFileNamePlaceholders(this.exportFileName, this.advancedSearchBody?.startTimestamp, this.advancedSearchBody?.endTimestamp));
    }

    goToAlertDetails(id: string): void {
        if (this.detailsMode == DetailsModeType.PAGE) {
            this.navigationService.navigateTo(['/dashboard/event_details', id]);
        } else if (this.detailsMode == DetailsModeType.POPUP) {
            this.openDialog(id);
        }
    }

    goToDetail(alert: Alert): void {
        this.navigationService.goToThingDetailPage(alert.thing?.id);
    }

    execButtonAction(actionValue: ButtonActionValue): void {
        if (actionValue.action == 'showDetails') {
            this.goToAlertDetails(actionValue.value);
        }
    }

    protected subscribeToExportServices(): void {
        this.exportId = "historical-alert-list-" + this.getNextId();
        this.exportService.subscribeToExport(this.exportId, this.title || "Historical Alerts").subscribe(() => this.export());
        this.subscribeToExportVisibility();
    }

    private openDialog(id: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        dialogConfig.data = { id: id, isHistorical: true };
        this.dialog.open(EventDetailsPageDialogComponent, dialogConfig);
    }

    executeAlertAdvancedSearch(advancedSearchBody: any): void {
        this.elements = [];
        this.executeAdvancedSearch(advancedSearchBody);
    }

    infiniteScrollMobile($event): void {
        if (this.isMobile) {
            const el = <any>($event.srcElement || $event.target);
            if (Math.trunc(el.scrollHeight - el.scrollTop) === el.clientHeight) {
                this.pageIndex++;
                this.getAlertList();
            }
        }
    }
}