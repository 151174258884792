import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { BULK_DELETE_THINGS } from "../../../common/endpoints";
import { HttpService } from "../../../service/http.service";

@Injectable()
export class ThingBulkDeleteDialogService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
    ) { }

    deleteThings(selectedThingIds: string[], params: HttpParams, allElementsSelected: boolean): Promise<void> {
        return firstValueFrom(this.http.post<void>(BULK_DELETE_THINGS, this.getBody(selectedThingIds, allElementsSelected), this.getEnrichedParams(params, allElementsSelected)));
    }

    private getBody(selectedThingIds: string[], allElementsSelected: boolean): any {
        if (!allElementsSelected) {
            return { thingIds: selectedThingIds };
        }
        return {};
    }

    private getEnrichedParams(params: HttpParams, allElementsSelected: boolean): HttpParams {
        if (allElementsSelected) {
            params = params.set('all', true);
        }
        return params;
    }
}