import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from "@angular/core";
import { MAINTENANCE_WORKS, MAINTENANCE_WORK_BY_ID, USER_THING } from "../../common/endpoints";
import { Properties } from '../../common/properties';
import { Location, MaintenanceWork, PagedList, Thing } from "../../model";
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { HttpService } from '../../service/http.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { PropertyComponent } from '../../shared/component';
import { CustomTableColumn, CustomTableService } from '../../shared/custom-table';
import { AbstractListWidgetV2Service } from '../list-widget-v2/abstract-list-widget-v2.service';

@Injectable()
export class MaintenanceRegistryWidgetService extends AbstractListWidgetV2Service<MaintenanceWork> {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService
    ) {
        super(authenticationService, customPropertyService);
    }

    getMaintenanceWork(thing: Thing, location: Location) {
        let param = new HttpParams();
        if (thing) {
            param = new HttpParams().set("thingId", thing.id);
        } else {
            param = new HttpParams().set("locationId", location.id)
        }
        return this.httpService.get<MaintenanceWork[]>(MAINTENANCE_WORKS, param).toPromise();
    }

    deleteMaintenanceWork(id: string): Promise<void> {
        return this.httpService.delete<void>(MAINTENANCE_WORK_BY_ID.replace('{id}', id)).toPromise();
    }

    saveMaintenanceWork(maintenanceWork: MaintenanceWork): Promise<MaintenanceWork> {
        if (maintenanceWork.id) {
            return this.httpService.put<MaintenanceWork>(MAINTENANCE_WORK_BY_ID.replace('{id}', maintenanceWork.id), maintenanceWork).toPromise();
        } else {
            return this.httpService.post<MaintenanceWork>(MAINTENANCE_WORKS, maintenanceWork).toPromise();
        }
    }

    getThingsByLocationId(locationId: string): Promise<Thing[]> {
        const params = new HttpParams().set('locationId', locationId);
        return this.httpService.get<Thing[]>(USER_THING, params).toPromise();
    }

    protected getPropertyColumn(col: PropertyComponent, columnName: string, defaultType: string): CustomTableColumn {
        switch (col.name) {
            case 'startTimestamp':
                return CustomTableService.newDatetimeColumn(col.name, this.getLabel(col, defaultType), 'startTimestamp', null, this.authenticationService.getUser().timezone).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'endTimestamp':
                return CustomTableService.newDatetimeColumn(col.name, this.getLabel(col, defaultType), 'endTimestamp', null, this.authenticationService.getUser().timezone).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'userEmail':
                return CustomTableService.newSimpleColumn(col.name, this.getLabel(col, defaultType), 'userEmail').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'duration':
                return CustomTableService.newTimerColumn(col.name, this.getLabel(col, defaultType), 'startTimestamp', 'endTimestamp').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            default:
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType))
                    .withValueMap(this.getValueMap(col)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
        }
    }

    protected isColumnVisible(columnName: string): boolean {
        if (columnName == 'type') {
            return !!this.authenticationService.getTenant().maintenanceWorkTypes;
        } else if (columnName == 'thingName') {
            return !this.thingContextService.getCurrentThing() && !!this.contextService.getCurrentLocation();
        } else {
            return true;
        }
    }

    protected getLabel(col: PropertyComponent, defaultType: string): string {
        if (col.label) return col.label;
        return Properties.getLabelByName(col.name, defaultType) || col.name;
    }

    protected getCustomPropertyNameAndType(columnName: string): { name: string, type: CustomPropertyType } {
        let name = columnName;
        let type = CustomPropertyType.MaintenanceWork;
        name = name.substring(11);
        return { name, type };
    }

    getPagedList(page: number, size: number, sort: string[], metricNames: Set<string>, searchFields: string[], advancedSearchBody: any): Promise<PagedList<MaintenanceWork>> {
        return null;
    }
}