import { HttpParams } from "@angular/common/http";
import { Component, Inject, ViewChild, forwardRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ErrorMessages, Permissions } from "../../../common/constants";
import { BulkUpdateType } from "../../../model/bulk-update";
import { BulkUpdateResourcesResponse } from "../../../model/bulk-update-resources-response";
import { AuthenticationService } from "../../../service/authentication.service";
import { ErrorUtility } from "../../../utility/error-utility";
import { BulkOperationDialogComponent } from "../bulk-operation-dialog/bulk-operation-dialog.component";
import { BulkOperationSelectionDialogService } from "./bulk-operation-selection-dialog.service";

@Component({
    selector: 'bulk-operation-selection-dialog',
    template: require('./bulk-operation-selection-dialog.component.html'),
    styles: [require('./bulk-operation-selection-dialog.component.css')]

})
export class BulkOperationSelectionDialogComponent {

    @ViewChild('form') form: NgForm;

    error: string;
    loaded: boolean;
    bulkRecipeEnabled: boolean;
    bulkCommandEnabled: boolean;
    bulkFirmwareEnabled: boolean;
    assignParentThingEnabled: boolean;
    allBulkOperationDisabled: boolean;
    selectedBulkOperation: string;

    private bulkOperationResources: BulkUpdateResourcesResponse;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<BulkOperationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => BulkOperationSelectionDialogService)) private bulkOperationSelectionDialogService: BulkOperationSelectionDialogService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) {
        this.bulkOperationSelectionDialogService.getBulkUpdateResourcesById(data.selectedThingIds, data.allElementsSelected, data.searchParams).then(resources => {
            this.bulkOperationResources = resources;
            this.error = null;
            this.handleBulkOperationVisibility();
            this.loaded = true;
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            this.loaded = true;
        });
    }

    private handleBulkOperationVisibility(): void {
        const bulkRecipePermission = this.authenticationService.hasPermission(Permissions.EXECUTE_BULK_UPDATE) && this.authenticationService.hasPermission(Permissions.EXECUTE_THING_COMMAND);
        const bulkCommandPermission = this.authenticationService.hasPermission(Permissions.EXECUTE_BULK_UPDATE) && this.authenticationService.hasPermission(Permissions.EXECUTE_THING_COMMAND);
        const bulkFirmwarePermission = this.authenticationService.hasPermission(Permissions.EXECUTE_BULK_UPDATE) && this.authenticationService.hasPermission(Permissions.UPDATE_FIRMWARE);
        const assignParentThingPermission = this.authenticationService.hasPermission(Permissions.WRITE_THING);
        this.bulkRecipeEnabled = bulkRecipePermission && this.bulkOperationResources.recipeItems?.length > 0;
        this.bulkCommandEnabled = bulkCommandPermission && this.bulkOperationResources.commandItems?.length > 0;
        this.bulkFirmwareEnabled = bulkFirmwarePermission && this.bulkOperationResources.firmwareItems?.length > 0;
        const parentThingsCandidates = this.bulkOperationResources.parentThingItem?.parentThings || {};
        this.assignParentThingEnabled = assignParentThingPermission && Object.keys(parentThingsCandidates).length > 0;
        this.allBulkOperationDisabled = !this.bulkRecipeEnabled && !this.bulkCommandEnabled && !this.bulkFirmwareEnabled && !this.assignParentThingEnabled;
    }

    selectBulkOperation(): void {
        this.dialogRef.close({
            operation: this.selectedBulkOperation,
            bulkOperationItems: this.getBulkItems()
        });
    }

    private getBulkItems(): any {
        switch (this.selectedBulkOperation) {
            case BulkUpdateType.RECIPE:
                return this.bulkOperationResources.recipeItems;
            case BulkUpdateType.COMMAND:
                return this.bulkOperationResources.commandItems;
            case BulkUpdateType.FIRMWARE:
                return this.bulkOperationResources.firmwareItems;
            case "CHANGE_PARENT_THING":
                return this.bulkOperationResources.parentThingItem;
        }
    }

}

export class BulkOperationSelectionDialogData {
    selectedThingIds: string[];
    allElementsSelected: boolean;
    searchParams: HttpParams;
}