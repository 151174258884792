import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { SparePartDefinition, StoreOrderItem, StoreOrderItemType } from "../../../model";

@Component({
    selector: 'spare-part-definition-catalog-list',
    template: require('./spare-part-definition-catalog-list.component.html'),
    styles: [require('./spare-part-definition-catalog-list.component.css')]
})
export class SparePartDefinitionCatalogListComponent {

    @Input() set sparePartDefinitions(values: SparePartDefinition[]) {
        this._sparePartDefinitions = null;
        if (values?.length) {
            this.init(values);
        }
    };

    @Input() addToCartEnabled: boolean;

    @Input() cartUpdating: boolean;

    @Input() sort: any[] = ['', ''];

    @Input() showTechnicalSchemePositions: boolean;

    @Input() hidePrices: boolean;

    @Output() addToCart = new EventEmitter();

    @Output() rowClickAction = new EventEmitter();

    @Output() sortAction = new EventEmitter();

    quantities: { [SparePartDefinitionId: string]: number } = {};
    _sparePartDefinitions: SparePartDefinition[];
    displayedColumns: string[];

    getDisplayedColumns(): string[] {
        let columns = [];
        if (this.showTechnicalSchemePositions) {
            columns.push('technicalSchemePositions');
        }
        columns = columns.concat(['code', 'name', 'description']);
        if (!this.hidePrices) {
            columns.push('price');
        }
        if (this.addToCartEnabled) {
            columns.push('quantity');
        }
        return columns;
    }

    init(sparePartDefinitions: SparePartDefinition[]): void {
        sparePartDefinitions.forEach(sp => {
            this.quantities[sp.id] = 1;
        });
        this.displayedColumns = this.getDisplayedColumns();
        this._sparePartDefinitions = sparePartDefinitions;
    }

    addQuantity(sparePartDefinition: SparePartDefinition): void {
        this.quantities[sparePartDefinition.id]++;
    }

    removeQuantity(sparePartDefinition: SparePartDefinition): void {
        if (this.quantities[sparePartDefinition.id] > 1) {
            this.quantities[sparePartDefinition.id]--;
        }
    }

    addSparePartDefinitionToCart(sparePartDefinition: SparePartDefinition): void {
        let newItem = new StoreOrderItem();
        newItem.itemId = sparePartDefinition.id;
        newItem.type = StoreOrderItemType.SPARE_PART;
        newItem.quantity = this.quantities[sparePartDefinition.id];
        this.addToCart.emit(newItem);
    }

    clickRow(id: string): void {
        this.rowClickAction.emit(id);
    }

    sortChange(sort: Sort): void {
        this.sortAction.emit(sort);
    }

}