import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, forwardRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorMessages } from '../../../../common/constants';
import { ProductModelSparePartDefinitionReference, StoreOrderItem, StoreOrderItemType, Thing } from '../../../../model';
import { SparePartDefinition } from '../../../../model/spare-part-definition';
import { ProductModelSparePartDefinitionReferenceService } from '../../../../service/product-model-spare-part-definition-reference.service';
import { StoreCartService } from '../../../../service/store-cart.service';
import { MessageComponent } from '../../../../shared/component';
import { ErrorUtility } from '../../../../utility/error-utility';
import { SparePartDefinitionService } from '../../../shared/spare-part-definition.service';

@Component({
    selector: 'spare-part-definition-catalog-details-dialog',
    template: require('./spare-part-definition-catalog-details-dialog.component.html'),
    styles: [require('./spare-part-definition-catalog-details-dialog.component.css')]
})
export class SparePartDefinitionCatalogDetailsDialogComponent implements OnInit {

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    error: string;
    loaded: boolean;
    sparePartDefinition: SparePartDefinition;
    selectedImageIndex: number = 0;
    addToCartEnabled: boolean;
    quantity: number = 1;
    cartUpdating: boolean;
    compatibleProductModelList: { name: string, group: string }[];

    private sparePartDefinitionId: string;
    private thing: Thing;

    constructor(
        @Inject(forwardRef(() => SparePartDefinitionService)) private catalogService: SparePartDefinitionService,
        @Inject(forwardRef(() => StoreCartService)) private storeCartService: StoreCartService,
        @Inject(forwardRef(() => ProductModelSparePartDefinitionReferenceService)) private referenceService: ProductModelSparePartDefinitionReferenceService,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<SparePartDefinitionCatalogDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.sparePartDefinitionId = data.sparePartDefinitionId;
        this.thing = data.thing;
    }

    ngOnInit() {
        if (this.sparePartDefinitionId) {
            Promise.all([
                this.catalogService.getSparePartDefinitionById(this.sparePartDefinitionId).then(sp => this.sparePartDefinition = sp),
                this.getReferences()
            ]).then(() => {
                this.loaded = true;
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        } else {
            this.loaded = true;
            this.error = "Missing spare part definition identifier";
        }
        this.addToCartEnabled = this.storeCartService.canAddToCart();
    }

    updateSelectedImageUrl(index: number): void {
        this.selectedImageIndex = index;
    }

    addQuantity(): void {
        this.quantity++;
    }

    removeQuantity(): void {
        if (this.quantity > 1) {
            this.quantity--;
        }
    }

    addItemToCart(): void {
        let newItem = new StoreOrderItem();
        newItem.itemId = this.sparePartDefinition.id;
        newItem.type = StoreOrderItemType.SPARE_PART;
        newItem.quantity = this.quantity;
        this.addToCart(newItem);
    }

    private addToCart(item: StoreOrderItem): void {
        this.cartUpdating = true;
        item.thingId = this.thing?.id;
        this.storeCartService.addItemToCart(item).then(() => {
            this.saveMessage.show();
            this.cartUpdating = false;
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
    }

    private getReferences(): Promise<void> {
        let params = new HttpParams().set('sparePartDefinitionId', this.sparePartDefinitionId);
        return this.referenceService.getRecursivelyAllProductModelSparePartDefinitionReferences(0, [], params).then(references => {
            this.buildCompatibleProductModelList(references.filter(r => r.productModel));
        });
    }

    private buildCompatibleProductModelList(references: ProductModelSparePartDefinitionReference[]): void {
        this.compatibleProductModelList = [];
        if (references?.length) {
            references.forEach(r => {
                if (!this.compatibleProductModelList.some(pm => pm.name == r.productModel.name && pm.group == r.group)) {
                    this.compatibleProductModelList.push({ name: r.productModel.name, group: r.group });
                }
            });
        }
    }
}