import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AlertSeverityOptions } from '../../common/constants';
import { ALERTS, BULK_UPDATES_BY_AREA, COMMANDS_BY_AREA, PARENT_THINGS_BY_AREA, RECIPES_BY_AREA, SET_PARENT_THING_BY_AREA, USER_THING_V2 } from '../../common/endpoints';
import { Alert } from '../../model/alert';
import { BulkUpdate } from '../../model/bulk-update';
import { BulkUpdateResourcesResponseItem } from '../../model/bulk-update-resource-response-item';
import { BulkUpdateParentThingsResponse, Customer, Location, PagedList, Thing } from '../../model/index';
import { HttpService } from '../../service/http.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';

@Injectable()
export class ThingMapService {

    private params: HttpParams;
    private location: Location;
    private customer: Customer;

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
    ) {
        this.params = new HttpParams();
        this.location = this.contextService.getCurrentLocation();
        if (this.location) {
            this.params = this.params.set('locationId', this.location.id);
        } else {
            this.customer = this.contextService.getCurrentCustomer();
            if (this.customer) {
                this.params = this.params.set('customerId', this.customer.id);
            }
        }
    }

    getThings(includeAlertSeverity: boolean, advancedSearchBody: any, searchFields: any): Promise<Thing[]> {
        if (includeAlertSeverity) {
            return this.getThingAndAlertMerged(advancedSearchBody, searchFields);
        } else {
            return this.getAllUserThingsV2(advancedSearchBody, searchFields);
        }
    }

    private getThingAndAlertMerged(advancedSearchBody: any, searchFields: any): Promise<Thing[]> {
        return Promise.all([
            this.getAllUserThingsV2(advancedSearchBody, searchFields),
            this.getUserAlerts()
        ]).then(results => {
            const things: Thing[] = results[0];
            const alerts: Alert[] = results[1];
            things.forEach(thing => thing.alertSeverity = this.findAlertSeverityByThingAndAlerts(thing.id, alerts));
            return things;
        }).catch(err => {
            console.error(err);
            return undefined;
        });
    }

    private getUserAlerts(): Promise<Alert[]> {
        return this.http.get<Alert[]>(ALERTS, this.params).toPromise();
    }

    private findAlertSeverityByThingAndAlerts(thingId: string, alerts: Alert[]): string {
        const thingAlerts = alerts.filter(alert => alert.thing && alert.thing.id === thingId);
        if (thingAlerts.length > 0) {
            if (thingAlerts.some(alert => alert.severity == AlertSeverityOptions.FAILURE.value || alert.severity == AlertSeverityOptions.CRITICAL.value || alert.severity == AlertSeverityOptions.EMERGENCY.value)) {
                return AlertSeverityOptions.CRITICAL.value;
            } else if (thingAlerts.some(alert => alert.severity == AlertSeverityOptions.WARNING.value)) {
                return AlertSeverityOptions.WARNING.value;
            }
            return AlertSeverityOptions.INFORMATIONAL.value;
        }
        return null;
    }

    getResources(body: any, operation: string, advancedSearchBody: any, searchFields: any): Promise<BulkUpdateResourcesResponseItem[]> {
        let params = this.buildParams(0, advancedSearchBody, searchFields);
        if (operation == "COMMAND") {
            return this.http.post<BulkUpdateResourcesResponseItem[]>(COMMANDS_BY_AREA, body, params).toPromise();
        } else if (operation == "RECIPE") {
            return this.http.post<BulkUpdateResourcesResponseItem[]>(RECIPES_BY_AREA, body, params).toPromise();
        }
        return null;
    }

    bulkCommandUpdate(body: any, advancedSearchBody: any, searchFields: any): Promise<BulkUpdate> {
        let params = this.buildParams(0, advancedSearchBody, searchFields);
        return firstValueFrom(this.http.post<BulkUpdate>(BULK_UPDATES_BY_AREA, body, params));
    }

    private getAllUserThingsV2(advancedSearchBody: any, searchFields: any): Promise<Thing[]> {
        let thingList: Thing[] = [];
        return this.getThingsV2(0, advancedSearchBody, searchFields).then(pagedList => {
            thingList = thingList.concat(pagedList.content);
            let promises = [];
            for (let i = 1; i < pagedList.totalPages; i++) {
                promises.push(this.getThingsV2(i, advancedSearchBody, searchFields));
            }
            return Promise.all(promises).then(results => {
                results.forEach(res => {
                    thingList = thingList.concat(res.content)
                });
                return thingList;
            });
        });
    }

    private getThingsV2(page: number, advancedSearchBody: any, searchFields: any): Promise<PagedList<Thing>> {
        let params = this.buildParams(page, advancedSearchBody, searchFields);
        params = params.set('sort', '_id');
        return this.http.get<PagedList<Thing>>(USER_THING_V2, params).toPromise();
    }

    buildParams(page: number, advancedSearchBody: any, searchFields: any): HttpParams {
        let params = new HttpParams();
        this.location = this.contextService.getCurrentLocation();
        if (this.location) {
            params = params.set('locationId', this.location.id);
        } else if (this.contextService.getCurrentCustomer()) {
            params = params.set('customerId', this.contextService.getCurrentCustomer().id);
        } else if (this.contextService.getCurrentPartner()) {
            params = params.set('partnerId', this.contextService.getCurrentPartner().id);
        }
        if (advancedSearchBody) {
            if (advancedSearchBody.key) {
                params = params.set('searchText', "*" + advancedSearchBody.key + "*");
                searchFields.forEach(field => params = params.append('searchField', field));
            }
            if (advancedSearchBody.customer) {
                params = params.set('customerId', advancedSearchBody.customer);
            }
            if (advancedSearchBody.thingDefinitions && advancedSearchBody.thingDefinitions.length) {
                advancedSearchBody.thingDefinitions.forEach(id => params = params.append('thingDefinitionId', id));
            }
            if (advancedSearchBody.tags && advancedSearchBody.tags.length) {
                advancedSearchBody.tags.forEach(tag => params = params.append('tagId', tag));
            }
            if (advancedSearchBody.name) {
                params = params.set('name', advancedSearchBody.name);
            }
            if (advancedSearchBody.serialNumber) {
                params = params.set('serialNumber', advancedSearchBody.serialNumber);
            }
            if (advancedSearchBody['customer.name']) {
                params = params.set('customer.name', advancedSearchBody['customer.name']);
            }
            if (advancedSearchBody['customer.code']) {
                params = params.set('customer.code', advancedSearchBody['customer.code']);
            }
            if (advancedSearchBody['thingDefinition.name']) {
                params = params.set('thingDefinition.name', advancedSearchBody['thingDefinition.name']);
            }
            if (advancedSearchBody.serviceLevels && advancedSearchBody.serviceLevels.length) {
                advancedSearchBody.serviceLevels.forEach(id => params = params.append('serviceLevelId', id));
            }
            if (advancedSearchBody.productModels && advancedSearchBody.productModels.length) {
                advancedSearchBody.productModels.forEach(id => params = params.append('productModelId', id));
            }
            const properties = Object.keys(advancedSearchBody).filter(key => key.includes('properties.'));
            properties.forEach(property => params = params.set(property, advancedSearchBody[property]));
        }
        params = params.set('page', page + '');
        params = params.set('size', 200 + '');
        return params;
    }

    getParentThingsByArea(body: any, advancedSearchBody: any, searchFields: any): Promise<BulkUpdateParentThingsResponse> {
        let params = this.buildParams(0, advancedSearchBody, searchFields);
        return firstValueFrom(this.http.post<BulkUpdateParentThingsResponse>(PARENT_THINGS_BY_AREA, body, params));
    }

    setParentThing(body: any, advancedSearchBody: any, searchFields: any): Promise<void> {
        let params = this.buildParams(0, advancedSearchBody, searchFields);
        return firstValueFrom(this.http.post<void>(SET_PARENT_THING_BY_AREA, body, params));
    }

}