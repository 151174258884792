import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CUSTOMERS, USER_CUSTOMERS_EXPORT, USER_CUSTOMERS_V2 } from '../../common/endpoints';
import { Properties } from '../../common/properties';
import { Customer, PagedList } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { DownloadingObject, DownloadService } from '../../service/download.service';
import { HttpService } from '../../service/http.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { CompositePartComponent, MetricDetailComponent, PropertyComponent } from '../../shared/component';
import { CustomTableColumn, CustomTableService, PipedTableColumn } from '../../shared/custom-table';
import { DownloadStatus, DownloadType } from '../../shared/download-dialog/download-dialog.component';
import { AbstractListWidgetV2Service } from '../list-widget-v2/abstract-list-widget-v2.service';

@Injectable()
export class CustomerListWidgetV2Service extends AbstractListWidgetV2Service<Customer> {

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => DownloadService)) private downloadService: DownloadService
    ) {
        super(authenticationService, customPropertyService);
    }

    getPagedList(page: number, size: number, sort: string[], metricNames: Set<string>, searchFields: string[], advancedSearchBody: any): Promise<PagedList<Customer>> {
        let params = new HttpParams();
        if (this.contextService.getCurrentPartner()) {
            params = params.set('partnerId', this.contextService.getCurrentPartner().id);
        }
        metricNames.forEach(metricName => params = params.append('metricName', metricName));
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            if (sort[0].startsWith(AbstractListWidgetV2Service.COMPOSITE_SORT_PREFIX)) {
                sort = this.assignCompositeSort(sort);
            }
            params = params.set('sort', sort.join(','));
        }
        if (advancedSearchBody) {
            if (advancedSearchBody.key) {
                params = params.set('searchText', "*" + advancedSearchBody.key + "*");
                searchFields.forEach(field => params = params.append('searchField', field));
            }
            if (advancedSearchBody.countries && advancedSearchBody.countries.length) {
                params = params.append('country', 'eq;' + advancedSearchBody.countries.join(','));
            }
            if (advancedSearchBody.code) {
                params = params.set('code', advancedSearchBody.code);
            }
            if (advancedSearchBody.name) {
                params = params.set('name', advancedSearchBody.name);
            }
            const properties = Object.keys(advancedSearchBody).filter(key => key.includes('properties.'));
            properties.forEach(property => params = params.set(property, advancedSearchBody[property]));
        }

        return this.httpService.get<PagedList<Customer>>(USER_CUSTOMERS_V2, params).toPromise();
    }

    protected getPropertyColumn(col: PropertyComponent, columnName: string, defaultType: string): CustomTableColumn {
        return (CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType)) as PipedTableColumn).withArgument(this.getArgument(col))
            .withSortField(col.name).withValueMap(this.getValueMap(col)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
    }

    protected isColumnVisible(columnName: string): boolean {
        return true;
    }

    protected getLabel(col: MetricDetailComponent | CompositePartComponent | PropertyComponent, defaultType: string): string {
        if (col.label) {
            return col.label;
        }
        if (col.name.indexOf('properties.') > -1) {
            const propNameType = this.getCustomPropertyNameAndType(col.name);
            return this.customPropertyService.getLabelByTypeAndName(propNameType.type, propNameType.name) || propNameType.name;
        } else {
            return Properties.getLabelByName(col.name, defaultType) || col.name;
        }
    }

    protected getCustomPropertyNameAndType(columnName: string): { name: string, type: CustomPropertyType } {
        let name = columnName.substr(11);
        let type = CustomPropertyType.Customer;
        return { name, type };
    }

    getExportParams(metricNames: Set<string>, searchFields: string[], advancedSearchBody: any): HttpParams {
        let params = new HttpParams();
        if (this.contextService.getCurrentPartner()) {
            params = params.set('partnerId', this.contextService.getCurrentPartner().id);
        }
        metricNames.forEach(metricName => params = params.append('metricName', metricName));
        if (advancedSearchBody) {
            if (advancedSearchBody.key) {
                params = params.set('searchText', "*" + advancedSearchBody.key + "*");
                searchFields.forEach(field => params = params.append('searchField', field));
            }
            if (advancedSearchBody.countries && advancedSearchBody.countries.length) {
                params = params.append('country', 'eq;' + advancedSearchBody.countries.join(','));
            }
            if (advancedSearchBody.code) {
                params = params.set('code', advancedSearchBody.code);
            }
            if (advancedSearchBody.name) {
                params = params.set('name', advancedSearchBody.name);
            }
            const properties = Object.keys(advancedSearchBody).filter(key => key.includes('properties.'));
            properties.forEach(property => params = params.set(property, advancedSearchBody[property]));
        }
        if (this.columnFieldNames) {
            this.columnFieldNames.forEach(columnFieldName => params = params.append('selectField', columnFieldName));
        }
        if (this.columnFieldLabels) {
            params = params.append('labels', this.columnFieldLabels.join(';'));
        }
        return params
    }

    downloadCSV(params: HttpParams, fileName: string): void {
        params = params.set('language', this.authenticationService.getUser().language || 'en');
        const downloadingObject: DownloadingObject = {
            fileName: fileName || 'customers.csv',
            uuid: null,
            status: DownloadStatus.DOWNLOADING,
            type: DownloadType.CSV,
            csvEndpoint: USER_CUSTOMERS_EXPORT,
            params: params
        }
        this.downloadService.addDownloadingObject(downloadingObject);
        this.downloadService.setVisible();
    }

    import(data: FormData): Promise<void> {
        return firstValueFrom(this.httpService.post<void>(CUSTOMERS + '/import', data))
    }
}