import { SslCertificateIssue, UserType } from ".";

export class Tenant {
    public name: string;
    public id: string;
    public auditEnabled: boolean;
    public auditViewPageEventsEnabled: boolean;
    public domainAliases: string[];
    public customerVerification: boolean;
    public creationDate: number;
    public enableSmartWarranty: boolean;
    public maxParameterCountPerMessage: number;
    public enableFirmware: boolean;
    public enableAlertStatistics: boolean;
    public enableWorkSessionStatistics: boolean;
    public enableDocumentation: boolean;
    public denyAllUserAuthorization: boolean;
    public enableRecipes: boolean;
    public enableFeedbackPageLink: boolean;
    public maxInactivityMonths: number;
    public maintenanceWorkTypes: string;
    public alertCriticalSeverityType: string;
    public thingCloudExpireDateEnabled: boolean;
    public forceMaxOneTagPerThing: boolean;
    public nullValueEnabled: boolean;
    public defaultNullValue: string;
    public servicePurchaseBackOfficeApprovalEnabled: boolean;
    public thingDefaultPropertySettings: DefaultPropertySettings[];
    public thingDefinitionDefaultPropertySettings: DefaultPropertySettings[];
    public customerDefaultPropertySettings: DefaultPropertySettings[];
    public locationDefaultPropertySettings: LocationDefaultPropertySettings[];
    public partnerDefaultPropertySettings: DefaultPropertySettings[];
    public userDefaultPropertySettings: DefaultPropertySettings[];
    public domainAliasesSslCertificatePresent: boolean;
    public domainAliasesSslCertificateKeyPresent: boolean;
    public domainAliasesSslCertificateExpirationTimestamp: number;
    public domainAliasesSslCertificateIssues: SslCertificateIssue[];
    public thingDefinitionAlgorithmsMinComputationPeriod: ThingDefinitionAlgorithmsMinComputationPeriod;
    public selfRegistrationEnabled: boolean;
    public selfRegistrationCustomerTypes: CustomerType[];
    public selfRegistrationDefaultLocationCreationEnabled: boolean;
    public configurationPlan: ConfigurationPlan;
    public defaultLoginPage: string;
    public languageSelectorEnabled: boolean;
    public listRangeSelectionMode: ListRangeSelectionModeType;
    public domainAliasesSslCertificateMode: SslCertificateMode;
    public userPasswordPublicInformationCheckEnabled: boolean;
    public userPasswordDictionaryCheckEnabled: boolean;
    public partnerSelfRegistrationEnabled: boolean;
    public defaultOrganizationUserType: UserType;
    public defaultPartnerUserType: UserType;
    public defaultCustomerUserType: UserType;
    public thingInventoryManagement: ThingInventoryManagementType;
    public features: string[];
}

export class DefaultPropertySettings {
    public name: string;
    public mandatory: boolean;
    public unique: boolean;
    public userTypeFiltered: boolean;
    public userTypeIds: string[];
}

export class LocationDefaultPropertySettings extends DefaultPropertySettings {
    public derivedValue?: boolean;
    public valueExpression?: string;
}

export enum ThingDefinitionAlgorithmsMinComputationPeriod {
    MINUTES_5 = "MINUTES_5",
    HOURS_1 = "HOURS_1",
    DAYS_1 = "DAYS_1"
}

export enum CustomerType {
    BUSINESS = "BUSINESS",
    PERSONAL = "PERSONAL",
    PARTNER = "PARTNER"
}

export class ConfigurationPlan {
    public maxBusinessMetricCount: number;
    public maxWorkSessionDefinitionCount: number;
    public minBusinessMetricSamplingPeriod: string;
}

export enum ListRangeSelectionModeType {
    ITEMS = "ITEMS",
    PAGES = "PAGES"
}

export enum SslCertificateMode {
    IMPORT = "IMPORT",
    FREE = "FREE",
    PROXY = "PROXY"
}

export enum ThingInventoryManagementType {
    BY_THING_DEFINITION = "BY_THING_DEFINITION",
    BY_THING_DEFINITION_AND_MODEL = "BY_THING_DEFINITION_AND_MODEL",
    BY_MODEL = "BY_MODEL"
}