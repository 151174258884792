export class DataExportStatus {
    public id: string;

    public name: string;

    public tenantId: string;

    public dateTime: number;

    public thingFileName: string;

    public hashThingFileName: boolean;

    public state: DataExportState;

    public thingDefinitionIds: string[];

    public metricIds: string[];

    public startTimestamp: number;

    public endTimestamp: number;

    public lastUpdateTimestamp: number;

    public url: string;

    public totalCount: number;

    public exportedCount: number;

    public remainingSeconds: number;

    public timestampFormat: DataExportTimestampFormat;
}

export enum DataExportState {
    PENDING = "PENDING",
    COMPLETED = "COMPLETED",
    ERROR = "ERROR"
}

export enum DataExportTimestampFormat {
    ISO_8601 = "ISO_8601",
    EPOCH_MILLIS = "EPOCH_MILLIS"
}