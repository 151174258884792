import { DatePipe } from '@angular/common';
import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ErrorMessages, SAVE_DATA_ERROR } from '../../common/constants';
import { Location, MaintenanceWork, Thing } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { DynamicModalComponent } from '../../shared/component/dynamic-modal/dynamic-modal.component';
import { CustomPropertyFormComponent } from '../../shared/custom-property/custom-property-form.component';
import { FormEditorComponent } from '../../shared/form-editor/form-editor.component';
import { ErrorUtility } from '../../utility/error-utility';
import { MaintenanceRegistryWidgetService } from './maintenance-registry-widget.service';

let nextId = 0;

@Component({
    selector: 'maintenance-registry-dialog',
    template: require('./maintenance-registry-dialog.component.html'),
    styles: [`
    form-editor ::ng-deep .radio-inline {
        width: 100%;
        margin-left: 0px !important;
        text-align: unset;
    }`],
    providers: [DatePipe]
})
export class MaintenanceRegistryDialogComponent implements OnInit {

    @Input() thing: Thing;

    @Input() location: Location;

    @Input() thingValues: { value: string, label: string }[];

    @Output() saveAction = new EventEmitter();

    @ViewChild(DynamicModalComponent) dialog: DynamicModalComponent;

    @ViewChild('formEditor') formEditor: FormEditorComponent;

    @ViewChild('customPropForm') customPropForm: CustomPropertyFormComponent;

    id: string;
    maintenanceFields: any[] = [];
    showForm: boolean;
    showSave: boolean;
    maintenanceWork: MaintenanceWork;
    error: string = null;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => MaintenanceRegistryWidgetService)) private maintenanceRegistryService: MaintenanceRegistryWidgetService
    ) { }

    close() {
        this.dialog.close();
    }

    ngOnInit() {
        this.id = 'maintenance-registry-dialog-' + nextId++;
    }

    private initConfiguration(): void {
        let maintenanceFields = [];
        maintenanceFields.push({ name: 'startTimestamp', id: "startTimestamp", label: 'startMaintenanceProperty', type: 'DATE', value: this.maintenanceWork ? this.maintenanceWork.startTimestamp : new Date().getTime() })
        maintenanceFields.push({ name: 'endTimestamp', id: "endTimestamp", label: 'endMaintenanceProperty', type: 'DATE', value: this.maintenanceWork ? this.maintenanceWork.endTimestamp : new Date().getTime() })
        if (this.location) {
            maintenanceFields.push({ name: 'thingId', id: "thingId", label: 'thingProperty', type: 'STRING', selectionMode: 'SELECTION', values: this.thingValues, value: this.maintenanceWork && this.maintenanceWork.thingId ? this.maintenanceWork.thingId : null })
        }
        const types = this.getTypeValues();
        if (types) {
            maintenanceFields.push({ name: 'type', id: "type", label: 'typeProperty', type: 'RADIO', selectionMode: 'RADIO_BUTTON', values: types, value: (this.maintenanceWork ? this.maintenanceWork.type : (types.length == 1 ? types[0].value : null)), isHidden: types.length == 1 })
        }
        maintenanceFields.push({ name: 'description', id: "description", label: 'descriptionProperty', textArea: true, value: this.maintenanceWork ? this.maintenanceWork.description : null })
        maintenanceFields.push({ name: 'outcome', id: "outcome", label: 'outcomeProperty', textArea: true, value: this.maintenanceWork && this.maintenanceWork.outcome ? this.maintenanceWork.outcome : null })
        this.maintenanceFields = maintenanceFields;
    }

    private getTypeValues(): { value: string, label: string }[] {
        let typesString = this.authenticationService.getTenant().maintenanceWorkTypes;
        if (typesString) {
            let typesArray = typesString.split(',');
            return typesArray.map(t => { return { value: t, label: t } });
        } else {
            return null;
        }
    }

    open(maintenanceWork: MaintenanceWork): void {
        this.showForm = false;
        this.showSave = false;
        this.maintenanceWork = null;
        if (maintenanceWork) {
            this.maintenanceWork = maintenanceWork;
        }
        this.initConfiguration();
        setTimeout(() => {
            this.showForm = true;
            setTimeout(() => this.showSave = true, 10);
            this.dialog.open();
        }, 10);
    }

    getMaintenanceWorkBody(): any {
        let body: MaintenanceWork;
        if (this.maintenanceWork) {
            body = Object.assign({}, this.maintenanceWork, this.formEditor.getObjectValue());
        } else {
            body = Object.assign({}, this.formEditor.getObjectValue());
        }
        if (body["endTimestamp"] < body["startTimestamp"]) {
            body["endTimestamp"] = body["startTimestamp"];
        }
        let properties = null;
        if (this.customPropForm) {
            if (this.customPropForm.checkForm()) {
                properties = this.customPropForm.getProperties();
            } else {
                return;
            }
        }
        if (properties) {
            body['properties'] = properties;
        }
        return body;
    }

    save(): void {
        let maintenanceWorkBody = this.getMaintenanceWorkBody();
        this.error = null;
        if (this.thing) {
            maintenanceWorkBody.thingId = this.thing.id;
        } else {
            maintenanceWorkBody.locationId = this.location.id;
        }
        this.maintenanceRegistryService.saveMaintenanceWork(maintenanceWorkBody).then(maintenanceWork => {
            if (this.customPropForm) {
                this.customPropForm.uploadFiles(maintenanceWork.id).then(() => {
                    this.saveAction.emit();
                    this.close();
                }).catch(err => this.error = ErrorUtility.getMessage(err, SAVE_DATA_ERROR));
            } else {
                this.saveAction.emit();
                this.close();
            }
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
    }

    isFormInvalid(): boolean {
        if (!this.formEditor) {
            return true;
        }
        let value = this.formEditor.getObjectValue();
        for (let field in value) {
            if ((field != 'outcome' && field != 'thingId' && !value[field])) {
                return true;
            }
        }
        return false;
    }

    fileDelete(deleteResult: string): void {
        if (deleteResult != "OK") {
            this.error = deleteResult;
        }
    }

    getSelectedMaintenanceType(): string {
        return this.formEditor ? this.formEditor.getObjectValue()['type'] : null;
    }
}