import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { STORE_ORDER_BY_ID, STORE_ORDERS_V2 } from '../common/endpoints';
import { PagedList, StoreOrder } from '../model';
import { HttpService } from './http.service';

@Injectable()
export class StoreOrderService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getOrders(page: number, size: number, sort: string[], startDate: string, endDate: string, searchText: string, statuses: string[], buyer: boolean): Promise<PagedList<StoreOrder>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort[0] + ',' + sort[1]);
        }
        if (startDate) {
            params = params.set('minCreationTimestamp', startDate);
        }
        if (endDate) {
            params = params.set('maxCreationTimestamp', endDate);
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        if (statuses?.length) {
            statuses.forEach(s => params = params.append('status', s));
        }
        if (buyer) {
            params = params.set('buyer', 'true');
        }
        return firstValueFrom(this.httpService.get<PagedList<StoreOrder>>(STORE_ORDERS_V2, params));
    }

    getById(id: string): Promise<StoreOrder> {
        return firstValueFrom(this.httpService.get<StoreOrder>(STORE_ORDER_BY_ID.replace('{id}', id)));
    }

    postNote(id: string, noteBody: any): Promise<void> {
        return firstValueFrom(this.httpService.post<void>(STORE_ORDER_BY_ID.replace('{id}', id) + '/notes', noteBody));
    }

    approve(id: string, body: any): Promise<void> {
        return firstValueFrom(this.httpService.post<void>(STORE_ORDER_BY_ID.replace('{id}', id) + '/approve', body));
    }

    accept(id: string): Promise<void> {
        return firstValueFrom(this.httpService.post<void>(STORE_ORDER_BY_ID.replace('{id}', id) + '/accept', null));
    }

    confirmShipping(id: string): Promise<void> {
        return firstValueFrom(this.httpService.post<void>(STORE_ORDER_BY_ID.replace('{id}', id) + '/confirmShipping', null));
    }

    confirmDelivered(id: string): Promise<void> {
        return firstValueFrom(this.httpService.post<void>(STORE_ORDER_BY_ID.replace('{id}', id) + '/confirmDelivered', null));
    }

    reject(id: string): Promise<void> {
        return firstValueFrom(this.httpService.post<void>(STORE_ORDER_BY_ID.replace('{id}', id) + '/reject', null));
    }

    rejectByBuyer(id: string): Promise<void> {
        return firstValueFrom(this.httpService.post<void>(STORE_ORDER_BY_ID.replace('{id}', id) + '/rejectByBuyer', null));
    }

    read(id: string): Promise<void> {
        return firstValueFrom(this.httpService.post<void>(STORE_ORDER_BY_ID.replace('{id}', id) + '/read', null));
    }

}