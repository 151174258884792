import { Component, Inject, forwardRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Metric } from "../../model";

@Component({
    selector: 'wear-status-widget-element-details-dialog',
    template: require('./wear-status-widget-element-details-dialog.component.html'),
})
export class WearStatusWidgetElementDetailsDialogComponent {

    wearMetric: Metric;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<WearStatusWidgetElementDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.wearMetric = data.wearMetric;
    }

    close(): void {
        this.dialogRef.close(true);
    }
}