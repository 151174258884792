import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { CLEAR_TAG, CLEAR_TAG_AREA, TAGGABLE_BY_AREA, TAGGABLE_BY_IDS, TAG_AREA, TAG_THINGS } from "../../../common/endpoints";
import { HttpService } from "../../../service/http.service";
import { BulkUpdateTagDialogData } from "./bulk-update-tag-dialog.component";

@Injectable()
export class BulkUpdateTagDialogService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
    ) { }

    getIsThingsTaggable(data: BulkUpdateTagDialogData): Promise<object> {
        let body = {};
        if (data.mapTagging) {
            body['selectedCoordinates'] = data.selectedCoordinates;
            body['areaCoordinates'] = data.areaCoordinates;
        } else {
            if (!data.allElementsSelected) {
                body['thingIds'] = data.selectedThingIds;
            }
        }
        return firstValueFrom(this.http.post<object>(data.mapTagging ? TAGGABLE_BY_AREA : TAGGABLE_BY_IDS, body, this.getEnrichedParams(data)));
    }

    tagThings(body: any, data: BulkUpdateTagDialogData): Promise<void> {
        return firstValueFrom(this.http.post<void>(data.mapTagging ? TAG_AREA : TAG_THINGS, body, this.getEnrichedParams(data)));
    }

    clearAllTagThings(body: any, data: BulkUpdateTagDialogData): Promise<void> {
        return firstValueFrom(this.http.post<void>(data.mapTagging ? CLEAR_TAG_AREA : CLEAR_TAG, body, this.getEnrichedParams(data)));
    }

    private getEnrichedParams(data: BulkUpdateTagDialogData): HttpParams {
        let params = data.searchParams;
        if (data.allElementsSelected && !data.mapTagging) {
            params = params.set('all', true);
        }
        return params;
    }
}