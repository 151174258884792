import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { SET_PARENT_THING_BY_AREA, SET_PARENT_THING_BY_IDS } from "../../../common/endpoints";
import { HttpService } from "../../../service/http.service";
import { BulkParentAssignDialogData } from "./bulk-parent-assign-dialog.component";

@Injectable()
export class BulkParentAssignDialogService {
    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
    ) { }

    setParentThingsByArea(selectedCoordinates: any[], areaCoordinates: any[], params: HttpParams, parentThingId: string): Promise<void> {
        let body = {
            parentThingId: parentThingId,
            selectedCoordinates: selectedCoordinates,
            areaCoordinates: areaCoordinates
        };
        return firstValueFrom(this.http.post<void>(SET_PARENT_THING_BY_AREA, body, params));
    }

    setParentThingsByIds(data: BulkParentAssignDialogData, parentThingId: string): Promise<void> {
        let body = {
            parentThingId: parentThingId
        };
        let params = data.searchParams;
        if (data.allElementsSelected) {
            params = params.set('all', true);
        } else {
            body['thingIds'] = data.selectedThingIds;
        }
        return firstValueFrom(this.http.post<void>(SET_PARENT_THING_BY_IDS, body, params));
    }
}