import { ComponentType } from '@angular/cdk/portal';
import { HttpParams } from '@angular/common/http';
import {
    AfterViewInit, Component, ContentChildren,
    HostListener, Inject, Input, NgZone, OnDestroy, OnInit,
    QueryList,
    forwardRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import * as _ from 'lodash';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { Permissions } from '../../common/constants';
import { CUSTOM_FILTERS_AND_COMPONENTS_MAP } from '../../common/setup';
import { BulkUpdateParentThingsResponse, Location, Thing } from '../../model';
import { BulkUpdateType } from '../../model/bulk-update';
import { MapFilterRequiredData } from '../../model/map-filter-required-data';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomLabelService } from '../../service/custom-label.service';
import { NavigationService } from '../../service/navigation.service';
import { ThingService } from '../../service/thing.service';
import { UserThingService } from '../../service/user-thing.service';
import { CompositePartComponent, PropertyComponent } from '../../shared/component';
import { BulkOperationDialogComponent, BulkOperationDialogData } from '../../shared/component/bulk-operation-dialog/bulk-operation-dialog.component';
import { BulkParentAssignDialogComponent, BulkParentAssignDialogData } from '../../shared/component/bulk-parent-assign-dialog/bulk-parent-assign-dialog.component';
import { BulkUpdateTagDialogComponent, BulkUpdateTagDialogData } from '../../shared/component/bulk-update-tag-dialog/bulk-update-tag-dialog.component';
import { LoaderPipe, LocalizationPipe } from '../../shared/pipe';
import { COMPONENT_DEFINITION_REF } from '../../shared/utility/component-definition-token';
import { ConnectionStatusMapFilter } from './map-filter/connection-status-map-filter';
import { MapFilter } from './map-filter/map-filter';
import { MapFilterComponent } from './map-filter/map-filter.component';
import { StatusMapFilter } from './map-filter/status-map-filter';
import { LocationForMap, MapService, ThingForMap } from './map.service';

require('./Leaflet.SelectAreaFeature.js');
require('../../../../node_modules/leaflet/dist/leaflet.css');
require('../../../../node_modules/leaflet.markercluster/dist/MarkerCluster.css');

const DEFAULT_HEIGHT = 600;
const DEFAULT_MAP_LAYER_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const HIERARCHY_COLOR_PALETTE = ['#F94144', '#00BFFF', '#F3722C', '#43AA8B', '#F8961E', '#4D908E', '#F9844A', '#577590', '#F9C74F', '#277DA1'];

@Component({
    selector: 'map-widget',
    template: require('./map.component.html'),
    styles: [require('./map.component.css')],
    providers: [MapService]
})
export class MapComponent implements AfterViewInit, OnInit, OnDestroy {

    @Input() context: MapContext = MapContext.THINGS;

    @Input() title: string;

    @Input() moveElementEnabled: boolean = false;

    @Input() showHierarchyEnabled: boolean = false;

    @Input() bulkControlsEnabled: boolean = false;

    @Input() queryFieldRef: string;

    @Input() refreshInterval: string = 'PT300S'; // 300 secs (5 min) default

    @Input() query: { property: string, predicate: string, value: any }[];

    @Input() searchFields: string[];

    @Input() maxClusterRadius: number;

    @Input() disableClusteringAtZoomLevel: number;

    @Input() maxZoomLevel: number = 18;

    @Input() mapLayerUrl: string = DEFAULT_MAP_LAYER_URL;

    @Input() tooltipClass: string = 'map-tooltip';

    @Input() styleClass: string;

    @Input() height: string;

    @ContentChildren(MapFilterComponent) mapFilters: QueryList<MapFilterComponent>;

    @ContentChildren(COMPONENT_DEFINITION_REF) properties: QueryList<PropertyComponent | CompositePartComponent>;

    private map: any;
    private mapInitialized: boolean = false;
    private markersCluster: any = null;
    private markersHierarchy: any = null;
    private objects: (ThingForMap | LocationForMap)[] = [];
    private objectsIdMapping: { [objectId: string]: ThingForMap | LocationForMap } = {};
    private mapFilterMapping: { [filterName: string]: MapFilter } = {};
    private mapFilterData: { [key: string]: any[] } = {};
    private mapPreselectedStates: { [key: string]: any[] } = {};
    private mapFilter: MapFilter;
    private refreshIntervalMillis: number;
    private intervalId: any;
    private storedFilterKey: string;
    private advancedSearchBody: any;
    private bounds: Bounds;
    private prevMapAction: MapAction = null;
    private controlKeyPressed: boolean = false;
    private selectedAreas: L.latLng[][] = [];
    private manuallySelectedMarkers: L.latLng[] = [];
    private allRootThings: Thing[];

    mapFilterEntries: any[] = [];
    mapFilterRanks: number[] = [];
    selectedFilter: string;
    selectedMapAction: MapAction;
    selectedIds: string[] = [];
    rankDescriptors: any[];
    objectCount: number = 0;
    initLoading: boolean;
    loading: boolean;
    advancedSearchOpen: boolean = false;
    isMobile: boolean;
    error: string;
    noMarkerMessage: string;
    hierarchyFilterEnabled: boolean = false;
    hierarchyParentColorMapping: { [parentId: string]: string } = {};
    setTagPermission: boolean;
    bulkCommandPermission: boolean;
    bulkRecipePermission: boolean;
    bulkFirmwarePermission: boolean;
    assignParentThingPermission: boolean;
    hierarchyButtonEnabled: boolean;
    setTagButtonEnabled: boolean;
    bulkCommandButtonEnabled: boolean;
    bulkRecipeButtonEnabled: boolean;
    bulkFirmwareButtonEnabled: boolean;
    assignParentThingButtonEnabled: boolean;

    readonly bulkSelectionIcon: string = '/img/bulk_selection.png';
    readonly bulkSelectionActiveIcon: string = '/img/bulk_selection_active.png';
    readonly moveIcon: string = '/img/move.png';
    readonly moveActiveIcon: string = '/img/move_active.png';

    constructor(@Inject(forwardRef(() => MapService)) private mapService: MapService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AppService)) private appService: AppService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => MatSnackBar)) private snackBar: MatSnackBar,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => UserThingService)) private userThingService: UserThingService
    ) { }

    ngOnInit(): void {
        this.refreshIntervalMillis = Math.max(moment.duration(this.refreshInterval).asMilliseconds(), 5000); // min 5 sec
        this.height = this.height || (DEFAULT_HEIGHT + 'px');
        this.initLoading = true;
        this.checkIsMobile();
        this.checkPermissions();
        if (!this.searchFields) {
            if (this.context == MapContext.THINGS) {
                this.searchFields = ['name', 'serialNumber', 'customer.name', 'customer.code'];
            } else {
                this.searchFields = ['name'];
            }
        }
        this.maxZoomLevel = this.maxZoomLevel != null && this.maxZoomLevel <= 18 ? this.maxZoomLevel : 18;
    }

    ngAfterViewInit(): void {
        let storedFieldKey: string;
        if (this.context == MapContext.THINGS) {
            storedFieldKey = 'thingAdvancedSearchFieldsValues';
            this.storedFilterKey = 'thingFilterValue';
        } else {
            storedFieldKey = 'locationAdvancedSearchFieldsValues';
            this.storedFilterKey = 'locationFilterValue';
        }

        const storedFieldsValues = localStorage.getItem(this.queryFieldRef || storedFieldKey);
        const savedFieldsValues = storedFieldsValues ? JSON.parse(storedFieldsValues) : null;
        if (!this.queryFieldRef && !this.query && !savedFieldsValues) {
            this.fetchData(true);
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.intervalId);
    }

    private checkIsMobile(): void {
        this.isMobile = this.appService.isMobile();
    }

    private checkPermissions(): void {
        if (this.context == MapContext.THINGS) {
            this.setTagPermission = this.authenticationService.hasPermission(Permissions.WRITE_THING_TAG);
            this.bulkRecipePermission = this.authenticationService.hasPermission(Permissions.EXECUTE_BULK_UPDATE) && this.authenticationService.hasPermission(Permissions.EXECUTE_THING_COMMAND);
            this.bulkCommandPermission = this.authenticationService.hasPermission(Permissions.EXECUTE_BULK_UPDATE) && this.authenticationService.hasPermission(Permissions.EXECUTE_THING_COMMAND);
            this.bulkFirmwarePermission = this.authenticationService.hasPermission(Permissions.EXECUTE_BULK_UPDATE) && this.authenticationService.hasPermission(Permissions.UPDATE_FIRMWARE);
            this.assignParentThingPermission = this.authenticationService.hasPermission(Permissions.WRITE_THING);

            this.moveElementEnabled = this.moveElementEnabled && this.authenticationService.hasPermission(Permissions.WRITE_THING);
            this.bulkControlsEnabled = this.bulkControlsEnabled && (this.setTagPermission || this.bulkRecipePermission || this.bulkCommandPermission || this.bulkFirmwarePermission || this.assignParentThingPermission);
        } else {
            this.moveElementEnabled = this.moveElementEnabled && this.authenticationService.hasPermission(Permissions.WRITE_LOCATION);
            this.bulkControlsEnabled = false;
        }
    }

    private initRefreshInterval(): void {
        this.zone.runOutsideAngular(() => {
            this.intervalId = setInterval(() => {
                this.zone.run(() => {
                    this.fetchData();
                });
            }, this.refreshIntervalMillis);
        });
    }

    private clearRefreshInterval(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    private fetchData(fitMapToContent: boolean = false): void {
        this.clearRefreshInterval();
        if (!this.mapInitialized) {
            let promises = [this.buildMapFilterMap()];
            if (this.context == MapContext.THINGS && this.assignParentThingPermission) {
                const params = new HttpParams().set('rootsOnly', true);
                promises.push(this.userThingService.getRecursivelyAllThings(null, [], null, null, null, params).then(things => this.allRootThings = things));
            }
            Promise.all(promises)
                .then(() => this.initialFetchData());
        } else {
            this.refreshFetchData(fitMapToContent);
        }
    }

    private initialFetchData(): void {
        const dataKeys = [];
        const requiredData = this.computeMapFilterRequiredData();
        let dataPromises: Promise<any>[] = [];
        if (this.context == MapContext.LOCATIONS) {
            dataPromises.push(this.mapService.getAllLocations(this.advancedSearchBody, this.searchFields));
        } else if (requiredData.locations) {
            dataPromises.push(this.mapService.getAllLocations());
            dataKeys.push('locations');
        }
        if (requiredData.alerts) {
            dataPromises.push(this.mapService.getAllAlerts());
            dataKeys.push('alerts');
        }
        Promise.all(dataPromises).then(results => {
            let dataPadding = 0;
            if (this.context == MapContext.LOCATIONS) {
                results[0].forEach(obj => this.checkValidObject(obj));
                this.checkEmptyObjects();
                dataPadding = 1;
            }
            this.mapFilterData = {}
            dataKeys.forEach((item, index) => {
                this.mapFilterData[item] = results[index + dataPadding];
            });

            this.initLoading = false;
            setTimeout(() => this.initMap());
            if (this.context == MapContext.THINGS || requiredData.things) {
                this.fetchThings();
                this.initRefreshInterval();
            } else {
                setTimeout(() => this.reloadMapMarkers(true));
            }
        });
    }

    private async fetchThings(): Promise<void> {
        this.loading = true;
        const generator = (this.context == MapContext.THINGS) ? this.mapService.getThingsInChunks(5, this.advancedSearchBody, this.searchFields) : this.mapService.getThingsInChunks(5);
        for await (const objects of generator) {
            if (this.context == MapContext.THINGS) {
                objects.forEach(obj => this.checkValidObject(obj));
                this.checkEmptyObjects();
            } else {
                if (this.mapFilterData['things']) {
                    this.mapFilterData['things'] = this.mapFilterData['things'].concat(objects.map(obj => obj as Thing));
                } else {
                    this.mapFilterData['things'] = objects.map(obj => obj as Thing);
                }
            }
            this.reloadMapMarkers(true);
        }
        this.loading = false;
    }

    private refreshFetchData(fitMapToContent: boolean = false): void {
        this.loading = true;
        this.clearRefreshInterval();
        const dataKeys = [];
        this.buildRefreshFetchPromise(dataKeys).then(results => {
            this.initRefreshInterval();
            this.objects = [];
            this.objectsIdMapping = {};
            // Results 0 is always the objects array, others are the optional filter data
            results[0].forEach(obj => this.checkValidObject(obj));
            this.mapFilterData = {}
            dataKeys.forEach((item, index) => {
                this.mapFilterData[item] = results[index + 1];
            });
            this.checkEmptyObjects();
            this.reloadMapMarkers(fitMapToContent);
            this.loading = false;
        }).catch(
            () => this.error = 'fetchingMapDataErrorProperty'
        );
    }

    private buildRefreshFetchPromise(dataKeys: string[]): Promise<any[]> {
        const requiredData = this.computeMapFilterRequiredData();
        let dataPromises: Promise<any>[] = [];
        if (this.context == MapContext.THINGS) {
            dataPromises.push(this.mapService.getAllThings(this.advancedSearchBody, this.searchFields));
            if (requiredData.locations) {
                dataPromises.push(this.mapService.getAllLocations());
                dataKeys.push('locations');
            }
        } else {
            dataPromises.push(this.mapService.getAllLocations(this.advancedSearchBody, this.searchFields));
            if (requiredData.things) {
                dataPromises.push(this.mapService.getAllThings());
                dataKeys.push('things');
            }
        }
        if (requiredData.alerts) {
            dataPromises.push(this.mapService.getAllAlerts());
            dataKeys.push('alerts');
        }
        return Promise.all(dataPromises);
    }

    private checkValidObject(obj: ThingForMap | LocationForMap): void {
        if (obj.latLng) {
            this.objects.push(obj);
            this.objectsIdMapping[obj.id] = obj;
        }
    }

    private checkEmptyObjects(): void {
        if (!this.objects.length) {
            if (this.context == MapContext.THINGS) {
                this.noMarkerMessage = 'noThingMarkerProperty'
            } else {
                this.noMarkerMessage = 'noLocationMarkerProperty'
            }
        } else {
            this.noMarkerMessage = null;
            this.selectedIds.forEach((id) => {
                if (this.objectsIdMapping[id]) {
                    this.objectsIdMapping[id].selected = true;
                }
            });
        }
    }

    private drawMap(): void {
        this.map = L.map('map', {
            center: [45.697127, 9.035056],
            zoom: 6,
            zoomControl: false,
            dragging: !this.isMobile
        });
        this.map.attributionControl.setPrefix('');

        if (this.isMobile) {
            L.Control.ToggleDragging = L.Control.extend({
                options: {
                    position: 'topright'
                },
                onAdd: this.createToggleDraggingButton.bind(this)
            });
            (new L.Control.ToggleDragging).addTo(this.map);
        } else {
            let zoomControl = L.control.zoom({
                position: 'topright'
            });
            zoomControl.addTo(this.map);
            zoomControl.getContainer().classList.add('user-select-none');
        }

        L.Control.FitMapToContent = L.Control.extend({
            options: {
                position: 'topright'
            },
            onAdd: this.createFitToContentButton.bind(this)
        });
        (new L.Control.FitMapToContent).addTo(this.map);

        const tiles = L.tileLayer(this.mapLayerUrl, {
            maxZoom: this.maxZoomLevel,
            minZoom: 3,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });
        tiles.addTo(this.map);

        let clusterOptions = {
            iconCreateFunction: this.createClusterIcon.bind(this)
        }
        if (this.disableClusteringAtZoomLevel) {
            clusterOptions['disableClusteringAtZoom'] = this.disableClusteringAtZoomLevel;
            clusterOptions['spiderfyOnMaxZoom'] = false;
        }
        if (this.maxClusterRadius) {
            clusterOptions['maxClusterRadius'] = this.maxClusterRadius;
        }
        this.markersCluster = L.markerClusterGroup(clusterOptions);
        this.map.addLayer(this.markersCluster);
        this.markersHierarchy = L.layerGroup();
        this.map.addLayer(this.markersHierarchy);
        this.map.selectAreaFeature.onDrawEnd = this.onMapBulkSelectionDrawEnd.bind(this);
    }

    private initMap(): void {
        const storedFilterValue = localStorage.getItem(this.storedFilterKey);
        this.drawMap();
        const storedFilter = this.mapFilterMapping[storedFilterValue];
        if (storedFilter) {
            this.mapFilter = storedFilter;
            this.selectedFilter = storedFilterValue;
        } else {
            this.mapFilter = Object.values(this.mapFilterMapping)[0];
            this.selectedFilter = Object.keys(this.mapFilterMapping)[0];
            localStorage.setItem(this.storedFilterKey, this.selectedFilter);
        }
        this.rankDescriptors = this.mapFilter.getRankDescriptors(this.objects);
        this.mapFilterRanks = this.getPreselectedRanks(this.selectedFilter, this.rankDescriptors);
        this.mapInitialized = true;
    }

    private buildMapFilterMap(): Promise<any[]> {
        let initPromises = [];
        if (this.mapFilters.length) {
            let filtersMap = {}
            this.mapFilters.forEach(f => {
                try {
                    const splitIndex = f.name.indexOf(':');
                    let name, filterConf;
                    if (splitIndex > -1) {
                        [name, filterConf] = [f.name.slice(0, splitIndex), f.name.slice(splitIndex + 1)];
                    } else {
                        name = f.name;
                    }
                    const filterInstance = this.instantiateFilter(name, f.config);
                    let preselectedStates;
                    if (filterConf) {
                        const confObj = JSON.parse(filterConf.replaceAll("'", '"'));
                        preselectedStates = confObj?.preselectedStates;
                    }
                    let count;
                    if (name in filtersMap) {
                        count = filtersMap[name]
                    } else {
                        count = 0
                    }
                    filtersMap[name] = count + 1
                    const id = `${name}_${String(count).padStart(2, '0')}`;
                    this.mapFilterMapping[id] = filterInstance;
                    this.mapPreselectedStates[id] = preselectedStates || [];
                    this.mapFilterEntries.push({
                        'id': id,
                        'label': filterInstance.getConfig()?.label || name
                    });
                    filterInstance.setContext(this.context);
                    if (filterInstance.onInit) {
                        initPromises.push(filterInstance.onInit());
                    }
                } catch (e) {
                    console.log(e);
                    this.error = 'parsingMapFilterErrorProperty';
                }
            });
        } else {
            const connectionStatusFilter = new ConnectionStatusMapFilter(null);
            this.mapFilterMapping['ConnectionStatusMapFilter'] = connectionStatusFilter;
            this.mapFilterEntries.push({
                'name': 'ConnectionStatusMapFilter',
                'label': connectionStatusFilter.getConfig()?.label || 'ConnectionStatusMapFilter'
            });
            connectionStatusFilter.setContext(this.context);
        }
        return Promise.all(initPromises);
    }

    private getPreselectedRanks(filterName: string, filterDescriptor: object[]): number[] {
        const preselectedStates = this.mapPreselectedStates[filterName];
        if (preselectedStates) {
            let preselectedRanks = []
            filterDescriptor.forEach(fd => {
                if (preselectedStates.includes(fd['label'].toUpperCase())) {
                    preselectedRanks.push(fd['rank'])
                }
            });
            return preselectedRanks;
        } else {
            return [];
        }
    }

    private computeMapFilterRequiredData(): MapFilterRequiredData {
        const requireData = new MapFilterRequiredData();
        Object.values(this.mapFilterMapping).forEach(f => {
            const currentRequiredData = f.getRequiredData();
            requireData.things = requireData.things || currentRequiredData.things;
            requireData.locations = requireData.locations || currentRequiredData.locations;
            requireData.alerts = requireData.alerts || currentRequiredData.alerts;
        });
        return requireData;
    }

    private instantiateFilter(filterName: string, config: any): MapFilter {
        switch (filterName) {
            case 'ConnectionStatusMapFilter':
                return new ConnectionStatusMapFilter(config);
            case 'StatusMapFilter':
                return new StatusMapFilter(config);
            default:
                return new CUSTOM_FILTERS_AND_COMPONENTS_MAP[filterName](config) as MapFilter;
        }
    }

    onMapFilterChange(): void {
        this.loading = true;
        localStorage.setItem(this.storedFilterKey, this.selectedFilter);
        this.mapFilter = this.mapFilterMapping[this.selectedFilter];
        this.rankDescriptors = this.mapFilter.getRankDescriptors(this.objects);
        this.mapFilterRanks = this.getPreselectedRanks(this.selectedFilter, this.rankDescriptors);
        this.disableMapAction(this.selectedMapAction);
        this.selectedMapAction = null;
        this.prevMapAction = null;
        this.reloadMapMarkers();
        this.loading = false;
    }

    onMapFilterButtonChange(): void {
        this.loading = true;
        this.disableMapAction(this.selectedMapAction);
        this.selectedMapAction = null;
        this.prevMapAction = null;
        this.applyMapFilter();
        this.loading = false;
    }

    onHierarchyToggleChange(): void {
        if (this.hierarchyFilterEnabled) {
            this.drawHierarchyLines();
        } else {
            this.markersHierarchy.clearLayers();
        }
    }

    private reloadMapMarkers(fitMapToContent: boolean = false): void {
        this.mapFilter.computeRank(this.objects, this.mapFilterData);
        this.applyMapFilter();
        const rankDesc = this.mapFilter.getRankDescriptors(this.objects).map(rd => Object.assign({}, rd));
        rankDesc.forEach(rd => {
            const count = this.objects.filter(obj => obj.rank == rd.rank).length;
            rd.label = this.localizationPipe.transform(rd.label) + ` (${count})`;
        });
        this.rankDescriptors = rankDesc;
        if (fitMapToContent) {
            this.fitMapToBounds();
        }
    }

    private applyMapFilter(): void {
        let filtered: ThingForMap[] | LocationForMap[];
        if (this.mapFilterRanks.length) {
            filtered = this.objects.filter(obj => this.mapFilterRanks.includes(obj.rank));
        } else {
            filtered = this.objects;
        }
        this.objectCount = filtered.length;
        this.addMarkers(filtered);
        if (this.showHierarchyEnabled && this.context == MapContext.THINGS) {
            this.hierarchyButtonEnabled = (filtered as ThingForMap[]).some(t => t.parentThingId);
            if (this.hierarchyButtonEnabled) {
                if (this.hierarchyFilterEnabled) {
                    this.drawHierarchyLines();
                }
            } else {
                this.hierarchyFilterEnabled = false;
                this.markersHierarchy.clearLayers();
            }
        }
    }

    private addMarkers(objects: ThingForMap[] | LocationForMap[]): void {
        this.markersCluster.clearLayers();
        const newMarkers = objects.map((obj: LocationForMap | ThingForMap) => {
            this.updateBounds(obj.latLng);
            const marker = L.marker(obj.latLng, { opacity: 0.8 });
            marker.id = obj.id;
            marker.selected = obj.selected;
            marker.rank = obj.rank;

            this.mapFilter.updateMarker(obj, marker);
            marker.bindTooltip('', { offset: [12, 0], className: this.tooltipClass });
            marker.on({
                click: this.onMarkerClick.bind(this, marker),
                dragend: this.onMarkerMoveEnd.bind(this),
                mouseover: () => {
                    marker.setOpacity(1);
                    const tooltipContent = this.getMarkerTooltip(obj);
                    marker.setTooltipContent(tooltipContent);

                    const tooltipId = marker.getTooltip()._container.id;
                    const tooltipRect = document.querySelector(`.leaflet-tooltip#${tooltipId}`).getBoundingClientRect();
                    const mapRect = document.querySelector('#map').getBoundingClientRect();
                    const tooltip = marker.getTooltip();
                    const iconX = marker.getIcon().options.iconSize[0];
                    const iconY = marker.getIcon().options.iconSize[1]

                    const diffLeft = tooltipRect.left - mapRect.left - iconX;
                    const diffRight = mapRect.right - tooltipRect.right - iconX;
                    let xOffset = 0;
                    if (diffLeft < tooltipRect.width / 2) {
                        xOffset = tooltipRect.width / 2 + iconX;
                    } else if (diffRight < tooltipRect.width / 2) {
                        xOffset = - tooltipRect.width / 2 - iconX;
                    }

                    if (tooltipRect.bottom > mapRect.bottom) {
                        const pos = this.map.latLngToLayerPoint(tooltip._latlng);
                        tooltip.options.direction = 'top';
                        tooltip.options.offset = [xOffset, -iconY];
                        tooltip._setPosition(pos);
                    } else if (tooltipRect.top < mapRect.top) {
                        const pos = this.map.latLngToLayerPoint(tooltip._latlng);
                        tooltip.options.direction = 'bottom';
                        tooltip.options.offset = [xOffset, 0];
                        tooltip._setPosition(pos);
                    }
                },
                mouseout: () => {
                    marker.setOpacity(0.8);
                    marker.setTooltipContent('');
                    const tooltipOptions = marker.getTooltip().options;
                    tooltipOptions.direction = 'auto';
                    tooltipOptions.offset = [12, 0];
                },
            });
            return marker;
        });
        this.markersCluster.addLayers(newMarkers);
    }

    private getMarkerTooltip(obj: ThingForMap | LocationForMap): string {
        let tooltip = ``;
        if (this.properties.length) {
            this.properties.forEach(p => {
                const value = this.getTooltipPropertyValue(obj, p);
                let label = null;
                if (p.showLabel) {
                    label = this.localizationPipe.transform(p.label || p.name);
                }
                tooltip += this.getTooltipRow(label, value);
            });
        } else { // Default tooltip
            if (this.context == MapContext.THINGS) {
                tooltip += this.getTooltipRow(this.localizationPipe.transform('Name'), ThingService.getValue(obj, 'name', ''));
                tooltip += this.getTooltipRow(this.localizationPipe.transform('Serial Number'), ThingService.getValue(obj, 'serialNumber', ''));
                tooltip += this.getTooltipRow(this.localizationPipe.transform('Thing Definition'), ThingService.getValue(obj, 'thingDefinition.name', ''));
            } else {
                tooltip += this.getTooltipRow(this.localizationPipe.transform('Name'), _.get(obj, 'name', ''));
            }
        }
        return tooltip;
    }

    private getTooltipPropertyValue(obj: ThingForMap | LocationForMap, property: PropertyComponent | CompositePartComponent): any {
        let value;
        if (property instanceof PropertyComponent) {
            if (this.context == MapContext.THINGS) {
                value = ThingService.getValue(obj, property.name, '');
            } else {
                value = _.get(obj, property.name, '');
            }
        } else { // CompositePartComponent
            value = (property as CompositePartComponent).getPropertyValues(obj);
        }
        if (property.filter) {
            value = this.loaderPipe.transform(value, property.filter, true);
        } else if (property.name == 'connectionStatus') {
            value = this.loaderPipe.transform(value, 'defaultConnectionStatus', true);
        } else if (property.name == 'cloudStatus') {
            value = this.loaderPipe.transform(value, 'defaultCloudStatus', true);
        } else if (property instanceof CompositePartComponent) {
            value = JSON.stringify(value);
        }
        return value;
    }

    private getTooltipRow(label: string, value: any): string {
        let row = `<div class="map-tooltip-row">`;
        if (label) {
            row += `<div class="map-tooltip-label">${label}</div>`;
        }
        row += `<div class="map-tooltip-value">${value}</div>`;
        row += `</div>`;
        return row;
    }

    private onMarkerClick(marker): void {
        if (this.selectedMapAction == MapAction.BULK_SELECTION && this.controlKeyPressed) {
            const markerCoords = marker.getLatLng();
            if (marker.selected) {
                const manualIndex = this.manuallySelectedMarkers.findIndex((coords) => coords.equals(markerCoords))
                if (manualIndex == -1) {
                    // Marker is selected by area, not manually. Can't deselect
                    return;
                }
                marker.selected = false;
                this.objectsIdMapping[marker.id].selected = false;
                this?.mapFilter.updateMarker(this.objectsIdMapping[marker.id], marker);
                const idIndex = this.selectedIds.indexOf(marker.id);
                if (idIndex != -1) {
                    this.selectedIds.splice(idIndex, 1);
                }
                this.manuallySelectedMarkers.splice(manualIndex, 1);
            } else {
                marker.selected = true;
                this.objectsIdMapping[marker.id].selected = true;
                this?.mapFilter.updateMarker(this.objectsIdMapping[marker.id], marker);
                this.selectedIds.push(marker.id);
                this.manuallySelectedMarkers.push(marker.getLatLng());
            }
            this.markersCluster.refreshClusters();
            this.bulkSelectionChanged();
        } else {
            if (this.context == MapContext.THINGS) {
                this.navigationService.navigateTo(['dashboard/thing_details', marker.id]);
            } else {
                this.navigationService.navigateTo(['dashboard/location_details', marker.id]);
            }
        }
    }

    private createClusterIcon(cluster: any): any {
        const childrens = cluster.getAllChildMarkers();
        const clusterSelected = childrens.some(c => c.selected);
        const clusterRanks = childrens.map(c => c.rank);
        const maxRank = clusterRanks.reduce((prev, current) => (prev > current) ? prev : current);
        let clusterClass = null;
        if (clusterSelected) {
            clusterClass = this.mapFilter?.getRankDescriptors(this.objects).find(rd => rd.rank == maxRank).clusterSelectedClass;
        } else {
            clusterClass = this.mapFilter?.getRankDescriptors(this.objects).find(rd => rd.rank == maxRank).clusterClass;
        }
        return L.divIcon({
            html:
                `<svg width="34px" height="34px" viewBox="-0.5 -0.5 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="16" cy="16" rx="16" ry="16" fill="none" stroke="rgb(0, 0, 0)" pointer-events="all" />
                <g transform="translate(-0.5 -0.5)">
                <text x="16" y="20" fill="rgb(0, 0, 0)" text-anchor="middle">` + cluster.getChildCount() + `</text>
                </g>
                </svg>`,
            className: clusterClass,
            iconSize: [34, 34],
            iconAnchor: [17, 17],
        });
    }

    private drawHierarchyLines(): void {
        this.markersHierarchy.clearLayers();
        this.markersCluster.eachLayer(mk => {
            const thing = this.objectsIdMapping[mk.id] as ThingForMap;
            if (thing.parentThingId && this.objectsIdMapping[thing.parentThingId]) {
                const parentThing = this.objectsIdMapping[thing.parentThingId] as ThingForMap;
                let polyline = L.polyline(
                    [thing.latLng, parentThing.latLng], {
                    color: this.getHierarchyParentColor(thing.parentThingId),
                });
                this.markersHierarchy.addLayer(polyline);
            }
        });
    }

    private getHierarchyParentColor(parentId: string): string {
        if (this.hierarchyParentColorMapping[parentId]) {
            return this.hierarchyParentColorMapping[parentId];
        }
        const numColorTaken = Object.keys(this.hierarchyParentColorMapping).length;
        if (numColorTaken < HIERARCHY_COLOR_PALETTE.length) {
            this.hierarchyParentColorMapping[parentId] = HIERARCHY_COLOR_PALETTE[numColorTaken];
            return HIERARCHY_COLOR_PALETTE[numColorTaken];
        } else {
            const randomColor = '#' + Math.floor(Math.random() * 256 * 256 * 256).toString(16);
            this.hierarchyParentColorMapping[parentId] = randomColor;
            return randomColor;
        }
    }

    private createFitToContentButton(): void {
        const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control fit-control-button');
        const button = L.DomUtil.create('a', 'leaflet-control-button', container);
        L.DomUtil.create('i', 'fas fa-expand', button);
        L.DomEvent.disableClickPropagation(button);
        L.DomEvent.on(button, 'click', this.fitMapToBounds.bind(this));
        container.title = 'Fit Map to Content';
        return container;
    }

    private createToggleDraggingButton(): void {
        const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
        const button = L.DomUtil.create('a', 'leaflet-control-button', container);
        L.DomUtil.create('i', 'far fa-hand-paper', button);
        L.DomEvent.disableClickPropagation(button);
        L.DomEvent.on(button, 'click', () => {
            const draggingEnabled = this.map.dragging._enabled;
            if (draggingEnabled) {
                button.classList.remove('leaflet-control-button-toggled');
                this.map.dragging.disable();
            } else {
                button.classList.add('leaflet-control-button-toggled');
                this.map.dragging.enable();
            }
        });
        container.title = 'Toggle Dragging';
        return container;
    }

    private updateBounds(coordinates: L.latLng): void {
        if (!this.bounds) {
            this.bounds = {
                ne: Object.assign({}, coordinates),
                sw: Object.assign({}, coordinates)
            };
            return;
        }
        if (this.bounds.ne.lat < coordinates.lat) {
            this.bounds.ne.lat = coordinates.lat;
        } else if (this.bounds.sw.lat > coordinates.lat) {
            this.bounds.sw.lat = coordinates.lat;
        }

        if (this.bounds.ne.lng < coordinates.lng) {
            this.bounds.ne.lng = coordinates.lng;
        } else if (this.bounds.sw.lng > coordinates.lng) {
            this.bounds.sw.lng = coordinates.lng;
        }
    }

    private fitMapToBounds(): void {
        if (this.bounds) {
            const southWest = this.bounds.sw;
            const northEast = this.bounds.ne;
            const bounds = L.latLngBounds(southWest, northEast);
            this.map.fitBounds(bounds);
        }
    }

    onToggleAdvancedSearch(isOpen: boolean): void {
        this.advancedSearchOpen = isOpen;
    }

    onAdvancedSearchUpdate(body: any): void {
        this.advancedSearchBody = body;
        this.disableMapAction(this.selectedMapAction);
        this.selectedMapAction = null;
        this.prevMapAction = null;
        this.bounds = null;
        this.fetchData(true);
    }

    onMapActionClick(): void {
        // Handles the uncheck of a button-toggle
        if (this.prevMapAction != this.selectedMapAction) {
            if (this.prevMapAction) {
                this.disableMapAction(this.prevMapAction);
            }
            this.prevMapAction = this.selectedMapAction;
            this.enableMapAction(this.selectedMapAction);
        } else {
            this.disableMapAction(this.selectedMapAction);
            this.selectedMapAction = null;
            this.prevMapAction = null;
        }
    }

    enableMapAction(action: MapAction): void {
        switch (action) {
            case MapAction.BULK_SELECTION:
                this.enableBulkSelection();
                break;
            case MapAction.MOVE:
                this.enableMarkerMove();
                break;
            default:
                break;
        }
    }

    disableMapAction(action: MapAction): void {
        switch (action) {
            case MapAction.BULK_SELECTION:
                this.disableBulkSelection();
                break;
            case MapAction.MOVE:
                this.disableMarkerMove();
                break;
            default:
                break;
        }
    }

    private enableBulkSelection(): void {
        this.map.selectAreaFeature.enable();
    }

    private disableBulkSelection(): void {
        this.map.selectAreaFeature.disable();
        this.controlKeyPressed = false;
        this.clearBulkSelection();
    }

    clearBulkSelection(): void {
        this.map.selectAreaFeature.removeAllArea();
        this.selectedIds.forEach((id) => {
            if (this.objectsIdMapping[id]) {
                this.objectsIdMapping[id].selected = false;
            }
        });
        this.selectedIds = [];
        this.selectedAreas = [];
        this.manuallySelectedMarkers = [];
        this.loading = true;
        this.applyMapFilter();
        this.loading = false;
    }

    @HostListener('window:keydown.control', ['$event'])
    onControlKeyDown(): void {
        if (this.selectedMapAction == MapAction.BULK_SELECTION && !this.controlKeyPressed) {
            this.controlKeyPressed = true;
            this.map.selectAreaFeature.disable();
        }
    }

    @HostListener('window:keyup.control', ['$event'])
    onControlKeyUp(): void {
        if (this.selectedMapAction == MapAction.BULK_SELECTION) {
            this.controlKeyPressed = false;
            this.map.selectAreaFeature.enable();
        }
    }

    private onMapBulkSelectionDrawEnd(): void {
        const currentlySelected = this.map.selectAreaFeature.getFeaturesSelected('marker-cluster');
        if (currentlySelected) {
            this.selectedAreas.push(this.map.selectAreaFeature.getAreaLatLng());
            currentlySelected.forEach((mk) => {
                if (this.selectedIds.includes(mk.id)) {
                    const manualIndex = this.manuallySelectedMarkers.findIndex((coords) => coords.equals(mk.getLatLng()))
                    if (manualIndex != -1) {
                        // Since the manually selected marker is now included in an area, 
                        // it is removed it from manually selected list 
                        this.manuallySelectedMarkers.splice(manualIndex, 1);
                    }
                } else {
                    mk.selected = true;
                    this.objectsIdMapping[mk.id].selected = true;
                    this?.mapFilter.updateMarker(this.objectsIdMapping[mk.id], mk);
                }
            });
            this.markersCluster.refreshClusters();
            this.selectedIds = [...new Set([...this.selectedIds, ...currentlySelected.map(mk => mk.id)])];
            this.bulkSelectionChanged();
        }
    }

    private bulkSelectionChanged(): void {
        if (this.selectedIds.length) {
            const things = this.selectedIds.map(id => this.objectsIdMapping[id] as Thing);
            const parentCandidates = this.mapService.getParentThingCandidates(this.allRootThings, things);
            this.assignParentThingButtonEnabled = !!parentCandidates.length;
            const thingDefinitionIds = new Set(things.map(t => t.thingDefinitionId));
            this.mapService.getBulkUpdateAvailability(thingDefinitionIds).then(
                result => {
                    this.setTagButtonEnabled = result.taggingAvailable || false;
                    this.bulkCommandButtonEnabled = result.commandAvailable || false;
                    this.bulkRecipeButtonEnabled = result.recipeAvailable || false;
                    this.bulkFirmwareButtonEnabled = result.firmwareAvailable || false;
                }
            );
        }
    }

    private enableMarkerMove(): void {
        this.markersCluster.eachLayer(mk => {
            if (mk.dragging) { // Marker shown on map
                mk.dragging.enable();
            } else { // Marker in a cluster
                mk.options.draggable = true;
            }
        });
    }

    private disableMarkerMove(): void {
        this.markersCluster.eachLayer(mk => {
            if (mk.dragging) { // Marker shown on map
                mk.dragging.disable();
            } else { // Marker in a cluster
                mk.options.draggable = false;
            }
        });
    }

    private openDialog(componentType: ComponentType<any>, data?: any): Promise<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = data;
        dialogConfig.minWidth = '25%';
        return firstValueFrom(this.dialog.open(componentType, dialogConfig).afterClosed());
    }

    private showSnackbar(text: string, error?: boolean): void {
        this.labelService.getCustomLabel(text)
            .then(message => {
                this.snackBar.open(this.localizationPipe.transform(message), '', {
                    duration: 4000,
                    panelClass: error ? 'notification-error' : 'notification-info'
                });
            });
    }

    private onMarkerMoveEnd(event): void {
        const newCoords = event.target.getLatLng();
        const movedObj = this.objectsIdMapping[event.target.id];
        movedObj.latLng = newCoords;
        movedObj.gpsPosition = `${newCoords.lat},${newCoords.lng}`;
        let updatePromise;
        if (this.context == MapContext.THINGS) {
            updatePromise = this.mapService.updateThing(movedObj as Thing);
        } else {
            updatePromise = this.mapService.updateLocation(movedObj as Location);
        }
        updatePromise.then(() => {
            if (this.context == MapContext.THINGS) {
                this.showSnackbar('moveThingProperty');
            } else {
                this.showSnackbar('moveLocationProperty');
            }
            if (this.hierarchyFilterEnabled) {
                this.drawHierarchyLines();
            }
        }).catch(() => {
            if (this.context == MapContext.THINGS) {
                this.showSnackbar('moveThingErrorProperty', true);
            } else {
                this.showSnackbar('moveLocationErrorProperty', true);
            }
        });
    }

    bulkTag(): void {
        const data: BulkUpdateTagDialogData = {
            selectedThingIds: null,
            allElementsSelected: null,
            searchParams: this.mapService.buildParams(this.advancedSearchBody, this.searchFields),
            areaCoordinates: this.selectedAreas,
            selectedCoordinates: this.manuallySelectedMarkers,
            mapTagging: true
        }
        this.openDialog(BulkUpdateTagDialogComponent, data).then(result => {
            if (result) {
                this.showSnackbar('bulkTaggingExecutedProperty');
            }
        });
    }

    bulkUpdate(operation: string): void {
        const data: BulkOperationDialogData = {
            operationType: operation as BulkUpdateType,
            selectedThingIds: null,
            allElementsSelected: null,
            searchParams: this.mapService.buildParams(this.advancedSearchBody, this.searchFields),
            areaCoordinates: this.selectedAreas,
            selectedCoordinates: this.manuallySelectedMarkers,
            mapTagging: true,
            bulkUpdateItems: null
        }
        this.openDialog(BulkOperationDialogComponent, data).then(result => {
            if (result) {
                if (result == 'NOW') {
                    this.showSnackbar('bulkOperationExecutedProperty');
                } else {
                    this.showSnackbar('bulkOperationScheduledProperty');
                }
            }
        });
    }

    bulkParentAssign(): void {
        const things = this.selectedIds.map(id => this.objectsIdMapping[id]);
        const data: BulkParentAssignDialogData = {
            searchParams: this.mapService.buildParams(this.advancedSearchBody, this.searchFields),
            areaCoordinates: this.selectedAreas,
            selectedCoordinates: this.manuallySelectedMarkers,
            mapTagging: true,
            allElementsSelected: null,
            bulkUpdateParentThingItem: this.buildBulkUpdateParentThingItem(this.mapService.getParentThingCandidates(this.allRootThings, things)),
            selectedThingIds: null
        }
        this.openDialog(BulkParentAssignDialogComponent, data).then(result => {
            if (result) {
                this.showSnackbar('updateParentThingMessage');
                this.fetchData();
            }
        });
    }

    private buildBulkUpdateParentThingItem(candidates: Thing[]): BulkUpdateParentThingsResponse {
        let result: BulkUpdateParentThingsResponse = {
            multipleLocations: false,
            parentThings: {}
        }
        if (candidates && candidates.length) {
            candidates.forEach(t => result.parentThings[t.id] = t.name);
        }
        return result;
    }
}

class Bounds {
    ne: L.latLng;
    sw: L.latLng;
}

export enum MapContext {
    THINGS = 'THINGS',
    LOCATIONS = 'LOCATIONS'
}

export enum MapAction {
    BULK_SELECTION = 'BULK_SELECTION',
    MOVE = 'MOVE'
}