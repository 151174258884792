import { Metric } from './metric';
import { ThingDefinition } from './thing-definition';

export class Firmware {
    public id: string;
    public name: string;
    public description: string;
    public version: string;
    public size: number;
    public uploadDate: number;
    public md5: string;
    public thingDefinition: ThingDefinition;
    public versionMetric: Metric;
    public mandatory: boolean;
    public currentVersion: boolean;
    public updateProvider: string;
    public url: string;
    public unsecureHttp: boolean;
    public bulkExecution: boolean;
}

export enum FirmwareUpdateProvider {
    S3 = "S3",
    LEGACY = "LEGACY",
    URL = "URL"
}