import { Injectable, Pipe } from "@angular/core";
import { CompositePartComponent, MetricDetailComponent } from '../component';
import { ButtonTableColumn } from "./custom-table-column/button-table-column";
import { CompositeTableColumn } from './custom-table-column/composite-table-column';
import { CustomTableColumn } from "./custom-table-column/custom-table-column";
import { CustomerLinkTableColumn } from './custom-table-column/customer-link-table-column';
import { DatetimeTableColumn } from "./custom-table-column/datetime-table-column";
import { IconTableColumn, IconValue } from "./custom-table-column/icon-table-column";
import { ImageTableColumn } from "./custom-table-column/image-table-column";
import { LocationLinkTableColumn } from './custom-table-column/location-link-table-column';
import { MetricTableColumn } from './custom-table-column/metric-table-column';
import { OrderLinkTableColumn } from "./custom-table-column/order-link-table-column";
import { ParentThingTableColumn } from './custom-table-column/parent-thing-table-column';
import { PipedTableColumn } from "./custom-table-column/piped-table-column";
import { SimpleTableColumn } from "./custom-table-column/simple-table-column";
import { SimpleUntranslatedTableColumn } from './custom-table-column/simple-untranslated-table-column';
import { StoreOrderBuyerTableColumn } from "./custom-table-column/store-order-buyer-table-column";
import { TagTableColumn } from './custom-table-column/tag-table-column';
import { TimerTableColumn } from "./custom-table-column/timer-table-column";

@Injectable()
export class CustomTableService {

    static newSimpleColumn(name: string, label: string, path: string): CustomTableColumn {
        return new SimpleTableColumn(name, label, path);
    }

    static newPipedColumn(name: string, label: string, path: string, pipe: Pipe | string): PipedTableColumn {
        return new PipedTableColumn(name, label, path, pipe);
    }

    static newDatetimeColumn(name: string, label: string, path: string, format: string, timezone: string): DatetimeTableColumn {
        return new DatetimeTableColumn(name, label, path, format, timezone);
    }

    static newTimerColumn(name: string, label: string, startTimestampPath: string, endTimestampPath: string): TimerTableColumn {
        return new TimerTableColumn(name, label, startTimestampPath, endTimestampPath);
    }

    static newButtonColumn(name: string, label: string, path: string, btnClass: string, btnLabel: string): ButtonTableColumn {
        return new ButtonTableColumn(name, label, path, btnClass, btnLabel);
    }

    static newCustomerLinkColumn(label: string, path: string, idPath?: string): CustomerLinkTableColumn {
        return new CustomerLinkTableColumn(label, path, idPath);
    }

    static newLocationLinkColumn(label: string, path: string, idPath?: string): LocationLinkTableColumn {
        return new LocationLinkTableColumn(label, path, idPath);
    }

    static newMetricColumn(metricDetailComponent: MetricDetailComponent, label: string, pipe: Pipe | string): MetricTableColumn {
        return new MetricTableColumn(metricDetailComponent, label, pipe);
    }

    static newCompositeColumn(compositePartComponent: CompositePartComponent, label: string, pipe: Pipe | string): CompositeTableColumn {
        return new CompositeTableColumn(compositePartComponent, label, pipe);
    }

    static newTagColumn(name: string, label: string, path: string, pipe: Pipe | string): TagTableColumn {
        return new TagTableColumn(name, label, path, pipe);
    }

    static newBulkUpdateCountColumn(name: string, label: string, path: string, pipe: Pipe | string, argument: string[]): PipedTableColumn {
        return new PipedTableColumn(name, label, path, pipe).withArgument(argument);
    }

    static newParentThingColumn(label: string, path: string): ParentThingTableColumn {
        return new ParentThingTableColumn(label, path);
    }

    static newSimpleUntranslatedColumn(name: string, label: string, path: string): CustomTableColumn {
        return new SimpleUntranslatedTableColumn(name, label, path);
    }

    static newIconColumn(name: string, label: string, path: string, iconValueMap: { [value: string]: IconValue }, hideTitle?: boolean): IconTableColumn {
        let style = hideTitle ? { 'width': '1%', 'textAlign': 'center' } : { 'textAlign': 'center' }; // if hidden title , sets width to 1%
        return (new IconTableColumn(name, label, path, iconValueMap, hideTitle).withStyle({ '_any': style }) as IconTableColumn);
    }

    static newImageColumn(name: string, label: string, path: string): CustomTableColumn {
        return new ImageTableColumn(name, label, path);
    }

    static newOrderLinkColumn(label: string, path: string, idPath?: string): OrderLinkTableColumn {
        return new OrderLinkTableColumn(label, path, idPath);
    }

    static newStoreOrderBuyerColumn(label: string): StoreOrderBuyerTableColumn {
        return new StoreOrderBuyerTableColumn(label);
    }
}


