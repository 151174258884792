import { forwardRef, Inject, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StatisticItem } from '../../model';
import { AlertSeverityPipe } from './alert-severity.pipe';
import { BulkUpdateLocalizationPipe } from './bulk-update-localization.pipe';
import { ByteFormatterPipe } from './byte-formatter.pipe';
import { DefaultCloudStatusPipe } from './default-cloud-status.pipe';
import { DefaultConnectionStatusPipe } from './default-connection-status.pipe';
import { DefaultEventSeverityPipe } from './default-event-severity.pipe';
import { DefaultServiceLevelPipe } from './default-service-level.pipe';
import { DefaultSimSessionStatusPipe } from './default-sim-session-status.pipe';
import { LoaderPipe } from './loader.pipe';
import { UnderscoreRemoverPipe } from './underscore-remover.pipe';

@Pipe({ name: 'statistic' })
export class StatisticPipe extends LoaderPipe {

    constructor(
        @Inject(forwardRef(() => DomSanitizer)) sanitizer: DomSanitizer,
        @Inject(forwardRef(() => DefaultServiceLevelPipe)) defaultServiceLevelPipe: DefaultServiceLevelPipe,
        @Inject(forwardRef(() => UnderscoreRemoverPipe)) underscoreRemoverPipe: UnderscoreRemoverPipe,
        @Inject(forwardRef(() => BulkUpdateLocalizationPipe)) bulkUpdateLocalizePipe: BulkUpdateLocalizationPipe,
        @Inject(forwardRef(() => ByteFormatterPipe)) byteFormatterPipe: ByteFormatterPipe,
        @Inject(forwardRef(() => DefaultSimSessionStatusPipe)) defaultSimSessionStatusPipe: DefaultSimSessionStatusPipe,
        @Inject(forwardRef(() => AlertSeverityPipe)) alertSeverityPipe: AlertSeverityPipe,
        @Inject(forwardRef(() => DefaultCloudStatusPipe)) defaultCloudStatusPipe: DefaultCloudStatusPipe,
        @Inject(forwardRef(() => DefaultConnectionStatusPipe)) defaultConnectionStatusPipe: DefaultConnectionStatusPipe,
        @Inject(forwardRef(() => DefaultEventSeverityPipe)) defaultEventSeverityPipe: DefaultEventSeverityPipe
    ) { super(sanitizer, defaultServiceLevelPipe, underscoreRemoverPipe, bulkUpdateLocalizePipe, byteFormatterPipe, defaultSimSessionStatusPipe, alertSeverityPipe, defaultCloudStatusPipe, defaultConnectionStatusPipe, defaultEventSeverityPipe) }

    transform(valueInput: { statisticItems: StatisticItem[], filter: string | Function }): string {
        if (valueInput && valueInput.statisticItems) {
            let values = valueInput.statisticItems;
            if (values[0].category == 'Result') {
                let value = values[0].value.toString();
                if (valueInput.filter) {
                    value = super.transform(value, valueInput.filter, true);
                }
                return value;
            } else {
                return this.handleGroupBy(values, valueInput.filter);
            }
        } else {
            return '-';
        }
    }

    private handleGroupBy(values: StatisticItem[], filter: string | Function): string {
        let html = ``;
        if (values.some(item => item.value instanceof Array)) {
            values.forEach(item => {
                html += `
                <table class="table table-bordered">
            `;
                let head = `
            <thead>
                <tr>
            `;
                let body = `
            <tbody> 
            `;
                const itemValues: any = item.value;
                head += `
                        <th colspan="2" style="text-align: center;">${item.category}</th>
                        `;
                itemValues.forEach(itemValue => {
                    if (filter) {
                        itemValue.value = super.transform(itemValue.value, filter, true);
                    }
                    body += `<tr>
                        <td style="width: 50%">${itemValue.category} </td>
                        <td>${itemValue.value} </td>
                        </tr>`;
                });
                html += head + `</tr></thead>`;
                html += body + `</tbody> </table>`;
            });
        } else {
            html += `
                <table class="table table-bordered">
            `;
            let body = ``;
            values.forEach(item => {
                if (filter) {
                    item.value = super.transform(item.value, filter, true);
                }
                body += `<tr>
                        <td style="width: 50%">${item.category} </td>
                        <td>${item.value} </td>
                        </tr>`;
            });
            html += body + `</tr> </tbody> </table>`;
        }
        return html;
    }

}
