import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { SPARE_PART_DEFINITIONS, SPARE_PART_DEFINITION_BY_ID, SPARE_PART_DEFINITION_CATEGORIES } from "../../common/endpoints";
import { PagedList } from "../../model";
import { SparePartDefinition } from "../../model/spare-part-definition";
import { SparePartDefinitionCategory } from "../../model/spare-part-definition-category";
import { HttpService } from "../../service/http.service";
import { TreeNodeService } from "../../service/tree-node.service";
import { TreeNode } from "../../shared/component/tree-list/tree-list.component";

@Injectable()
export class SparePartDefinitionService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => TreeNodeService)) private treeNodeService: TreeNodeService
    ) { }

    getSparePartDefinitionCategories(): Promise<SparePartDefinitionCategory[]> {
        let categories: SparePartDefinitionCategory[] = [];
        let page = 0;
        return this.getRecursivelyAllPages(page, categories);
    }

    private getRecursivelyAllPages(page: number, categories: SparePartDefinitionCategory[]): Promise<SparePartDefinitionCategory[]> {
        return this.getPagedSparePartDefinitionCategories(page, 100)
            .then(pagedCategoryDefs => {
                categories = categories.concat(pagedCategoryDefs.content);
                if (pagedCategoryDefs.last) {
                    return categories;
                } else {
                    return this.getRecursivelyAllPages(++page, categories);
                }
            });
    }

    getPagedSparePartDefinitionCategories(pageIndex: number, pageSize: number): Promise<PagedList<SparePartDefinitionCategory>> {
        let params = new HttpParams();
        params = params.set('page', pageIndex + '');
        params = params.set('size', pageSize + '');
        params = params.set('sort', 'name, asc');
        return firstValueFrom(this.httpService.get<PagedList<SparePartDefinitionCategory>>(SPARE_PART_DEFINITION_CATEGORIES, params));
    }

    fillSparePartDefinitionCategoryTreeNodes(sparePartDefinitionCategories: SparePartDefinitionCategory[]): TreeNode[] {
        return this.treeNodeService.fillTreeNodes(sparePartDefinitionCategories, "parentId");
    }

    getSparePartDefinitionById(id: string): Promise<SparePartDefinition> {
        return firstValueFrom(this.httpService.get<SparePartDefinition>(SPARE_PART_DEFINITION_BY_ID.replace('{id}', id)));
    }

    getPagedList(page: number, size: number, sort: string[], categoryId: string, productModelId: string, searchString: string, productModelPartId?: string): Promise<PagedList<SparePartDefinition>> {
        let params = new HttpParams();
        if (sort?.length) {
            params = params.set('sort', sort.join(','));
        } else {
            params = params.set('sort', 'name, asc');
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (categoryId) {
            params = params.set('categoryId', categoryId);
            params = params.set('includeDescendants', true);
        }
        if (productModelId) {
            params = params.set('productModelId', productModelId);
        }
        if (searchString) {
            params = params.set('searchText', "*" + searchString + "*");
        }
        if (productModelPartId) {
            params = params.set('productModelPartId', productModelPartId);
        }
        return firstValueFrom(this.httpService.get<PagedList<SparePartDefinition>>(SPARE_PART_DEFINITIONS, params));
    }

}