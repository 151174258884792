import { SelectionModel } from "@angular/cdk/collections";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ConfigurationParameter } from "../../model";

@Component({
    selector: 'recipe-parameter-selector',
    template: require('./recipe-parameter-selector.component.html'),
    styles: [require('./recipe-parameter-selector.component.css')]
})
export class RecipeParameterSelectorComponent implements OnInit {

    @Input() parameters: ConfigurationParameter[];

    @Input() selectedParameters: ConfigurationParameter[];

    @ViewChild(MatSort) sort: MatSort;

    displayedColumns: string[] = ['select', 'group', 'label'];
    dataSource: MatTableDataSource<ConfigurationParameter>;
    selection: SelectionModel<ConfigurationParameter>;

    ngOnInit(): void {
        this.selection = new SelectionModel<ConfigurationParameter>(true, this.selectedParameters.length ? this.selectedParameters : []);
        this.dataSource = new MatTableDataSource<ConfigurationParameter>(this.parameters);
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    doSimpleSearch(body: any) {
        const key = body.key;
        if (key) {
            const filtered = this.parameters.filter(p => p.label?.includes(key) || p.group?.includes(key));
            this.dataSource = new MatTableDataSource<ConfigurationParameter>(filtered);
        } else {
            this.dataSource = new MatTableDataSource<ConfigurationParameter>(this.parameters);
        }
    }

    isAllSelected(): boolean {
        return this.dataSource.data?.every(p =>
            !!this.selection.selected?.find(selectedPar => selectedPar.id == p.id)
        );
    }

    isSomeSelected(): boolean {
        return this.dataSource.data?.some(p =>
            !!this.selection.selected?.find(selectedPar => selectedPar.id == p.id)
        );
    }

    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.deselect(...this.dataSource.data);
            return;
        }
        this.selection.select(...this.dataSource.data);
    }

    getSelected(): ConfigurationParameter[] {
        return this.selection.selected.sort((a, b) => (a.name > b.name) ? 1 : -1);
    }
}

