import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import * as _ from 'lodash';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { AlertSeverities } from '../../../../common/constants';
import { THING_DEFINITIONS } from '../../../../common/endpoints';
import { CustomerService } from '../../../../dashboard-area/customer/customer.service';
import { AlertService } from '../../../../dashboard-area/shared/alert.service';
import { CustomPropertyDefinition, LocationAlertDefinition, ThingDefinition } from '../../../../model';
import { AlertDefinitionService } from '../../../../service/alert-definition.service';
import { AuthenticationService } from '../../../../service/authentication.service';
import { ContextService } from '../../../../service/context.service';
import { CustomPropertyService, CustomPropertyType } from '../../../../service/custom-property.service';
import { CustomDateRange, DateRangeService } from '../../../../service/date-range.service';
import { FieldService } from '../../../../service/field.service';
import { HttpService } from '../../../../service/http.service';
import { AbstractContextService } from '../../../class/abstract-context-service.class';
import { AbstractThingContextService } from '../../../class/abstract-thing-context-service.class';
import { FormOption } from '../../../form-editor/form-field-type/form-option.interface';
import { LocalizationPipe } from '../../../pipe';
import { AbstractAdvancedSearchComponent } from '../abstract-advanced-search.component';
import { AlertAdvancedSearchAddPropertiesDialog } from './alert-advanced-search-add-properties-dialog.component';

@Component({
    selector: 'alert-advanced-search',
    template: require('./alert-advanced-search.component.html'),
    styles: [require('../thing-advanced-search/thing-advanced-search.component.css')],
    providers: [CustomerService]
})
export class AlertAdvancedSearchComponent extends AbstractAdvancedSearchComponent implements OnInit {

    @Input() isHistorical: boolean;

    @ViewChild(AlertAdvancedSearchAddPropertiesDialog) addPropertiesDialog: AlertAdvancedSearchAddPropertiesDialog;

    private addCustomerSearchField: boolean;
    private searchDataInitialized: boolean;
    private alertDefinitionsTree: any[];
    private thingDefinitionTypes: { value: string, label: string }[];
    private severities: { value: string, label: string }[];
    private locationAlertDefinitions: { value: string, label: string }[];
    private alertDefinitionGroups: { groupId: string, groupLabel: string, groupValues: FormOption[] }[] = [];
    private defaultCustomDateRange: CustomDateRange;
    private queryableProperties = ['group', 'name', 'acknowledgedTime', 'acknowledgedUser.email'];

    defaultProperties: { name: string, label: string }[] = [
    ];
    thingDefinitionProperties: CustomPropertyDefinition[];

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => CustomerService)) private customerService: CustomerService,
        @Inject(forwardRef(() => AlertDefinitionService)) private alertDefinitionService: AlertDefinitionService,
        @Inject(forwardRef(() => AlertService)) private alertService: AlertService,
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService
    ) { super(localizationPipe, fieldService) }

    ngOnInit(): void {
        this.localStorageKey = this.queryId || (this.isHistorical ? 'historicalAlertAdvancedSearchFieldsValues' : 'alertAdvancedSearchFieldsValues');
        this.savedFieldsValues = localStorage.getItem(this.localStorageKey) ? JSON.parse(localStorage.getItem(this.localStorageKey)) : null;
        const customerId = this.authenticationService.getUser().customerId || (this.contextService.getCurrentCustomer() ? this.contextService.getCurrentCustomer().id : null)
            || (this.authenticationService.isLocationUser() ? (ContextService.getCustomerFromLocation(this.authenticationService.getUser().location) ? ContextService.getCustomerFromLocation(this.authenticationService.getUser().location).id : null) : null);
        this.addCustomerSearchField = ((this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && !customerId) || this.authenticationService.getUser().hostCustomers?.length > 0;
        const userFields = _.cloneDeep(this.authenticationService.getUser().alertsSearchFields);
        this.searchFields = this.getSearchFields(userFields);
        this.thingDefinitionProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.ThingDefinition).filter(def => def.searchable);
        this.getAlertProperties();
        if (this.isHistorical) {
            this.defaultCustomDateRange = this.dateRangeService.getCustomDateRangeByName("LAST_7_DAYS");
        }
        if (this.query && this.query.length) {
            this.getEncodedQueryFields();
        }
        if (this.queryFieldRef) {
            this.subscribeToQueryFieldRef();
        } else {
            if (this.savedFieldsValues || this.alwaysExpanded || (this.isHistorical && this.thingContextService.getCurrentWorkSession())) {
                this.showHideAdvancedSearch().then(() => this.waitForAdvancedSearchRenderedAndPerformSearch());
            } else if (this.encodedQueryFields) {
                this.loadData(null, this.encodedQueryFields);
            }
        }
    }

    advancedSearch($event?): void {
        this.simpleSearchKey = null;
        const rawValue = this.advancedSearchEditor.getObjectValue();
        const key = this.advancedSearchBarEditor.getObjectValue()['key'];
        const rangeValue: { range: DateRange<moment.Moment>, rangeName: string } = rawValue['period'];
        const range: DateRange<moment.Moment> = rangeValue?.rangeName ? this.dateRangeService.getCustomDateRangeByName(rangeValue.rangeName)?.range : rangeValue?.range;
        const allAlertDefinitions = _.get(rawValue, "alertDefinitionIds") ? _.get(rawValue, "alertDefinitionIds") : [];
        const alertDefinitionIds = allAlertDefinitions.filter((id: string) => !id.startsWith("LOCATION_ALERT_DEF:"));
        let locationAlertDefinitionIds = allAlertDefinitions.filter((id: string) => id.startsWith("LOCATION_ALERT_DEF:"));
        locationAlertDefinitionIds = locationAlertDefinitionIds.map(id => { return id.substring(19) });
        const fields = {
            key: key,
            thingDefinitions: alertDefinitionIds.length || locationAlertDefinitionIds.length ? null : (rawValue['thingDefinitionIds']?.length ? rawValue['thingDefinitionIds'] : null),
            customer: rawValue['customer'] || null,
            severities: rawValue['severities'] || null,
            startTimestamp: range?.start?.valueOf(),
            endTimestamp: range?.end?.valueOf(),
            alertDefinitions: alertDefinitionIds?.length ? alertDefinitionIds : null,
            locationAlertDefinitions: locationAlertDefinitionIds?.length ? locationAlertDefinitionIds : null

        };
        let fieldsToSave = {
            key: key,
            thingDefinitions: alertDefinitionIds.length || locationAlertDefinitionIds.length ? null : rawValue['thingDefinitionIds'],
            customer: rawValue['customer'],
            severities: rawValue['severities'],
            alertDefinitions: allAlertDefinitions
        };
        if (rangeValue?.rangeName) {
            fieldsToSave['rangeName'] = rangeValue?.rangeName;
        } else {
            fieldsToSave['startTimestamp'] = range?.start?.valueOf();
            fieldsToSave['endTimestamp'] = range?.end?.valueOf();
        }
        let encodedBody = Object.assign({}, fields, this.propertiesInputs ? this.propertiesInputs.getEncodedBody() : null);
        let fieldsToSaveBody = Object.assign({}, fieldsToSave, this.propertiesInputs ? this.propertiesInputs.getBody() : null);
        if (this.query && this.query.length) {
            encodedBody = Object.assign({}, encodedBody, this.encodedQueryFields);
            fieldsToSaveBody = this.removeQueryFields(fieldsToSaveBody);
        }
        if (this.isHistorical && this.thingContextService.getCurrentWorkSession()) {
            delete fieldsToSaveBody['startTimestamp'];
            delete fieldsToSaveBody['endTimestamp'];
            delete fieldsToSaveBody['rangeName'];
        }
        this.updateLocalStorage(fieldsToSaveBody);
        const encodedBodyValues = Object.keys(encodedBody).map(el => encodedBody[el]);
        if (encodedBodyValues.some(el => el != null)) {
            this.loadData(key, encodedBody);
        } else {
            this.loadData();
        }
        if ($event) {
            const eventObject = $event.currentTarget;
            eventObject.blur();
        }
    }

    protected initConfigurations(): Promise<void> {
        let advancedSearchBarConfiguration = [];
        advancedSearchBarConfiguration.push({ name: 'key', type: 'SEARCH', value: this.getValue('key') || this.simpleSearchKey });
        this.advancedSearchBarConfiguration = advancedSearchBarConfiguration;
        return this.getSearchData().then(() => {
            let advancedSearchConfiguration = [];
            if (this.addCustomerSearchField) {
                advancedSearchConfiguration.push({ name: 'customer', label: 'customerProperty', type: 'CUSTOMER_SEARCH', value: this.getValue('customer'), disabled: this.isQueryField('customer'), defaultValue: this.isQueryField('customer') ? this.getValue('customer') : null });
            }
            advancedSearchConfiguration.push({ name: 'severities', label: 'alertDefinitionSeverityProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: this.severities, value: this.getValue('severities'), multipleSelection: true, disabled: this.isQueryField('severities'), defaultValue: this.isQueryField('severities') ? this.getValue('severities') : null, placeholder: "All Severities" });
            advancedSearchConfiguration.push({ name: 'period', 'label': 'periodProperty', type: 'PERIOD', value: this.getDateRange() || { range: null, rangeName: this.defaultCustomDateRange?.name }, defaultValue: this.isQueryField('startTimestamp') && this.isQueryField('endTimestamp') ? this.getDateRange() : null });
            if (this.thingDefinitionTypes.length > 1 && !this.thingContextService.getCurrentThing()) {
                advancedSearchConfiguration.push({ name: 'thingDefinitionIds', label: 'thingDefinitionsTabItem', type: 'STRING', selectionMode: 'MAT_SELECTION', values: this.thingDefinitionTypes, value: this.getValue('thingDefinitions'), multipleSelection: true, disabled: this.isQueryField('thingDefinitions'), defaultValue: this.isQueryField('thingDefinitions') ? this.getValue('thingDefinitions') : null, placeholder: "All Thing Definitions" });
            }
            if (this.alertDefinitionsTree && this.alertDefinitionsTree.length) {
                this.alertDefinitionGroups = this.alertDefinitionsTree.map(el => { return { groupId: el.id, groupLabel: el.label, groupValues: el.children.map(child => { return { value: child.id, label: child.label } }) } });
            }
            if (this.locationAlertDefinitions && this.locationAlertDefinitions.length) {
                this.alertDefinitionGroups.push({ groupId: null, groupLabel: this.localizationPipe.transform('Location Alert Definitions'), groupValues: this.locationAlertDefinitions });
            }
            if (this.alertDefinitionGroups && this.alertDefinitionGroups.length) {
                advancedSearchConfiguration.push({ name: 'alertDefinitionIds', label: 'alertDefinitionTypeProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: [], value: this.getValue('alertDefinitions'), multipleSelection: true, enableMatSelectGroups: true, matSelectGroupValues: this.alertDefinitionGroups, disabled: this.isQueryField('alertDefinitions'), defaultValue: this.isQueryField('alertDefinitions') ? this.getValue('alertDefinitions') : null, placeholder: "All Alert Types" });
            }
            this.fieldsPerRow = 3;
            this.advancedSearchConfiguration = advancedSearchConfiguration;
            setTimeout(() => this.updateAlertDefinitionGroup('thingDefinitionIds'), 10);
        });
    }

    private getSearchData(): Promise<void> {
        if (!this.searchDataInitialized) {
            this.searchDataInitialized = true;
            let promises = [];
            promises.push(firstValueFrom(this.httpService.get<ThingDefinition[]>(THING_DEFINITIONS)).catch(() => []));
            promises.push(this.alertService.getAlertDefinitions().catch(() => []));
            promises.push(this.alertDefinitionService.getLocationAlertDefintions().catch(() => []));
            if (this.addCustomerSearchField) {
                if (this.authenticationService.getUser().hostCustomers?.length) {
                    const customerIds = this.authenticationService.getUser().hostCustomers.map(hc => { return hc.customerId });
                    promises.push(Promise.all(customerIds.map(id => { return this.customerService.getCustomerById(id) })).catch(() => []));
                } else {
                    let partnerParam = null;
                    if (this.contextService.getCurrentPartner()) {
                        partnerParam = new HttpParams().set('partnerId', this.contextService.getCurrentPartner().id);
                    }
                }
            }
            return Promise.all(promises).then(results => {
                let tenant = this.authenticationService.getTenant();
                this.severities = AlertSeverities.filter(s => {
                    if (tenant.alertCriticalSeverityType == 'FAILURE') {
                        return s.value != 'CRITICAL';
                    } else if (tenant.alertCriticalSeverityType == 'CRITICAL') {
                        return s.value != 'FAILURE';
                    }
                    return true;
                });
                this.thingDefinitionTypes = results[0].map((td: ThingDefinition) => { return { value: td.id, label: td.name } });
                this.alertDefinitionsTree = this.alertService.buildTree(results[1]);
                this.locationAlertDefinitions = results[2].map((locDef: LocationAlertDefinition) => { return { value: "LOCATION_ALERT_DEF:" + locDef.id, label: locDef.name }; });
            });
        }
        return Promise.resolve();
    }

    getEncodedQueryFields(): void {
        let fields = [];
        this.query.forEach(el => {
            if (el.property == 'severity') {
                el.property = 'severities';
            }
            if (this.queryableProperties.includes(el.property) || this.advancedSearchAddibleProperties.some(p => p.name == el.property || "properties." + p.name == el.property)) {
                let propDef: CustomPropertyDefinition = null;
                if (el.property.startsWith('thingDefinition.properties.')) {
                    propDef = this.thingDefinitionProperties.find(prop => el.property == "thingDefinition.properties." + prop.name);
                }
                fields[el.property] = this.getQueryValueWithSuffixes(el.value, el.predicate, propDef);
            } else {
                fields[el.property] = el.value;
            }
        });
        this.encodedQueryFields = fields;
    }

    private getDateRange(): { range: DateRange<moment.Moment>, rangeName: string } {
        if (this.isHistorical && this.thingContextService.getCurrentWorkSession()) {
            const ws = this.thingContextService.getCurrentWorkSession()
            const start = ws.startTimestamp;
            const end = ws.endTimestamp || new Date().getTime();
            return { range: new DateRange(moment(start), moment(end)), rangeName: null };
        } else {
            const rangeName = this.getValue('rangeName');
            if (rangeName) {
                return { range: null, rangeName: rangeName };
            } else {
                const start = this.getValue('startTimestamp');
                const end = this.getValue('endTimestamp');
                if (start && end) {
                    return { range: new DateRange(moment(start), moment(end)), rangeName: null };
                }
            }
            return null;
        }
    }

    updateAlertDefinitionGroup(name: string): void {
        if (name == 'thingDefinitionIds' && this.advancedSearchEditor) {
            const rawValues = this.advancedSearchEditor.getObjectValue();
            const thingDefintionIds: string[] = _.get(rawValues, 'thingDefinitionIds') || [];
            const configurations = _.cloneDeep(this.advancedSearchConfiguration);
            const groupValues = this.alertDefinitionGroups.filter(group => thingDefintionIds.includes(group.groupId));
            if (configurations.find(el => el.name == 'alertDefinitionIds')) {
                if (thingDefintionIds && thingDefintionIds.length) {
                    if (groupValues && groupValues.length) {
                        configurations.find(el => el.name == 'alertDefinitionIds').matSelectGroupValues = groupValues;
                    } else {
                        configurations.splice(configurations.indexOf(el => el.name == 'alertDefinitionIds'), 1);
                    }
                } else {
                    configurations.find(el => el.name == 'alertDefinitionIds').matSelectGroupValues = this.alertDefinitionGroups;
                }
            } else {
                if (thingDefintionIds && thingDefintionIds.length) {
                    if (groupValues && groupValues.length) {
                        configurations.push({ name: 'alertDefinitionIds', label: 'alertDefinitionTypeProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: [], multipleSelection: true, enableMatSelectGroups: true, matSelectGroupValues: groupValues });
                    }
                } else if (this.alertDefinitionGroups && this.alertDefinitionGroups.length) {
                    configurations.push({ name: 'alertDefinitionIds', label: 'alertDefinitionTypeProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: [], multipleSelection: true, enableMatSelectGroups: true, matSelectGroupValues: this.alertDefinitionGroups });
                }
            }
            configurations.forEach(conf => {
                if (conf.name != 'alertDefinitionIds') {
                    conf.value = rawValues[conf.name];
                }
            });
            this.advancedSearchConfiguration = configurations;
        }
    }

    openAddMorePropertiesDialog(): void {
        if (this.useExternalAddPropertiesDialog) {
            this.emitOpenExternalAddPropertiesDialogAction(this.advancedSearchAddibleProperties);
        } else {
            this.addPropertiesDialog.open();
        }
    }

    private getAlertProperties(): void {
        this.advancedSearchAddibleProperties = [];
        if (this.thingDefinitionProperties) {
            this.updateAdvancedSearchAddibleProperties(this.thingDefinitionProperties, 'thingDefinition.properties.', null);
        }
    }
}