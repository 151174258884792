import { Component, EventEmitter, Inject, Input, OnInit, Output, forwardRef } from "@angular/core";
import { AuditEvent } from "../../model/audit-event";
import { LoaderPipe } from "../../shared/pipe";
import { NavigationService } from "../../service/navigation.service";
import { ContextService } from "../../service/context.service";
import { AuthenticationService } from "../../service/authentication.service";
import { Permissions } from '../../common/constants';

@Component({
    selector: 'audit-event-detail',
    template: require('./audit-event-detail.component.html'),
    styles: [`
        .outer-container {
            gap: 32px;
        }
        .outer-container-margin {
            margin: 24px; 
        }
        .details-container {
            padding: 10px;
            border-radius: 4px;
            background-color: #64c9d11A;         
        }
    `]
})
export class AuditEventDetailComponent implements OnInit {

    @Input() auditEvent: AuditEvent;

    @Input() detailsFilter: string;

    @Input() isMobile: boolean;

    @Output() navigateToResource = new EventEmitter();

    details: string;

    resourcePath: string[] = [];

    constructor(
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => ContextService)) protected contextService: ContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
    ) { }

    ngOnInit(): void {
        if (this.detailsFilter) {
            this.details = this.loaderPipe.transform(this.auditEvent, this.detailsFilter, true);
        } else {
            this.details = this.auditEvent.message;
        }
        this.computeResourceUrl();
    }

    private computeResourceUrl(): void {
        if (this.auditEvent.thingId) {
            this.resourcePath = ['/dashboard/thing_details', this.auditEvent.thingId];
        } else if (this.auditEvent.userId) {
            this.computeUserResource();
        } else {
            this.computeBusinessEntityResource();
        }



    }

    private computeUserResource() {
        if (!this.authenticationService.hasPermission(Permissions.READ_USER)) {
            return;
        }
        if (this.auditEvent.locationId) {
            if (!this.authenticationService.checkPermission(Permissions.READ_LOCATION)) {
                return;
            }
        } else if (this.auditEvent.customerId) {
            if (
                !(this.authenticationService.isCustomerUser() && this.authenticationService.checkPermission(Permissions.WRITE_CUSTOMER)) &&
                !(!this.authenticationService.isCustomerUser() && this.authenticationService.checkPermission(Permissions.READ_CUSTOMER))
            ) {
                return;
            }
        } else if (this.auditEvent.partnerId) {
            if (!this.authenticationService.checkPermission(Permissions.READ_PARTNER)) {
                return;
            }
        } else if (this.auditEvent.organizationId) {
            if (!this.authenticationService.checkPermission(Permissions.READ_ORGANIZATION)) {
                return;
            }
        } else {
            return;
        }

        this.resourcePath = ['/dashboard/users', this.auditEvent.userId];
    }

    computeBusinessEntityResource() {
        if (this.auditEvent.locationId) {
            if (this.authenticationService.isLocationUser()) {
                if (this.authenticationService.checkPermission(Permissions.WRITE_LOCATION)) {
                    this.resourcePath = ['/dashboard/account'];
                }
            } else {
                this.resourcePath = ['/dashboard/location_details', this.auditEvent.locationId];
            }
        } else if (this.auditEvent.customerId) {
            if (this.authenticationService.isCustomerUser()) {
                if (this.authenticationService.checkPermission(Permissions.WRITE_CUSTOMER)) {
                    this.resourcePath = ['/dashboard/account'];
                }
            } else {
                this.resourcePath = ['/dashboard/customer_details', this.auditEvent.customerId];
            }
        } else if (this.auditEvent.partnerId) {
            if (this.authenticationService.isPartnerUser()) {
                if (this.authenticationService.checkPermission(Permissions.WRITE_PARTNER)) {
                    this.resourcePath = ['/dashboard/account'];
                }
            } else {
                this.resourcePath = ['/dashboard/partner_details', this.auditEvent.partnerId];
            }
        } else if (this.auditEvent.organizationId) {
            if (this.authenticationService.getUser().organizationId == this.auditEvent.organizationId) {
                this.resourcePath = ['/dashboard/organization'];
            } else {
                this.resourcePath = ['/dashboard/organization', this.auditEvent.organizationId];
            }
        }
    }

    goToResource() {
        this.navigateToResource.emit();
        this.navigationService.navigateTo(this.resourcePath);
    }
}