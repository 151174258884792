import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { Firmware, Value } from "../../model";


enum UpdateState {
    DEFAULT = 'DEFAULT',
    UPDATING = 'UPDATING',
    UPDATING_SUCCESS = 'UPDATING_SUCCESS',
    UPDATING_FAILURE = 'UPDATING_FAILURE'
}

@Component({
    selector: 'thing-firmware-element',
    template: require('./thing-firmware-element.component.html'),
    styles: [require('./thing-firmware-element.component.css')],
})
export class ThingFirmwareElementComponent implements OnInit {

    @Input() firmware: Firmware;

    @Input() version$: BehaviorSubject<Value>;

    @Input() errorNotifier$: BehaviorSubject<boolean>;

    @Input() writePermission: boolean;

    @Input() thingId: string;

    @Input() timeout: number; //seconds

    @Input() updateOngoing: boolean;

    @Output() update = new EventEmitter();

    @Output() updateOngoingFunction = new EventEmitter();

    private supportLocalStorage: boolean;
    private timeoutId;
    private key;

    state: UpdateState;

    ngOnInit() {
        this.supportLocalStorage = !!window.localStorage;
        let storedTimeout = false;
        let storedStartTime = null;
        if (this.supportLocalStorage) {
            this.key = this.thingId + '_' + this.firmware.id;
            storedStartTime = window.localStorage.getItem(this.key);
            if (storedStartTime) {
                // setting the updating state if the timeout is not over
                const timeLeft = (this.timeout * 1000) - (new Date().getTime() - Number(storedStartTime));
                if (timeLeft > 0) {
                    storedTimeout = true; // setting storedTimeout to true to prevent clearing timeout on first version initializing
                    this.setUpdatingStatus(timeLeft);
                    setTimeout(() => storedTimeout = false, timeLeft);
                } else {
                    this.state = UpdateState.DEFAULT;
                    window.localStorage.removeItem(this.key);
                }
            } else {
                this.state = UpdateState.DEFAULT;
            }
        } else {
            this.state = UpdateState.DEFAULT;
        }
        this.version$.subscribe(val => {
            if ((this.timeoutId && !storedTimeout) || (storedTimeout && val.timestamp > Number(storedStartTime))) {
                clearTimeout(this.timeoutId);
                this.completeUpdate((val.value == this.firmware.version) ? UpdateState.UPDATING_SUCCESS : UpdateState.UPDATING_FAILURE);
            }
        });
        this.errorNotifier$.subscribe(err => {
            if (err) {
                clearTimeout(this.timeoutId);
                this.completeUpdate(UpdateState.UPDATING_FAILURE);
            }
        }
        )
    }

    updateFirmware(): void {
        if (this.supportLocalStorage) {
            window.localStorage.setItem(this.key, new Date().getTime() + "");
        }
        this.setUpdatingStatus(this.timeout * 1000);
        this.update.emit();
    }

    private setUpdatingStatus(timeout: number): void {
        this.state = UpdateState.UPDATING;
        this.timeoutId = setTimeout(() => this.completeUpdate(UpdateState.UPDATING_FAILURE), timeout);
        this.updateOngoingFunction.emit(true);
    }

    private completeUpdate(state: UpdateState) {
        this.state = state;
        this.timeoutId = null;
        setTimeout(() => {
            this.state = UpdateState.DEFAULT;
            this.updateOngoingFunction.emit(false);
        }, 2000);
        if (this.supportLocalStorage) {
            window.localStorage.removeItem(this.key);
        }
    }

    ngOnDestroy() {
        this.version$.unsubscribe();
        this.errorNotifier$.unsubscribe();
        clearTimeout(this.timeoutId);
    }
}