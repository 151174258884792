import { forwardRef, Inject, Injectable, QueryList } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Properties } from '../../common/properties';
import { Customer, CustomPropertyDefinition, DetailsWidgetData, StatisticItem, Value } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { DataService } from '../../service/data.service';
import { FieldService } from '../../service/field.service';
import { SocketService } from '../../service/socket.service';
import { StatisticService } from '../../service/statistic.service';
import { CompositePartComponent, CompositePartMode, StatisticComponent } from '../../shared/component/index';
import { MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { PropertyComponent } from '../../shared/component/property/property.component';
import { DefaultCompositePartPipe, DefaultContactsListPipe, StatisticPipe } from "../../shared/pipe/index";
import { DetailsWidgetService } from '../shared/details-widget.service';

@Injectable()
export class CustomerDetailsService extends DetailsWidgetService<Customer> {

    private socketSubscriptionIds: number[];
    private statisticSubscriptions: { fieldsName: string[], subscriberId: string }[] = [];

    static nextId = 0;

    constructor(
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => DataService)) protected dataService: DataService,
        @Inject(forwardRef(() => CustomPropertyService)) protected customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService
    ) {
        super(dataService, customPropertyService, authenticationService);
    }

    destroy(): void {
        if (this.socketSubscriptionIds) {
            this.socketSubscriptionIds.forEach(id => {
                this.socketService.delete(id);
            });
            this.socketSubscriptionIds = null;
        }
        if (this.statisticSubscriptions) {
            this.statisticSubscriptions.forEach(sub => {
                this.fieldService.unsubscribeFromFields(sub.fieldsName);
            });
        }
        if (this.fieldServiceSubscriptions && this.fieldServiceSubscriptions.length) {
            this.fieldServiceSubscriptions.forEach(sub => sub.unsubscribe());
        }
    }

    init(components: QueryList<any>, customer: Customer): DetailsWidgetData[] {
        if (components && components.length) {
            this.socketSubscriptionIds = [];
            this.element = customer;
            return components.map(component => this.processComponent(component));
        }
        return [];
    }

    private processComponent(component: MetricDetailComponent | PropertyComponent | CompositePartComponent | StatisticItem): DetailsWidgetData {
        const subject: BehaviorSubject<string> = new BehaviorSubject('');
        const statisticSubject: BehaviorSubject<{ statisticItems: StatisticItem[], filter: string | Function }> = new BehaviorSubject(null);
        if (component instanceof PropertyComponent) {
            const property = component as PropertyComponent;
            let isFile = false;
            let customPropertyType;
            let objId;
            let propertyDef: CustomPropertyDefinition;
            if (property.name.startsWith('properties')) {
                const customPropName = property.name.substr(11);
                let defaultValue = '';
                let propertyPath = 'properties.';
                customPropertyType = CustomPropertyType.Customer;
                objId = this.element.id;
                propertyDef = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(customPropertyType, component.name.substring(propertyPath.length));
                defaultValue = propertyDef ? propertyDef.value : '';
                isFile = propertyDef ? propertyDef.type == 'FILE' : false;
                let value = _.get(this.element, property.name, defaultValue);
                value = this.getDictionaryValue(propertyDef, value);
                subject.next(value);
                return {
                    name: this.getCustomerPropertyLabel(customPropName, property),
                    value: subject.asObservable(),
                    filter: this.getFilterProperty(property),
                    unit: null,
                    showLabel: property.showLabel,
                    downloadable: isFile,
                    metricNameOrPropertyId: propertyDef ? propertyDef.id : null,
                    customPropertyType: customPropertyType,
                    objId: objId,
                    description: property.description || propertyDef?.description,
                    filterArg: propertyDef ? { property: propertyDef, templateElement: property.getTemplateInputMap() } : null
                };
            } else {
                const propertyInfo = Properties.Customer[property.name];
                subject.next(_.get(this.element, propertyInfo.path, ''));
                return {
                    name: Promise.resolve(property.label || propertyInfo.label),
                    value: subject.asObservable(),
                    filter: property.filter || propertyInfo.defaultFilter,
                    unit: null,
                    showLabel: property.showLabel,
                    downloadable: isFile,
                    metricNameOrPropertyId: propertyDef ? propertyDef.id : null,
                    customPropertyType: customPropertyType,
                    objId: objId,
                    description: property.description || propertyDef?.description,
                    filterArg: propertyDef ? { property: propertyDef, templateElement: property.getTemplateInputMap() } : null
                };
            }
        } else if (component instanceof CompositePartComponent) {
            const compositePart = component;
            this.element.constructor = Customer;
            return {
                name: Promise.resolve(compositePart.label || compositePart.name),
                value: compositePart.get(this.element, CompositePartMode.DETAIL).pipe(map(val => {
                    if (val) {
                        const v = val as Value;
                        return v.value;
                    }
                })),
                filter: compositePart.filter || DefaultCompositePartPipe,
                unit: null,
                showLabel: compositePart.showLabel,
                downloadable: false,
                metricNameOrPropertyId: null,
                customPropertyType: null,
                objId: null,
                description: compositePart.description
            };
        } else if (component instanceof StatisticComponent) {
            if (component.groupBy && component.groupBy.length > 2) {
                component.groupBy = component.groupBy.slice(0, 2);
            }
            let subscriberId = 'customer_details_' + CustomerDetailsService.nextId++;
            if (component.periodRef) {
                component.startDateFieldRef = null;
                component.endDateFieldRef = null;
            }
            this.fieldServiceSubscriptions.push(this.fieldService.subscribeToFields([component.startDateFieldRef, component.endDateFieldRef, component.periodRef]).subscribe(fieldsMap => {
                this.statisticService.getStatisticValue(component, fieldsMap).then(value => statisticSubject.next({ statisticItems: this.handleStatisticValue(value, component), filter: component.filter }), err => console.error(err))
            }));
            this.statisticSubscriptions.push({ fieldsName: [component.startDateFieldRef, component.endDateFieldRef, component.periodRef], subscriberId: subscriberId });
            return {
                name: Promise.resolve(component.label || component.name),
                value: statisticSubject.asObservable(),
                filter: StatisticPipe,
                unit: null,
                showLabel: true,
                downloadable: false,
                metricNameOrPropertyId: null,
                customPropertyType: null,
                objId: null,
                description: component.description
            };
        } else {
            throw new Error('Widget definition error: some components are not valid');
        }
    }

    private getCustomerPropertyLabel(customPropName: string, property: PropertyComponent): Promise<string> {
        if (property.label) {
            return Promise.resolve(property.label);
        }
        let cp = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Customer, customPropName);
        if (cp) {
            return Promise.resolve(cp.label || cp.name);
        } else {
            return Promise.resolve(property.name);
        }
    }

    private handleStatisticValue(value: any, component: StatisticComponent): any {
        const hasPeriodGroupBy = component.groupBy ? component.groupBy.some(el => StatisticService.PERIOD_GROUP_BY_LIST.find(period => period == el) != null) : false;
        if (value && value.length > 0) {
            return this.statisticService.sortStatisticItems(value, hasPeriodGroupBy, component);
        }
        return null;
    }

    private getFilterProperty(property: PropertyComponent): string | Function {
        if (property.name.startsWith('properties.')) {
            const definitionCustomer = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Customer, property.name.substr(11));
            if (definitionCustomer && definitionCustomer.type === 'CONTACTS') {
                return DefaultContactsListPipe;
            } else {
                return property.filter;
            }
        } else {
            return property.filter;
        }
    }

}