import { Component, forwardRef, Host, Inject, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Permissions } from '../../common/constants';
import { Firmware, Thing, Value } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { ThingFirmwareWidgetService } from './thing-firmware.service';


export interface FirmwareElementData {
    firmware: Firmware;
    currentVersion$: BehaviorSubject<Value>;
    errorNotifier$: BehaviorSubject<boolean>;
}

@Component({
    selector: 'thing-firmware-widget',
    template: require('./thing-firmware.component.html'),
    providers: [
        ThingFirmwareWidgetService
    ]
})
export class ThingFirmwareWidgetComponent implements OnInit {

    @Input() maxHeight: string;

    @Input() title: string;

    @Input() timeout: number = 300; //seconds

    data: FirmwareElementData[];
    writePermission: boolean;
    thing: Thing;
    updateOngoing: boolean;
    thingOnline$: BehaviorSubject<Value>;

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => ThingFirmwareWidgetService)) private thingFirmwareWidgetService: ThingFirmwareWidgetService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.thing = this.thingContextService.getCurrentThing();
        this.thingFirmwareWidgetService.init(this.thing).then(resp => this.data = resp.filter(f => f.firmware && (f.firmware.size || f.firmware.url) && f.firmware.currentVersion)); //only shows current version firmware with file
        this.writePermission = this.authenticationService.hasPermission(Permissions.UPDATE_FIRMWARE);
        this.thingOnline$ = this.thingFirmwareWidgetService.subscribeToConnectionStatusValue(this.thing.id);
    }

    ngOnDestroy() {
        this.thingFirmwareWidgetService.destroy();
    }

    updateFirmware(data: FirmwareElementData) {
        this.thingFirmwareWidgetService.updateFirmware(this.thing, data.firmware).catch(() => { data.errorNotifier$.next(true) });
    }

    updateOngoingFunction(isUpdating: boolean) {
        this.updateOngoing = isUpdating;
    }
}