import { AfterContentInit, Component, ContentChildren, forwardRef, Inject, Input, OnInit, QueryList } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Permissions } from '../../common/constants';
import { PropertyInfo } from '../../common/properties';
import { CustomMatPaginatorIntl } from '../../dashboard-area/shared/custom-mat-paginator-intl.service';
import { Partner } from '../../model';
import { AbstractExportContextService } from '../../service/abstract-export-context.service';
import { AddButtonContextService, AddButtonResource } from '../../service/add-button-context.service';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { FieldService } from '../../service/field.service';
import { NavigationService } from '../../service/navigation.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { ErrorUtility } from '../../utility/error-utility';
import { ListWidgetV2Component } from '../list-widget-v2/list-widget-v2.components';
import { PartnerListWidgetV2Service } from './partner-list-widget-v2.service';

@Component({
    selector: 'partner-list-widget-v2',
    template: require('./partner-list-widget-v2.component.html'),
    styles: [require('../list-widget-v2/list-widget-v2.css')],
    providers: [PartnerListWidgetV2Service, { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }]
})
export class PartnerListWidgetV2Component extends ListWidgetV2Component<Partner> implements OnInit, AfterContentInit {

    @Input() searchFields: string[] = ["name", "code"];

    @Input() enableActions = true;

    @Input() detailsPath: string;

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<any>;

    private defaultProperties: { [name: string]: PropertyInfo } = {
        name: { label: 'partnerNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        code: { label: 'codeProperty', path: 'code', defaultFilter: null, defaultSorting: null }
    };
    private includeParentsOnly;

    constructor(
        @Inject(forwardRef(() => PartnerListWidgetV2Service)) private partnerListV2Service: PartnerListWidgetV2Service,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AddButtonContextService)) addButtonService: AddButtonContextService
    ) {
        super(appService, authenticationService, exportService, addButtonService);
    }

    ngOnInit() {
        this.checkIsMobile();
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_PARTNER);
        const currentPartner = this.contextService.getCurrentPartner();
        this.showAddButton = this.writePermission && this.enableActions && ((this.authenticationService.isOrganizationUser() && !currentPartner) || (this.authenticationService.isPartnerUser() && !this.authenticationService.getUser().partner.parentPartnerId));
        if (this.exportEnabled) {
            this.subscribeToExportServices();
        }
        this.includeParentsOnly = this.authenticationService.isOrganizationUser() && !this.contextService.getCurrentPartner();
        if (this.showAddButton) {
            this.subscribeToAddButtonVisibility(AddButtonResource.PARTNER);
        }
    }

    ngAfterContentInit(): void {
        this.displayedColumns = this.partnerListV2Service.getVisibleColumns(this.columnComponents.toArray(), this.defaultProperties, 'Partner');
        this.descriptions = this.partnerListV2Service.getColumnDescriptions(this.columnComponents.toArray());
        if (!this.sort) {
            this.sort = this.partnerListV2Service.setDefaultSort(this.displayedColumns);
        }
        if (this.queryFieldRef) {
            this.fieldServiceSubscription = this.fieldService.subscribeToFields([this.queryFieldRef]).subscribe(fieldsMap => this.manageFilterValue(fieldsMap));
        }
        this.getPartnerList();
    }

    goToDetail(partner: Partner): void {
        let link;
        if (this.detailsPath) {
            link = ['/dashboard/partner_details', partner?.id, this.detailsPath];
        } else {
            link = ['/dashboard/partner_details', partner?.id];
        }
        this.navigationService.navigateTo(link);
    }

    addPartner(): void {
        this.navigationService.navigateTo(['/dashboard/partner_details/add']);
    }

    private getPartnerList(): void {
        this.partnerListV2Service.getPagedList(this.pageIndex, this.pageSize, this.sort, null, this.searchFields, this.advancedSearchBody, null, null, null, null, this.includeParentsOnly).then(pagedList => {
            this.updateElementList(pagedList);
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.getPartnerList();
    }

    export(): void {
        const params = this.partnerListV2Service.getExportParams(this.searchFields, this.advancedSearchBody, this.includeParentsOnly);
        this.partnerListV2Service.downloadCSV(params, this.exportService.resolveExportFileNamePlaceholders(this.exportFileName));
    }

    ngOnDestroy(): void {
        if (this.queryFieldRef) {
            this.fieldService.unsubscribeFromFields([this.queryFieldRef]);
        }
        this.unsubscribeFormExport();
        if (this.fieldServiceSubscription) {
            this.fieldServiceSubscription.unsubscribe();
        }
        this.unsubscribeFromAddButtonVisibility();
    }

    private manageFilterValue(fieldsMap: { [fields: string]: any }): void {
        let advancedSearchBody = fieldsMap[this.queryFieldRef];
        if (advancedSearchBody) {
            this.executeAdvancedSearch(advancedSearchBody);
        }
    }

    protected subscribeToExportServices(): void {
        this.exportId = "partner-list-" + this.getNextId()
        this.exportService.subscribeToExport(this.exportId, this.title || "Partners").subscribe(() => this.export());
        this.subscribeToExportVisibility();
    }
}