import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { FIRMWARE_UPDATE_COMMAND, SOCKET_TOPIC_DATA_VALUES, THING_DEFINITION_FIRMWARES_V2 } from '../../common/endpoints';
import { API_URL } from '../../common/setup';
import { Firmware, Metric, PagedList, Thing, Value } from '../../model';
import { DataService } from '../../service/data.service';
import { HttpService } from '../../service/http.service';
import { SocketService } from '../../service/socket.service';
import { FirmwareElementData } from './thing-firmware.component';

@Injectable()
export class ThingFirmwareWidgetService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => DataService)) private dataService: DataService
    ) { }

    private socketSubscriptions: number[] = [];

    init(thing: Thing): Promise<FirmwareElementData[]> {
        return this.getRecursivelyAllPages(thing.thingDefinitionId).then(firmwares => {
            return firmwares.map(f => {
                return { firmware: f, currentVersion$: this.subscribeToVersionMetric(f.versionMetric, thing.id), errorNotifier$: new BehaviorSubject<boolean>(false) }
            });
        });
    }

    private getRecursivelyAllPages(thingDefinitionId: string, page: number = 0, docs: Firmware[] = []): Promise<Firmware[]> {
        return this.getPagedDocsByThingDefinitionId(thingDefinitionId, true, null, page, 100, ['name', 'asc'])
            .then(pagedMetrics => {
                docs = docs.concat(pagedMetrics.content);
                if (pagedMetrics.last) {
                    return docs;
                } else {
                    return this.getRecursivelyAllPages(thingDefinitionId, ++page, docs);
                }
            });
    }

    private getPagedDocsByThingDefinitionId(thingDefinitionId: string, includeInherited: boolean, searchText: string, page: number, size: number, sort: string[]): Promise<PagedList<Firmware>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        if (includeInherited) {
            params = params.set('includeInherited', includeInherited + "");
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        return firstValueFrom(this.httpService.get<PagedList<Firmware>>(THING_DEFINITION_FIRMWARES_V2.replace('{id}', thingDefinitionId), params));
    }

    private subscribeToVersionMetric(metric: Metric, thingId: string): BehaviorSubject<Value> {
        const value: Value = { timestamp: 0, value: 'N/A', unspecifiedChange: false }
        let subject: BehaviorSubject<Value> = new BehaviorSubject(value);
        if (metric) {
            this.dataService.getLastValueByThingIdAndMetricName(thingId, metric.name).then(val => {
                if (val) {
                    this.zone.run(() => { subject.next(val); });
                    if (val.privateData) {
                        return false;
                    }
                }
                return true;
            }).then(shouldSubscribe => {
                if (shouldSubscribe) {
                    let subscriber = {
                        topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', thingId).replace('{metricName}', metric.name),
                        callback: message => this.handleWsResponse(JSON.parse(message.body), subject)
                    };
                    this.socketSubscriptions.push(this.socketService.subscribe(subscriber));
                }
            });
        }
        return subject;
    }

    subscribeToConnectionStatusValue(thingId: string): BehaviorSubject<Value> {
        let subject: BehaviorSubject<Value> = new BehaviorSubject(null);
        let metricName = "Connection Status";
        this.dataService.getLastValueByThingIdAndMetricName(thingId, metricName).then(val => {
            if (val) {
                this.zone.run(() => { subject.next(val); });
                if (val.privateData) {
                    return false;
                }
            }
            return true;
        }).then(shouldSubscribe => {
            if (shouldSubscribe) {
                let subscriber = {
                    topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', thingId).replace('{metricName}', metricName),
                    callback: message => this.handleWsResponse(JSON.parse(message.body), subject)
                };
                this.socketSubscriptions.push(this.socketService.subscribe(subscriber));
            }
        });
        return subject;
    }

    private handleWsResponse(newData: any, subject: BehaviorSubject<Value>) {
        this.zone.run(() => {
            const value: Value = { timestamp: new Date().getTime(), value: DataService.extractValue(newData.values), unspecifiedChange: false }
            subject.next(value);
        });
    }

    destroy(): void {
        this.socketSubscriptions.forEach(s => this.socketService.delete(s));
    }

    updateFirmware(thing: Thing, firmware: Firmware): Promise<void> {
        const params = new HttpParams().set('thingId', thing.id).set('firmwareId', firmware.id).set('baseUrl', API_URL);
        return this.httpService.put<void>(FIRMWARE_UPDATE_COMMAND, null, params).toPromise();
    }

}