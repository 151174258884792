import { Location as NgLocation } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterContentInit, Compiler, Component, ContentChildren, forwardRef, Inject, Injectable, Input, NgModule, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatTab } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { TEMPLATE_CONTENT } from '../../common/endpoints';
import { Alert, Customer, Location, Partner, ServiceLevel, Tenant, Thing, ThingDefinition, ThingTestSession, UiProfilePage, UiTab, UiTabNavigationType, User, WorkSession } from '../../model/index';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { ControlBarService } from '../../service/control-bar.service';
import { HttpService } from '../../service/http.service';
import { UiService } from '../../service/ui.service';
import { RegisteredWidget, WidgetRegistrationService } from '../../service/widget-registration.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { MetricDetailComponent, PropertyComponent, StatisticComponent } from '../../shared/component';
import { getContextServiceProvider } from '../../shared/provider/context-service.provider';
import { getExportContextServiceProvider } from '../../shared/provider/export-context-service.provider';
import { getThingContextServiceProvider } from '../../shared/provider/thing-context-service.provider';
import { SharedModule } from '../../shared/shared.module';
import { ConfigurationParameterEntryComponent } from '../../widget/configuration-parameters/configuration-parameter-entry.component';
import { ConfigurationParametersModule } from '../../widget/configuration-parameters/configuration-parameters.module';
import { SchedulerModule } from '../../widget/scheduler/scheduler.module';
import { ThingOptionModule } from '../../widget/thing-option/thing-option.module';
import { TimeseriesWidgetModule } from '../../widget/timeseries/timeseries-widget.module';
import { UserThingAuthorizationModule } from '../../widget/user-thing-authorization/user-thing-authorization.module';
import { WidgetModule } from '../../widget/widget.module';
import { DynamicListModule } from '../dynamic-list/dynamic-list.module';

@Injectable()
export class TemplateLoaderService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => Compiler)) private compiler: Compiler,
        @Inject(forwardRef(() => UiService)) private uiService: UiService,
        @Inject(forwardRef(() => WidgetRegistrationService)) private widgetRegistrationService: WidgetRegistrationService,
        @Inject(forwardRef(() => NgLocation)) private browserLocation: NgLocation,
        @Inject(forwardRef(() => BreadcrumbService)) private breadcrumbService: BreadcrumbService,
        @Inject(forwardRef(() => ControlBarService)) private controlBarService: ControlBarService
    ) { }

    static TEMPLATE_ERROR = `
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="alert alert-warning">
                    <h4><i class="icon fa fa-exclamation"></i>Template not found!</h4>
                    Please check your configuration.
                </div>
            </div>
        </div> 
    `;

    loadFromEndpoint(endpoint: string, vcRef: ViewContainerRef, clearView: boolean, params?: HttpParams,
        templateWrapper?: { preTemplate: string, postTemplate: string, placeholders: { [key: string]: string } }, thingForContext?: Thing, locationForContext?: Location, emptyContext: boolean = false): Promise<any> {
        return firstValueFrom(this.httpService.getText(endpoint, params))
            .then(template => this.loadFromTextWithWrapper(template, templateWrapper, vcRef, clearView, thingForContext, locationForContext, emptyContext));
    }

    loadFromTextWithWrapper(template: string, templateWrapper: { preTemplate: string; postTemplate: string; placeholders: { [key: string]: string; }; }, vcRef: ViewContainerRef, clearView: boolean, thingForContext?: Thing, locationForContext?: Location, emptyContext: boolean = false) {
        if (templateWrapper) {
            for (let key in templateWrapper.placeholders) {
                template = template.replace(key, templateWrapper.placeholders[key]);
            }
            template = templateWrapper.preTemplate + template + templateWrapper.postTemplate;
        }
        this.loadFromText(template, vcRef, clearView, thingForContext, locationForContext, emptyContext);
        return template;
    }

    loadFromText(template: string, vcRef: ViewContainerRef, clearView: boolean, thingForContext?: Thing, locationForContext?: Location, emptyContext: boolean = false): void {
        if (clearView) {
            vcRef.clear()
        }
        const dynamicComponent = this.createComponent(template, thingForContext, locationForContext, emptyContext);
        const registeredComponents = this.widgetRegistrationService.getRegisteredWidgets().map(w => this.registerComponent(w, thingForContext, locationForContext, emptyContext));
        const dynamicModule = this.createModule(dynamicComponent, registeredComponents);

        this.compiler.compileModuleAndAllComponentsAsync(dynamicModule).then(
            compiledModule => {
                const factory = compiledModule.componentFactories.find((comp) => comp.componentType === dynamicComponent);
                vcRef.createComponent(factory);
            }
        );
    }

    loadThingDetailsDashboard(subPath: string, thing: Thing, vcRef: ViewContainerRef): void {
        this.uiService.getUserPagedDashboards(thing.id, 0, 1).then(dashboards => {
            let template = '';
            if (dashboards.content.length > 0) {
                let dashboard = dashboards.content[0];
                this.loadTabbedPageContent(dashboard.tabs, subPath, vcRef, thing.thingDefinitionId, dashboard.controlBarTemplateName);
            } else {
                this.loadFromText(template, vcRef, true);
            }
        }).catch(() => this.loadFromText(TemplateLoaderService.TEMPLATE_ERROR, vcRef, true));
    }

    loadContentFromUiPage(vcRef: ViewContainerRef, page: UiProfilePage, subPath: string, defaultControlBarTemplateName: string): void {
        if (page) {
            this.loadTabbedPageContent(page.tabs, subPath, vcRef, null, (page.controlBarTemplateName ?? defaultControlBarTemplateName));
        } else {
            this.loadFromText(TemplateLoaderService.TEMPLATE_ERROR, vcRef, true);
        }
    }

    loadTabbedPageContent(tabs: UiTab[], subPath: string, vcRef: ViewContainerRef, thingDefinitionId?: string, defaultControlBarTemplateName?: string): void {
        if (tabs && tabs.length) {
            tabs = this.filterTabsBySubPathType(subPath, tabs).filter(tab => this.filterVisibleTab(tab));
            if (!tabs.length) {
                this.loadFromText('', vcRef, true);
            } else if (tabs.length == 1) { // tab is hidden
                let tab = tabs[0];
                if (tab.navigation == UiTabNavigationType.LINK) {
                    this.breadcrumbService.addSubPath(tab.title);
                }
                const controlBarTemplateRequest = (tab.controlBarTemplateName || defaultControlBarTemplateName) ? this.uiService.getContentByTemplateName(tab.controlBarTemplateName || defaultControlBarTemplateName, thingDefinitionId) : Promise.resolve(null)
                Promise.all([this.uiService.getContentByTemplateName(tab.templateName, thingDefinitionId), controlBarTemplateRequest]).then(results => {
                    this.loadFromText(results[0], vcRef, true);
                    this.updateControlBarContent(results[1]);
                });
            } else { // show mat-tab-group
                Promise.all(tabs.map(t => this.uiService.getContentByTemplateName(t.templateName, thingDefinitionId))).then(templateContents => {
                    Promise.all(tabs.map(t => (t.controlBarTemplateName || defaultControlBarTemplateName) ? this.uiService.getContentByTemplateName(t.controlBarTemplateName || defaultControlBarTemplateName, thingDefinitionId) : Promise.resolve(null))).then(controlBarContents => {
                        this.controlBarService.setControlsBarTabContents(controlBarContents);
                        let template = this.buildTabbedTemplate(tabs, subPath, templateContents);
                        this.loadFromText(template, vcRef, true);
                        let selectedIndex = tabs.findIndex(t => t.urlPath == subPath);
                        let controlBarContent = this.controlBarService.getControlsBarTabContent(selectedIndex > -1 ? selectedIndex : 0);
                        this.updateControlBarContent(controlBarContent);
                    });
                });
            }
        } else {
            this.loadFromText('', vcRef, true);
        }
    }

    private filterTabsBySubPathType(subPath: string, tabs: UiTab[]): UiTab[] {
        if (subPath) {
            let subTab = tabs.find(t => t.urlPath == subPath);
            if (subTab) {
                if (subTab.navigation == UiTabNavigationType.LINK) {
                    return [subTab];
                } else { // UiTabNavigationType.TAB_MENU
                    return tabs.filter(t => t.navigation == UiTabNavigationType.TAB_MENU);
                }
            } else {
                return [];
            }
        } else {
            return tabs.filter(t => t.navigation == UiTabNavigationType.TAB_MENU);
        }
    }

    private filterVisibleTab(tab: UiTab): boolean {
        if (tab.visibilityCondition) {
            try {
                let visibilityCondition = this.uiService.normalizeVisibilityCondition(tab.visibilityCondition)
                return eval(visibilityCondition);
            } catch {
                return false;
            }
        }
        return true;
    }

    private buildTabbedTemplate(tabs: UiTab[], subPath: string, templateContents: string[]) {
        let selectedIndex = tabs.findIndex(t => t.urlPath == subPath);
        if (selectedIndex == -1) {
            if (subPath) {
                let path = this.browserLocation.path();
                this.browserLocation.replaceState(path.substring(0, path.lastIndexOf('/')));
            }
            subPath = '';
        }
        let template = `<tabbed-page selectedTab="${subPath}">`;
        let tabItems = tabs.map((tab, i) => {
            return `<tab-item title="${tab.title}" urlPath="${tab.urlPath}"></tab-item>
                    <ng-template tab-item-content>
                        ${templateContents[i]}
                    </ng-template>`;
        });
        template += tabItems.join('\n');
        template += `</tabbed-page>`;
        return template;
    }

    loadUserTemplate(vcRef: ViewContainerRef, name: string, thingDefinitionId?: string, emptyContext: boolean = false): Promise<void> {
        return this.uiService.getUserPagedTemplates(name, 0, 1, thingDefinitionId).then(templates => {
            if (templates.content.length > 0) {
                let template = templates.content[0];
                return this.loadFromEndpoint(TEMPLATE_CONTENT.replace('{id}', template.id), vcRef, true, null, null, null, null, emptyContext);
            } else {
                return this.loadFromText('', vcRef, true);
            }
        }).catch(() => this.loadFromText(TemplateLoaderService.TEMPLATE_ERROR, vcRef, true));
    }

    private createComponent(template: string, thing?: Thing, location?: Location, emptyContext: boolean = false) {
        let thingContextServiceProvider = getThingContextServiceProvider(thing, emptyContext);
        let contextServiceProvider = getContextServiceProvider(location, emptyContext);
        let exportContextServiceProvider = getExportContextServiceProvider(emptyContext);
        @Component({
            template: template,
            viewProviders: [thingContextServiceProvider, contextServiceProvider, exportContextServiceProvider]
        })
        class DynamicComponent {

            private currentTimestamp;

            reportMode: boolean;

            @ViewChildren(MatTab) tabs: QueryList<MatTab>

            constructor(
                @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
                @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
                @Inject(forwardRef(() => AppService)) private appService: AppService,
                @Inject(forwardRef(() => ActivatedRoute)) private route: ActivatedRoute,
                @Inject(forwardRef(() => Router)) private router: Router,
                @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService
            ) {
                this.route.queryParams.subscribe(params => {
                    this.reportMode = (params['mode'] == 'report') || !!(this.router.url.match(/\/dashboard\/thing_details\/[0-9A-Za-z]+\/reports/g));
                });
                this.currentTimestamp = new Date().getTime();
            }

            getThing(): Thing {
                return this.thingContextService.getCurrentThing();
            }

            getThingDefinition(): ThingDefinition {
                return this.thingContextService.getCurrentThingDefinition();
            }

            getUser(): User {
                return this.authenticationService.getUser();
            }

            getTenant(): Tenant {
                return this.authenticationService.getTenant();
            }

            getCustomer(): Customer {
                let user = this.getUser();
                return user && user.customer ? user.customer : this.contextService.getCurrentCustomer();
            }

            getLocation(): Location {
                let user = this.getUser();
                return user && user.location ? user.location : this.contextService.getCurrentLocation();
            }

            getPartner(): Partner {
                let user = this.getUser();
                let currentPartner = this.contextService.getCurrentPartner();
                return currentPartner || user.partner;
            }

            getServiceLevel(): ServiceLevel {
                let thing = this.thingContextService.getCurrentThing();
                if (thing) {
                    return thing.serviceLevel;
                }
                return null;
            }

            getTestSession(): ThingTestSession {
                return this.thingContextService.getCurrentThingTestSession();
            }

            getTestSessionStartDate(): number {
                let thingTestSession = this.getTestSession();
                return thingTestSession ? thingTestSession.startedAt : null;
            }

            getTestSessionStopDate(): number {
                let thingTestSession = this.getTestSession();
                return thingTestSession ? thingTestSession.stoppedAt : null;
            }

            isMobile(): boolean {
                return this.appService.isMobile();
            }

            isTablet(): boolean {
                return this.appService.isTablet();
            }

            getUserCustomer(): Customer {
                return this.authenticationService.getUserCustomer();
            }

            getCustomerServiceLevel(): ServiceLevel {
                let customer = this.getCustomer();
                return customer ? customer.serviceLevel : null;
            }

            getWorkSession(): WorkSession {
                return this.thingContextService.getCurrentWorkSession();
            }

            getTags(): string[] {
                return this.contextService.getTags();
            }

            getCustomerTags(): string[] {
                return this.contextService.getCustomerTags();
            }

            getLocationTags(): string[] {
                return this.contextService.getLocationTags();
            }

            getPartnerTags(): string[] {
                return this.contextService.getPartnerTags();
            }

            getCurrentTimestamp(): number {
                return this.currentTimestamp;
            }

            getAlert(): Alert {
                return this.thingContextService.getCurrentAlert();
            }
        }
        return DynamicComponent;
    }

    registerComponent(widget: RegisteredWidget, thing?: Thing, location?: Location, emptyContext: boolean = false) {
        let thingContextServiceProvider = getThingContextServiceProvider(thing, emptyContext);
        let contextServiceProvider = getContextServiceProvider(location, emptyContext);
        let exportContextServiceProvider = getExportContextServiceProvider(emptyContext);
        @Component({
            selector: widget.tagName,
            template: `
                <widget *ngIf="visible" name="${widget.className}" [title]="title" [config]="config" [inputs]="inputs" [metricSubscriptionDisabled]="metricSubscriptionDisabled" [lazyMetricDataLoading]="lazyMetricDataLoading">
                    <metric *ngFor="let m of metricComponents" [id]="m.id" [name]="m.name" [label]="m.label" [filter]="m.filter" [x]="m.x" [y]="m.y" [chartOptions]="m.chartOptions" [icon]="m.icon" [unit]="m.unit" [aggregation]="m.aggregation" [config]="m.config"></metric>
                    <property *ngFor="let p of propertyComponents" [id]="p.id" [name]="p.name" [label]="p.label" [filter]="p.filter" [x]="p.x" [y]="p.y" [config]="p.config"></property>
                    <configuration-parameter *ngFor="let p of configParamsComponents" [name]="p.name" [label]="p.label" [config]="p.config"></configuration-parameter>
                    <statistic *ngFor="let s of statisticComponents" [name]="s.name" [label]="s.label" [limit]="s.limit" [thingDefinition]="s.thingDefinition" [sumMetric]="s.sumMetric" [groupBy]="s.groupBy" [query]="s.query" [aggregation]="s.aggregation" [property]="s.property" [filter]="s.filter" [description]="s.description" [config]="s.config" [resource]="s.resource" [activationType]="s.activationType" [sortDirection]="s.sortDirection" [averagedBy]="s.averagedBy"></statistic>
                </widget>`,
            viewProviders: [thingContextServiceProvider, contextServiceProvider, exportContextServiceProvider]
        })
        class RegisteredComponent implements AfterContentInit {

            @Input() title: string;

            @Input() config: object;

            @Input() inputs: { [id: string]: string };

            @ContentChildren(MetricDetailComponent) metrics: QueryList<MetricDetailComponent>;

            @ContentChildren(PropertyComponent) properties: QueryList<PropertyComponent>;

            @ContentChildren(ConfigurationParameterEntryComponent) configParams: QueryList<ConfigurationParameterEntryComponent>;

            @ContentChildren(StatisticComponent) statistics: QueryList<StatisticComponent>;

            metricComponents: MetricDetailComponent[] = [];
            propertyComponents: PropertyComponent[] = [];
            configParamsComponents: ConfigurationParameterEntryComponent[] = [];
            statisticComponents: StatisticComponent[] = [];
            visible: boolean;

            ngAfterContentInit(): void {
                if (this.metrics?.length) {
                    this.metricComponents = this.metrics.toArray();
                }
                if (this.properties?.length) {
                    this.propertyComponents = this.properties.toArray();
                }
                if (this.configParams?.length) {
                    this.configParamsComponents = this.configParams.toArray();
                }
                if (this.statistics?.length) {
                    this.statisticComponents = this.statistics.toArray();
                }
                this.visible = true;
            }

        }
        return RegisteredComponent;
    }


    private createModule(component: any, registeredComponents: any[]) {
        @NgModule({
            imports: [
                SharedModule,
                WidgetModule,
                SchedulerModule,
                TimeseriesWidgetModule,
                ConfigurationParametersModule,
                ThingOptionModule,
                DynamicListModule,
                UserThingAuthorizationModule
            ],
            declarations: [component, ...registeredComponents]
        })
        class DynamicModule { }
        return DynamicModule;
    }

    updateControlBarContent(controlBarContent: string): void {
        this.controlBarService.updateContentSubject(controlBarContent)
    }

    loadControlBarContent(content: string): void {
        const controlBarVcRef = this.controlBarService.getVcRef();
        if (controlBarVcRef) {
            if (content) {
                this.loadFromText(content, controlBarVcRef, true);
            } else {
                this.loadFromText("", controlBarVcRef, true);
            }
        }
    }

}
