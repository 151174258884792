import { AfterContentInit, Component, ContentChildren, EventEmitter, forwardRef, Inject, Input, OnDestroy, OnInit, Output, QueryList } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Permissions } from '../../common/constants';
import { PropertyInfo } from '../../common/properties';
import { CustomMatPaginatorIntl } from '../../dashboard-area/shared/custom-mat-paginator-intl.service';
import { Customer, Location } from '../../model';
import { AbstractExportContextService } from '../../service/abstract-export-context.service';
import { AddButtonContextService, AddButtonResource } from '../../service/add-button-context.service';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { NavigationService } from '../../service/navigation.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { SearchTargetType } from '../../shared/component/search-field/search-field.component';
import { CustomTableService } from '../../shared/custom-table';
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { ErrorUtility } from '../../utility/error-utility';
import { AdvancedSearchLayoutType, ListWidgetV2Component } from '../list-widget-v2/list-widget-v2.components';
import { LocationListWidgetV2Service } from './location-list-widget-v2.service';

@Component({
    selector: 'location-list-widget-v2',
    template: require('./location-list-widget-v2.component.html'),
    styles: [require('../list-widget-v2/list-widget-v2.css')],
    providers: [LocationListWidgetV2Service, { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }]
})
export class LocationListWidgetV2Component extends ListWidgetV2Component<Location> implements OnInit, AfterContentInit, OnDestroy {

    @Input() searchFields: string[] = ["name"];

    @Input() enableActions = true;

    @Input() customer: Customer;

    @Input() selectableList: boolean;

    @Input() detailsPath: string;

    @Input() accordionTemplate: string = null;

    @Output() elementSelectedAction = new EventEmitter();

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<any>;

    enableImport: boolean;

    private metricNames = new Set<string>();

    private defaultProperties: { [name: string]: PropertyInfo } = {
        name: { label: 'locationNameProperty', path: 'name', defaultFilter: null, defaultSorting: null }
    };

    constructor(
        @Inject(forwardRef(() => LocationListWidgetV2Service)) private locationListV2Service: LocationListWidgetV2Service,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => AddButtonContextService)) addButtonService: AddButtonContextService
    ) {
        super(appService, authenticationService, exportService, addButtonService);
    }

    ngOnInit() {
        this.importTitle = 'importLocationsTitle';
        this.csvExample = `name,country,gpsPosition,timezone,properties.<property_name>\nLOC1,United Kingdom,"51.509865,-0.118092",Europe/London,P1\nLOC2,United Kingdom,"51.509865,-0.118092",Europe/London,P1`;
        this.checkIsMobile();
        this.readPermission = this.authenticationService.hasPermission(Permissions.READ_LOCATION);
        if (this.selectableList) {
            this.enableImport = false;
            this.showAddButton = false;
        } else {
            this.customer = this.contextService.getCurrentCustomer();
            this.enableImport = this.authenticationService.hasPermission(Permissions.IMPORT_LOCATIONS);
            this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_LOCATION) && (!!this.customer || this.authenticationService.isCustomerUser());
            this.showAddButton = this.writePermission && this.enableActions;
        }
        this.handleAdvancedSearchLayoutType("location-list-popup-advanced-search", SearchTargetType.LOCATIONS);
        if (this.exportEnabled) {
            this.subscribeToExportServices();
        }
        if (this.showAddButton) {
            this.subscribeToAddButtonVisibility(AddButtonResource.LOCATION);
        }
    }

    ngAfterContentInit(): void {
        this.metricNames = this.locationListV2Service.getMetricNames(this.columnComponents);
        if (this.selectableList) {
            this.displayedColumns = [CustomTableService.newSimpleColumn('name', 'nameProperty', 'name').withSortField('name')];
        } else {
            this.displayedColumns = this.locationListV2Service.getVisibleColumns(this.columnComponents.toArray(), this.defaultProperties, 'Location');
        }
        this.descriptions = this.locationListV2Service.getColumnDescriptions(this.columnComponents.toArray());
        if (!this.sort) {
            this.sort = this.locationListV2Service.setDefaultSort(this.displayedColumns);
        }
        const storedFieldsValues = localStorage.getItem(this.queryFieldRef || 'locationAdvancedSearchFieldsValues');
        const savedFieldsValues = storedFieldsValues ? JSON.parse(storedFieldsValues) : null;
        if (!savedFieldsValues && !this.query && !this.queryFieldRef && this.advancedSearchLayout != AdvancedSearchLayoutType.POPUP) {
            this.getLocationList();
        }
    }

    goToDetail(location: Location): void {
        if (this.selectableList) {
            this.elementSelectedAction.emit(location);
        } else {
            let link;
            if (this.detailsPath) {
                link = ['/dashboard/location_details', location?.id, this.detailsPath];
            } else {
                link = ['/dashboard/location_details', location?.id];
            }
            this.navigationService.navigateTo(link);
        }
    }

    addLocation(): void {
        if (this.authenticationService.isCustomerUser()) {
            this.navigationService.navigateTo(['/dashboard/location_details/add']);
        } else {
            this.navigationService.navigateTo(['/dashboard/customer_details', this.customer.id, 'location_details', 'add']);
        }
    }

    private getLocationList(): void {
        this.locationListV2Service.getPagedList(this.pageIndex, this.pageSize, this.sort, this.metricNames, this.searchFields, this.advancedSearchBody, null, null, null, null, null, this.customer).then(pagedList => {
            this.updateElementList(pagedList);
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.getLocationList();
    }

    export(): void {
        const params = this.locationListV2Service.getExportParams(this.metricNames, this.searchFields, this.advancedSearchBody);
        this.locationListV2Service.downloadCSV(params, this.exportService.resolveExportFileNamePlaceholders(this.exportFileName));
    }

    import(data: FormData): void {
        this.locationListV2Service.import(data).then(() => {
            this.error = null;
            this.getLocationList();
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    protected subscribeToExportServices(): void {
        this.exportId = "location-list-" + this.getNextId();
        this.exportService.subscribeToExport(this.exportId, this.title || "Locations").subscribe(() => this.export());
        this.subscribeToExportVisibility();
    }
}