import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild, forwardRef } from "@angular/core";
import { FormControl, NgForm } from "@angular/forms";
import { DateRange } from "@angular/material/datepicker";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { Moment } from "moment";
import { Subscription, take } from "rxjs";
import { ErrorMessages } from "../../common/constants";
import { DataExportTimestampFormat, Location, Metric, Thing, ThingDefinition } from "../../model";
import { ContextService } from "../../service/context.service";
import { CustomLabelService } from "../../service/custom-label.service";
import { DataExportConfigurationProperties, DataExportConfigurationService } from "../../service/data-export-configuration.service";
import { DateRangeName, DateRangeService } from "../../service/date-range.service";
import { ThingDefinitionService } from "../../service/thing-definition.service";
import { UserLocationService } from "../../service/user-location.service";
import { UserThingService } from "../../service/user-thing.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { PreselectedRangeComponent } from "../../shared/component/daterange-picker/preselected-range.component";
import { ButtonActionValue, CustomTableColumn, CustomTableService } from "../../shared/custom-table";
import { LocalizationPipe } from "../../shared/pipe";
import { ErrorUtility } from "../../utility/error-utility";
import { DataExportWidgetAddThingDefinitionDialog } from "./data-export-widget-add-thing-definition-dialog.component";
import { DataExportWidgetService } from "./data-export-widget.service";

@Component({
    selector: 'data-export-widget-schedule-page',
    template: require('./data-export-widget-schedule-page.component.html'),
    styles: [require('./data-export-widget-schedule-page.component.css')]
})
export class DataExportWidgetSchedulePageComponent extends PreselectedRangeComponent implements OnInit, OnDestroy {

    @Input() configuration: DataExportConfigurationProperties[];

    @Output() cancelAction = new EventEmitter();

    @ViewChild('scheduleForm') scheduleForm: NgForm;

    exportData: { exportThingDef: ThingDefinition, exportMetrics: Metric[], metricNamesLabel: string }[] = [];
    error: string;
    range: DateRange<Moment>;
    maxDaysBack: number;
    locations: Location[] = [];
    things: Thing[] = [];
    contextCustomerId: string;
    locationControl = new FormControl({ value: null, disabled: true });
    thingControl = new FormControl({ value: null, disabled: true });
    displayedColumns: CustomTableColumn[];
    dataSource = new MatTableDataSource<{ exportThingDef: ThingDefinition, exportMetrics: Metric[], metricNamesLabel: string }>([]);
    timestampFormats = [
        { value: DataExportTimestampFormat.ISO_8601, label: 'ISO' },
        { value: DataExportTimestampFormat.EPOCH_MILLIS, label: 'Milliseconds' }
    ];
    contextThingId: string;
    maximumPeriodMessage: string;
    visibleRanges: string[];
    showMaxPeriodChanged: boolean;
    hideDateRangePicker: boolean;

    private contextLocationId: string;
    private locationSub: Subscription;
    private defaultFileName: string = "${thing.serialNumber}_${thingDefinition.name}";
    private thingDefinitions: ThingDefinition[] = [];
    private maximumPeriodDefaultMessage: string = "Maximum period ${maxMonths} months";
    private invalidPeriodConfiguration: boolean;

    constructor(
        @Inject(forwardRef(() => DataExportWidgetService)) private dataExportWidgetService: DataExportWidgetService,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService,
        @Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService,
        @Inject(forwardRef(() => MatSnackBar)) private snackBar: MatSnackBar,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => UserLocationService)) private userLocationService: UserLocationService,
        @Inject(forwardRef(() => UserThingService)) private userThingService: UserThingService,
        @Inject(forwardRef(() => ThingDefinitionService)) private thingDefinitionService: ThingDefinitionService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => DataExportConfigurationService)) private dataExportConfigurationService: DataExportConfigurationService
    ) {
        super(dateRangeService);
    }

    ngOnInit() {
        this.defaultPeriodValue = DateRangeName.THIS_MONTH;
        let value = this.getPeriod();
        this.range = new DateRange(value.range.start, value.range.end);
        this.checkContext();
        this.displayedColumns = this.getDisplayedColumns();
        this.initSubscriptions();
        this.thingDefinitionService.getThingDefinitions().then(thingDefinitions => {
            this.thingDefinitions = thingDefinitions;
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        this.filterPeriods = this.filterPeriods || [DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_24_HOURS, DateRangeName.LAST_7_DAYS, DateRangeName.LAST_30_DAYS, DateRangeName.THIS_MONTH, DateRangeName.LAST_MONTH, DateRangeName.LAST_12_MONTHS, DateRangeName.THIS_YEAR, 'CUSTOM'];
        this.updateDateLimit(true);
    }

    private getDisplayedColumns(): CustomTableColumn[] {
        let displayedColumns: CustomTableColumn[] = [];
        if (!this.contextThingId) {
            displayedColumns.push(CustomTableService.newSimpleColumn('name', 'nameProperty', 'exportThingDef.name'));
        }
        displayedColumns.push(
            CustomTableService.newSimpleColumn('metrics', 'metricsTabItem', 'metricNamesLabel'),
            CustomTableService.newButtonColumn('delete', '', 'id', 'float-right', 'deleteButton').withMatIcon('delete').withMatIconClass('material-symbols-outlined').withStyle({ '_any': { 'font-size': '20px', 'color': '#ff0000', 'width': '10px' } }).withStickyEndColumn()
        );
        return displayedColumns;
    }

    ngOnDestroy(): void {
        if (this.locationSub) {
            this.locationSub.unsubscribe();
        }
    }

    private checkContext(): void {
        if (this.thingContextService.getCurrentThing()) {
            const contextThing = this.thingContextService.getCurrentThing();
            this.contextThingId = contextThing.id;
            this.contextLocationId = contextThing.locationId;
            this.contextCustomerId = contextThing.customerId;
        } else if (this.contextService.getCurrentLocation()) {
            const contextLocation = this.contextService.getCurrentLocation();
            this.contextLocationId = contextLocation.id;
            this.contextCustomerId = ContextService.getCustomerFromLocation(contextLocation)?.id;
        } else if (this.contextService.getCurrentCustomer()) {
            this.contextCustomerId = this.contextService.getCurrentCustomer().id;
        }
    }

    loadLocationList(customerId: string): void {
        this.locations = [];
        this.locationControl.reset();
        if (customerId) {
            this.userLocationService.getRecursivelyAllLocations(null, [], customerId).then(locations => {
                this.locations = locations;
                if (this.contextLocationId) {
                    this.locationControl.setValue(this.contextLocationId);
                    this.locationControl.disable();
                } else {
                    this.locationControl.enable();
                }
            });
        } else {
            this.locationControl.disable();
        }
    }

    private initSubscriptions(): void {
        this.locationSub = this.locationControl.valueChanges.subscribe(locationId => {
            this.things = [];
            this.thingControl.reset();
            if (locationId) {
                this.userThingService.getRecursivelyAllThings(null, [], locationId).then(things => {
                    this.things = things;
                    this.thingControl.enable();
                });
            } else {
                this.thingControl.disable();
            }
        });
    }

    selectPeriod(range: DateRange<Moment>) {
        this.range = range;
        this.updateDateLimit();
    }

    onCancel() {
        this.cancelAction.emit();
    }

    scheduleBulkDataExport(): void {
        const formValues = this.scheduleForm.form.getRawValue();
        let metricsList: string[] = [];
        let thingDefList: string[] = [];
        this.exportData.forEach(el => {
            thingDefList.push(el.exportThingDef.id);
            el.exportMetrics.forEach(m => {
                metricsList.push(m.id);
            });
        });
        let body = {
            name: formValues.name,
            thingFileName: formValues.thingFileName ? formValues.thingFileName : this.defaultFileName,
            hashThingFileName: formValues.hashThingFileName ? formValues.hashThingFileName : null,
            thingDefinitionIds: thingDefList,
            metricIds: metricsList,
            startTimestamp: this.range.start.valueOf(),
            endTimestamp: this.range.end.valueOf(),
            timestampFormat: formValues.timestampFormat
        }
        if (this.contextThingId) {
            body['thingId'] = this.contextThingId;
            body['locationId'] = this.contextLocationId;
            body['customerId'] = this.contextCustomerId;
        } else {
            if (this.thingControl.value) {
                body['thingId'] = this.thingControl.value;
            }
            if (this.locationControl.value) {
                body['locationId'] = this.locationControl.value;
            }
            if (formValues.customer) {
                body['customerId'] = formValues.customer;
            }
        }
        if (this.contextService.getCurrentPartner()) {
            body['partnerId'] = this.contextService.getCurrentPartner().id;
        }
        this.dataExportWidgetService.scheduleBulkDataExport(body).then(() => {
            this.error = null;
            this.showSnackbar("dataExportScheduledProperty");
            this.onCancel();
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
    }

    private showSnackbar(text: string): void {
        this.labelService.getCustomLabel(text)
            .then(message => {
                this.snackBar.open(this.localizationPipe.transform(message), '', {
                    duration: 4000,
                    panelClass: 'notification-info'
                });
            });
    }

    isFormValid(): boolean {
        if (this.invalidPeriodConfiguration) {
            return false;
        }
        if (this.scheduleForm) {
            const formValues = this.scheduleForm.form.getRawValue();
            return this.exportData?.length > 0 && this.range && formValues.name;
        } else {
            return false;
        }
    }

    openAddThingDefinitionDialog(index: number): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.panelClass = "data-export-add-thing-definition-dialog";
        const selectedElement = index != null ? this.exportData[index] : null;
        dialogConfig.data = {
            selectedElement: selectedElement,
            thingDefinitions: this.thingDefinitions.filter(td => selectedElement?.exportThingDef.id == td.id || !this.exportData.some(ed => ed.exportThingDef.id == td.id)),
            currentThing: this.thingContextService.getCurrentThing()
        }
        dialogConfig.autoFocus = false;
        dialogConfig.maxWidth = '428px';
        dialogConfig.disableClose = true;
        this.dialog.open(DataExportWidgetAddThingDefinitionDialog, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                if (index != null) {
                    this.exportData[index] = result;
                    this.dataSource = new MatTableDataSource<{ exportThingDef: ThingDefinition, exportMetrics: Metric[], metricNamesLabel: string }>(this.exportData);
                } else {
                    this.exportData.push(result);
                    this.dataSource = new MatTableDataSource<{ exportThingDef: ThingDefinition, exportMetrics: Metric[], metricNamesLabel: string }>(this.exportData);
                }
                this.updateDateLimit();
            }
        });
    }

    execButtonAction(actionValue: ButtonActionValue): void {
        switch (actionValue.action) {
            case 'delete':
                this.exportData.splice(actionValue.index, 1);
                this.dataSource = new MatTableDataSource<{ exportThingDef: ThingDefinition, exportMetrics: Metric[], metricNamesLabel: string }>(this.exportData);
                this.updateDateLimit();
                break;
        }
    }

    private updateDateLimit(init?: boolean): void {
        let metricsList: string[] = [];
        this.exportData.forEach(el => {
            el.exportMetrics.forEach(m => {
                metricsList.push(m.id);
            });
        });
        const configurationProperty = this.configuration.find(conf => metricsList.length <= conf.maxMetrics || conf.maxMetrics == null);
        const monthLimit = configurationProperty?.maxMonths != null ? configurationProperty.maxMonths : 0;
        if (monthLimit == 0) {
            this.error = "Invalid maxPeriod configuration";
            this.invalidPeriodConfiguration = true;
            return;
        }
        const maxPeriodChanged = (monthLimit * 30) != this.maxDaysBack;
        this.maxDaysBack = monthLimit * 30;
        this.maximumPeriodMessage = this.localizationPipe.transform(this.maximumPeriodDefaultMessage).replace('${maxMonths}', monthLimit.toString());
        this.showMaxPeriodChanged = !init && maxPeriodChanged;
        if (maxPeriodChanged) {
            this.hideDateRangePicker = true;
            this.visibleRanges = this.dataExportConfigurationService.updateVisibleRanges(monthLimit, this.allowedPeriods, this.filterPeriods);
            setTimeout(() => this.hideDateRangePicker = false, 10);
        }
    }

}