import { Component, forwardRef, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as _ from "lodash";
import { AuditEvent } from "../../model/audit-event";

@Component({
    selector: 'audit-event-detail-dialog',
    template: require('./audit-event-detail-dialog.component.html')
})
export class AuditEventDetailDialogComponent {

    auditEvent: AuditEvent;
    detailsFilter: string;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<AuditEventDetailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
    ) {
        const actionData = _.cloneDeep(data);
        this.auditEvent = actionData.auditEvent;
        this.detailsFilter = actionData.detailsFilter;
    }
}