import { Component, ContentChildren, forwardRef, Host, Inject, Input, OnInit, QueryList, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ErrorMessages, Permissions } from '../../common/constants';
import { PropertyInfo } from '../../common/properties';
import { Location, MaintenanceWork, Thing } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { PropertyComponent } from '../../shared/component/property/property.component';
import { CustomTableColumn } from '../../shared/custom-table';
import { COMPONENT_DEFINITION_REF } from '../../shared/utility/component-definition-token';
import { ErrorUtility } from '../../utility/error-utility';
import { MaintenanceRegistryDialogComponent } from './maintenance-registry-dialog.component';
import { MaintenanceRegistryWidgetService } from './maintenance-registry-widget.service';

@Component({
	selector: 'maintenance-registry-widget',
	template: require('./maintenance-registry-widget.component.html'),
	providers: [MaintenanceRegistryWidgetService]
})
export class MaintenanceRegistryWidgetComponent implements OnInit {

	@Input() title: string;

	@Input() showHeader: boolean;

	@ViewChild(MaintenanceRegistryDialogComponent) dialog: MaintenanceRegistryDialogComponent;

	@ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<PropertyComponent>;

	loaded: boolean;
	error: string;
	readPermission: boolean;
	writePermission: boolean;
	maintenanceWorks: MaintenanceWork[] = [];
	dataSource = new MatTableDataSource<MaintenanceWork>([]);
	displayedColumns: CustomTableColumn[];
	displayedColumnNames: string[];
	thingValues: { value: string, label: string }[];
	thing: Thing;
	location: Location;

	private defaultProperties: { [name: string]: PropertyInfo } = {
		startTimestamp: { label: 'dateProperty', path: 'startTimestamp', defaultFilter: null, defaultSorting: null },
		type: { label: 'typeProperty', path: 'type', defaultFilter: 'underscoreRemover', defaultSorting: null },
		userEmail: { label: 'userProperty', path: 'userEmail', defaultFilter: null, defaultSorting: null },
		duration: { label: 'testDurationProperty', path: null, defaultFilter: null, defaultSorting: null }
	};

	constructor(
		@Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
		@Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
		@Inject(forwardRef(() => MaintenanceRegistryWidgetService)) private maintenanceRegistryService: MaintenanceRegistryWidgetService,
		@Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService
	) { }

	ngOnInit(): void {
		this.title = this.title || 'maintenanceRegistryProperty';
		if (this.showHeader === undefined) {
			this.showHeader = true;
		}
		if (this.thingContextService.getCurrentThing()) {
			this.thing = this.thingContextService.getCurrentThing();
		} else {
			this.location = this.contextService.getCurrentLocation();
		}
		this.readPermission = this.authenticationService.hasPermission(Permissions.READ_MAINTENANCE_REGISTRY);
		this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_MAINTENANCE_REGISTRY);
	}

	ngAfterContentInit(): void {
		this.displayedColumns = this.maintenanceRegistryService.getVisibleColumns(this.columnComponents.toArray(), this.defaultProperties, 'MaintenanceWork');
		this.displayedColumnNames = this.displayedColumns.map(dc => { return dc.name });
		if (this.location) {
			this.maintenanceRegistryService.getThingsByLocationId(this.location.id).then(things => {
				this.thingValues = things.map(t => { return { value: t.id, label: t.name } });
				this.refreshTable();
			}).catch(err => ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
		} else {
			this.refreshTable();
		}
	}

	private refreshTable(): void {
		this.loaded = false;
		this.maintenanceRegistryService.getMaintenanceWork(this.thing, this.location).then(maintenanceWorks => {
			this.maintenanceWorks = maintenanceWorks;
			if (this.location) {
				this.maintenanceWorks.forEach(mw => {
					mw['thingName'] = this.thingValues.find(thing => thing.value == mw.thingId) ? this.thingValues.find(thing => thing.value == mw.thingId).label : null;
				});
			}
			this.dataSource = new MatTableDataSource<MaintenanceWork>(this.maintenanceWorks);
			this.loaded = true;
		}).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
	}

	addMaintenance(): void {
		this.dialog.open(null);
	}

	refresh(): void {
		this.refreshTable();
	}

	editMaintenance(maintenanceWork: MaintenanceWork): void {
		this.dialog.open(maintenanceWork);
	}

	deleteMaintenance(id: string): void {
		this.maintenanceRegistryService.deleteMaintenanceWork(id).then(() => this.refreshTable())
			.catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.DELETE_DATA_ERROR));
	}

}
