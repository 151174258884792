import { Component, forwardRef, Inject } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { firstValueFrom } from "rxjs";
import { Permissions } from '../../../common/constants';
import { HistoricalAlertWorkSessionMatPaginatorIntl } from "../../../dashboard-area/shared/historical-alert-work-session-mat-paginator-intl.service";
import { WorkSessionService } from "../../../dashboard-area/shared/work-session.service";
import { ListRangeSelectionModeType, PagedList, WorkSession, WorkSessionDefinition } from "../../../model";
import { AbstractExportContextService } from "../../../service/abstract-export-context.service";
import { AppService } from "../../../service/app.service";
import { AuthenticationService } from "../../../service/authentication.service";
import { NavigationService } from "../../../service/navigation.service";
import { ThingContextService } from "../../../service/thing-context.service";
import { SearchTargetType } from "../../../shared/component/search-field/search-field.component";
import { ConfirmDialog } from "../../../shared/confirm-dialog/confirm-dialog.component";
import { CustomTableService } from "../../../shared/custom-table";
import { ErrorUtility } from "../../../utility/error-utility";
import { DetailsModeType } from "../../shared/alert-work-session-list";
import { ThingListWidgetV2Service } from "../../thing-list/thing-list-widget-v2.service";
import { AbstractWorkSessionListWidgetV2Component } from "../abstract-work-session-list-widget-v2.component";
import { HistoricalWorkSessionListWidgetV2Service } from "./historical-work-session-list-widget-v2.service";

@Component({
    selector: 'historical-work-session-list-widget-v2',
    template: require('./historical-work-session-list-widget-v2.component.html'),
    providers: [HistoricalWorkSessionListWidgetV2Service, ThingListWidgetV2Service, WorkSessionService, { provide: MatPaginatorIntl, useClass: HistoricalAlertWorkSessionMatPaginatorIntl }],
    styles: [require('../../list-widget-v2/list-widget-v2.css')]
})
export class HistoricalWorkSessionListWidgetV2Component extends AbstractWorkSessionListWidgetV2Component {

    manualActivationWorkSessionDefinitions: WorkSessionDefinition[] = [];
    enableImport: boolean;
    hidden: boolean;

    private writeWorkSessionPermission: boolean;

    constructor(
        @Inject(forwardRef(() => HistoricalWorkSessionListWidgetV2Service)) private historicalWorkSessionListWidgetV2Service: HistoricalWorkSessionListWidgetV2Service,
        @Inject(forwardRef(() => NavigationService)) protected navigationService: NavigationService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => ThingListWidgetV2Service)) thingListWidgetV2Service: ThingListWidgetV2Service,
        @Inject(forwardRef(() => WorkSessionService)) workSessionService: WorkSessionService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService,
        @Inject(forwardRef(() => MatDialog)) dialog: MatDialog
    ) {
        super(historicalWorkSessionListWidgetV2Service, navigationService, appService, thingListWidgetV2Service, workSessionService, authenticationService, exportService, dialog);
    }

    protected init(): void {
        this.checkIfHidden();
        this.writeWorkSessionPermission = this.authenticationService.hasPermission(Permissions.WRITE_WORK_SESSION);
        this.handleAdvancedSearchLayoutType("historical-work-session-list-popup-advanced-search", SearchTargetType.HISTORICAL_WORK_SESSIONS);
        this.localStorageKey = 'historicalWorkSessionAdvancedSearchFieldsValues';
        if (this.thingContextService.getCurrentThing() && this.thingContextService.getCurrentThing().thingDefinitionId) {
            this.workSessionService.getThingDefinitionWorkSessionDefinitions(this.thingContextService.getCurrentThing().thingDefinitionId, true).then(ws => {
                this.manualActivationWorkSessionDefinitions = ws.filter(w => w.manualActivation);
                this.enableImport = this.writeWorkSessionPermission && !!this.manualActivationWorkSessionDefinitions?.length;
            });
        }
    }

    private checkIfHidden(): void {
        if (!this.thingContextService.getCurrentThing()) {
            if (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForOrgPartner');
            } else {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForCustomer');
            }
        }
    }

    protected updatePaginatorClass(): void {
        this.matPaginatorClass = "mat-paginator-hidden-last ";
        if (this.authenticationService.getTenant().listRangeSelectionMode == ListRangeSelectionModeType.PAGES) {
            this.matPaginatorClass += "mat-paginator-pages-mode";
        } else {
            this.matPaginatorClass += "mat-paginator-hidden-range-label";
        }
    }

    protected getWorkSessionList(): void {
        this.historicalWorkSessionListWidgetV2Service.getPagedList(this.pageIndex, this.pageSize, null, null, this.searchFields, this.advancedSearchBody, null, this.includeSubThingsWorkSessions).then(pagedList => {
            this.error = null;
            this.historicalWorkSessionListWidgetV2Service.updatePageListContent(pagedList.content, this.workSessionDefinitions, this.displayedColumns, true, this.detailsMode);
            if (this.writeWorkSessionPermission && pagedList.content.some(ws => ws.manualActivation)) {
                if (!this.displayedColumns.some(c => c.name == 'delete')) {
                    this.displayedColumns.push(CustomTableService.newButtonColumn('delete', '', 'id', 'float-right', 'deleteButton').withMatIcon('delete').withVisiblePath('manualActivation').withStyle({ '_any': { 'font-size': '20px', 'padding': '0px', 'color': '#DD4B39', 'width': '48px' } }));
                }
            } else {
                const deleteColumnIndex = this.displayedColumns.findIndex(c => c.name == 'delete');
                if (deleteColumnIndex > -1) {
                    this.displayedColumns.splice(deleteColumnIndex, 1);
                }
            }
            this.updateElementList(pagedList);
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    updateElementList(pagedList: PagedList<WorkSession>): void {
        if (this.isMobile) {
            this.mobileListWidget.updateElementList(pagedList);
        } else {
            this.fillerRowCount = pagedList.numberOfElements;
            this.elements = pagedList.content;
            this.dataSource = new MatTableDataSource<WorkSession>(this.elements);
            this.length = (pagedList.number * pagedList.size) + pagedList.numberOfElements + (pagedList.last ? 0 : 1);
            this.pageSize = pagedList.size;
            this.pageIndex = pagedList.number;
            this.totalPages = pagedList.totalPages;
            this.loaded = true;
        }
    }

    refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        this.loaded = false;
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.getWorkSessionList();
    }

    protected goToWorkSessionDetails(id: string): void {
        if (this.detailsMode == DetailsModeType.PAGE) {
            this.navigationService.navigateTo(['/dashboard/historical_work_session_details', id]);
        } else if (this.detailsMode == DetailsModeType.POPUP) {
            this.openDialog(id, true);
        }
    }

    protected subscribeToExportServices(): void {
        this.exportId = "historical-alert-list-" + this.getNextId();
        this.exportService.subscribeToExport(this.exportId, this.title || "Historical Alerts").subscribe(() => this.export());
        this.subscribeToExportVisibility();
    }

    import() {
        this.refreshElementList();
    }

    protected deleteWorkSession(id: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            title: "deleteWorkSessionTitle",
            message: "deleteWorkSessionMessage"
        }
        firstValueFrom(this.dialog.open(ConfirmDialog, dialogConfig).afterClosed()).then(result => {
            if (result) {
                this.workSessionService.deleteHistoricalWorkSession(id).then(() => this.refreshElementList());
            }
        });
    }

}