import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Inject, Input, OnDestroy, OnInit, Output, SecurityContext, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { Subscription } from 'rxjs';
import { CONFIG } from '../../common/config';
import { Countries } from '../../common/countries';
import { isEmptyObject } from '../../common/helper';
import { SelectOption } from '../../model';
import { TextMap } from '../../service/view.service';
import { DatetimeHelper } from '../../shared/utility/datetime-helper';
import { datetimeValidator, emailValidator, stepValidator, typeValidator } from '../../shared/validator/index';

let nextId = 0;

@Component({
    selector: 'form-control',
    template: require('./form-control.component.html'),
    styles: [`
        label.required:after {
            content:" *";
        }

        .radio-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }

        .toggle-with-description ::ng-deep .mat-slide-toggle-bar{
            margin-bottom: 23px;
        }

        .checkbox-with-description ::ng-deep .mat-checkbox-inner-container{
            margin-bottom: 28px;
        }
    `]
})
export class FormControlComponent implements AfterViewInit, OnDestroy, OnInit {

    @Input() name: string;

    @Input() label: string;

    @Input() description: string;

    @Input() required: boolean;

    @Input() type: string;

    @Input() form: AbstractControl;

    @Input() values: string[] | SelectOption[];

    @Input() selectionMode: string;

    @Input() trueLabel: string;

    @Input() falseLabel: string;

    @Input() minValue: string;

    @Input() maxValue: string;

    @Input() stepValue: string;

    @Input() blobResultMode: string;

    @Input() set enabled(enabled: boolean) {
        this._enabled = enabled;
        if (this.control) {
            if (enabled) {
                this.control.enable();
            } else {
                this.control.disable();
            }
        }
    };

    get enabled(): boolean {
        return this._enabled
    }

    @Input() value: string;

    @Input() suffix: string;

    @Input() slider: boolean;

    @Input() title: string;

    @Input() hideLabel: boolean;

    @Input() placeholder: string;

    @Input() multiple: boolean = true;

    @Input() resultBlob: string;

    @Input() hideError: boolean;

    @Input() touchForError: boolean;

    @Input() showRequiredLabel: boolean;

    @Input() textMap: TextMap;

    @Input() allPlaceFields: { field: string, type: string }[] = [];

    @Input() useSlideToggleForBoolean: boolean;

    @Input() set country(country: string) {
        if (country != this._country) {
            this._country = country;
            if (this.controlType == 'PLACE_FIELD') {
                this.setPlaceFieldOptions();
            }
        }
    }

    @Input() patternValidation: string;

    @Input() validationErrorMessage: string;

    @Output() fileUpload = new EventEmitter();

    @Output() multipleFileUpload = new EventEmitter();

    @Output() fileDownload = new EventEmitter();

    @Output() fileDelete = new EventEmitter();

    @Output() valueChanged = new EventEmitter();

    @Output() addressChanged = new EventEmitter<{ [fieldName: string]: string }>();

    @ViewChild("placesRef") placesRef: GooglePlaceDirective;

    radioCheck: boolean = true;
    controlType: string;
    id: string;
    hasSuffix: boolean;
    inputType: string;
    normalizedValues: SelectOption[];
    control: FormControl;
    labelClass: string = '';
    options: Options;

    private fileField: any;
    private eventSubscription: Subscription;
    private _country: string;
    private _enabled: boolean;

    constructor(
        @Inject(forwardRef(() => ElementRef)) private elRef: ElementRef,
        @Inject(forwardRef(() => DomSanitizer)) private sanitizer: DomSanitizer
    ) { }

    getValuesRenderType(): string {
        if (this.values && this.values.length) {
            if (!this.selectionMode) {
                return 'CHECKBOX';
            }
            return this.selectionMode;
        }
        return null;
    }

    manageFile(data) {
        const file: File = <any>(data.srcElement || data.target).files.item(0);
        const p = new Promise(resolve => {
            const data: { filename: string, file: File, base64?: string } = {
                filename: this.sanitizer.sanitize(SecurityContext.HTML, file.name),
                file: file,
            };
            if (this.blobResultMode == 'BASE64') {
                const reader = new FileReader();
                reader.addEventListener("load", function () {
                    let dataUrl: string = reader.result as string;
                    dataUrl = dataUrl.substring(dataUrl.lastIndexOf(",") + 1);
                    data.base64 = dataUrl;
                    resolve(data);
                }, false);
                if (file) {
                    reader.readAsDataURL(file);
                }
            } else {
                resolve(data);
            }
        });
        p.then(data => this.fileUpload.emit(data));
    }

    manageMultipleFile(data) {
        const files: FileList = <any>(data.srcElement || data.target).files;
        let dataToEmit: { filename: string, file: File, base64?: string }[] = [];
        for (let i = 0; i < files.length; i++) {
            dataToEmit.push({
                filename: this.sanitizer.sanitize(SecurityContext.HTML, files.item(i).name),
                file: files.item(i),
            });
        }
        this.multipleFileUpload.emit(dataToEmit);
    }

    downloadFile(): void {
        this.fileDownload.emit();
    }

    deleteFile(): void {
        this.value = '';
        this.fileField.filestyle('clear');
        this.fileDelete.emit();
    }

    ngAfterViewInit() {
        if (this.type == 'DATE') {
            const $element = $(this.elRef.nativeElement).find('#' + this.id);
            DatetimeHelper.initDatetimePicker(this.elRef.nativeElement, null, this.id);
            $element.on("change.datetimepicker", value => {
                if (value && value['date'] && value['date'].format) {
                    const v = value['date'].format(CONFIG.DATETIME_FORMAT);
                    (this.form as FormGroup).controls[this.name].setValue(v);
                }
            });
        } else if (this.type === 'BASE64' || this.type === 'FILE' || this.type === 'BLOB') {
            this.fileField = $(':file') as any;
            this.fileField.filestyle({ buttonBefore: true, placeholder: this.extractPlaceholder(), btnClass: "btn-default" });
        }
        if (this.selectionMode == 'SELECTION') {
            setTimeout(() => this.control.reset(this.value), 10);    // bug5185
        }
    }

    private extractPlaceholder(): string {
        if (this.value) {
            try {
                return this.sanitizer.sanitize(SecurityContext.HTML, JSON.parse(this.value)["fileName"]);
            } catch {
                return "";
            }
        }
        return "";
    }

    ngOnChanges(changes) {
        // if the only change is the label title -> return
        if (changes["title"]) {
            if (Object.keys(changes).length == 1) {
                return;
            }
        }

        if (this.control) {
            setTimeout(() => {
                this.control.reset(this.setValue());
            }, 10);
        }
    }

    ngOnDestroy() {
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
            this.eventSubscription = null;
        }
    }

    ngOnInit() {
        this.id = 'control-' + nextId++;
        this.trueLabel = this.trueLabel || 'trueLabel';
        this.falseLabel = this.falseLabel || 'falseLabel';
        this.hasSuffix = this.suffix != undefined && this.suffix !== '';
        this.control = this.addFormControl();
        this.setInputType();
        this.setControlType();
        this.normalizedValues = this.getValues();
        if (this.showRequiredLabel && this.required) {
            this.labelClass = 'required';
        }
    }

    private setInputType(): void {
        this.inputType = 'text';
        if (this.type === 'MOBILE_NUMBER' || this.type === 'PHONE_NUMBER') {
            this.inputType = 'tel';
        }
        if (this.type === 'EMAIL') {
            this.inputType = 'email';
        }
    }

    private setControlType(): void {
        if (this.type == 'INTEGER' || this.type == 'LONG' || this.type == 'DOUBLE' || this.type == 'FLOAT') {
            this.controlType = 'NUMBER'
        } else if (this.type == 'BOOLEAN') {
            this.controlType = 'BOOLEAN';
        } else if (this.type == 'DATE') {
            this.controlType = 'DATE';
        } else if (this.type == 'BASE64') {
            this.controlType = 'BASE64';
        } else if (this.type == 'BLOB') {
            this.controlType = 'BLOB';
        } else if (this.type == 'FILE') {
            this.controlType = 'FILE';
        } else if (this.type == 'ADDRESS') {
            this.controlType = 'PLACE_FIELD';
            this.setPlaceFieldOptions();
        } else {
            this.controlType = 'TEXT';
        }
    }

    private setPlaceFieldOptions(): void {
        let options: Partial<Options> = { types: [], fields: ['name', 'address_components'] };
        if (this._country) {
            options.componentRestrictions = { country: Countries[this._country] };
        }
        this.options = new Options(options);
        if (this.placesRef) {
            this.placesRef.options = this.options;
            this.placesRef.reset();
        }
    }

    private addFormControl(): FormControl {
        const validators: ValidatorFn[] = [];
        const renderType = this.getValuesRenderType();
        validators.push(typeValidator(this.type));
        if (this.type == 'EMAIL' && !renderType) {
            validators.push(emailValidator());
        }
        if (this.type == 'DATE') {
            validators.push(datetimeValidator());
        }
        if (this.type == 'INTEGER' || this.type == 'LONG' || this.type == 'DOUBLE' || this.type == 'FLOAT') {
            const min = this.convertValue(this.minValue);
            const step = this.convertValue(this.stepValue);
            if (!isNaN(step)) {
                validators.push(stepValidator(step, min));
            }
        }
        const control = new FormControl({ value: this.setValue(), disabled: !this.enabled }, validators);
        if (this.form instanceof FormGroup) {
            this.form.addControl(this.name, control);
        } else if (this.form instanceof FormArray) {
            this.form.push(control);
        }
        control.valueChanges.subscribe(val => this.valueChanged.emit(val));
        return control;
    }

    private convertValue(value: string): number {
        if (this.type == 'INTEGER' || this.type == 'LONG') {
            return parseInt(value);
        } else {
            return parseFloat(value);
        }
    }

    private setValue(): any {
        if (this.value != undefined && this.value !== '' && this.type != 'FILE') {
            if (this.type != 'BOOLEAN' || (this.type == 'BOOLEAN' && this.selectionMode && this.selectionMode != 'SWITCH')) {
                if (this.type == 'INTEGER' || this.type == 'LONG' || this.type == 'FLOAT' || this.type == 'DOUBLE') {
                    if (this.selectionMode == 'RADIO_BUTTON') {
                        return this.value.toString();
                    }
                    return this.convertValue(this.value);
                } else {
                    return this.value.toString();
                }
            } else {
                return (typeof this.value === 'boolean') ? this.value : (this.value === 'true');
            }
        } else {
            return (this.type == 'BOOLEAN' && this.useSlideToggleForBoolean) ? false : '';
        }
    }

    getErrorKeys(): string[] {
        let control: FormControl;
        if (this.form instanceof FormGroup) {
            control = (this.form as FormGroup).controls[this.name] as FormControl;
        } else {
            control = this.form.get(this.name) as FormControl;
        }
        if (control && control.errors) {
            return Object.keys(control.errors);
        }
        return null;
    }

    getErrorLabel(key: string): string {
        if (key == 'min') {
            return 'minValueValidation';
        } else if (key == 'max') {
            return 'maxValueValidation';
        } else if (key == 'pattern' && this.type == 'FISCAL_CODE') {
            return 'fiscalCodeValidation';
        } else if (key == 'required') {
            return 'requiredValidation';
        } else {
            return key;
        }
    }

    getErrorInfo(errorKey: string) {
        let control: FormControl;
        if (this.form instanceof FormGroup) {
            control = (this.form as FormGroup).controls[this.name] as FormControl;
        } else {
            control = this.form.get(this.name) as FormControl;
        }
        if (control && control.errors) {
            return control.errors[errorKey];
        }
    }

    private getValues(): SelectOption[] {
        let values = [];
        if (this.values != null && this.values instanceof Array) {
            if (typeof this.values[0] == "string") {
                (this.values as string[]).forEach(v => values.push({ value: v, label: v }));
            } else {
                return this.values as SelectOption[];
            }
        }
        return values;
    }

    isSelected(s1: any, s2: any) {
        return s1 == s2;
    }

    hasError(): boolean {
        const control = this.form.get(this.name) as FormControl;
        if (control) {
            if (this.touchForError) {
                return control.invalid && control.touched;
            } else {
                return control.invalid;
            }

        }
        return false;
    }

    handleAddressChange(address: Address): void {
        let placeObjMap = {};
        let countryCode = this.getAddressPartnNameByType(address, 'country');
        let street = this.getAddressPartnNameByType(address, 'route');
        let streetNumber = this.getAddressPartnNameByType(address, 'street_number');
        let city = this.getAddressPartnNameByType(address, 'locality');
        let zipCode = this.getAddressPartnNameByType(address, 'postal_code');
        let stateProvince = (countryCode == 'IT') ?
            (this.getAddressPartnNameByType(address, 'administrative_area_level_2') || this.getAddressPartnNameByType(address, 'administrative_area_level_1'))
            : this.getAddressPartnNameByType(address, 'administrative_area_level_1');
        this.allPlaceFields.forEach(t => {
            let fieldValue: string;
            switch (t.type) {
                case 'ADDRESS':
                    // address is: 
                    //  if STREET_NUMBER exists -> ${street}
                    //  if any other place property exists ->  ${street} ${streetNumber}
                    //  else ->  ${street} ${streetNumber}, ${zipCode} ${city} ${province}
                    fieldValue = street;
                    if (this.allPlaceFields.some(f => f.type == 'STREET_NUMBER')) {
                        break;
                    } else {
                        if (streetNumber) {
                            fieldValue += ' ' + streetNumber;
                        }
                        if (this.allPlaceFields.some(f => f.type == 'CITY' || f.type == 'STATE' || f.type == 'ZIP_CODE')) {
                            break;
                        }
                        fieldValue += [',', zipCode, city, stateProvince].filter(Boolean).join(' '); // joining existing ones
                    }
                    break;
                case 'CITY':
                    fieldValue = city;
                    break;
                case 'STATE':
                    fieldValue = stateProvince;
                    break;
                case 'STREET_NUMBER':
                    fieldValue = streetNumber;
                    break;
                case 'ZIP_CODE':
                    fieldValue = zipCode;
                    break;
                default:
                    break;
            }
            if (fieldValue) {
                placeObjMap[t.field] = fieldValue;
            }
        });
        if (!this._country) {
            placeObjMap['country'] = Object.keys(Countries).find(c => Countries[c] == countryCode);
        }
        if (!isEmptyObject(placeObjMap)) {
            this.addressChanged.emit(placeObjMap);
        }
    }

    private getAddressPartnNameByType(address: Address, type: string): string {
        return address.address_components.find(c => c.types.indexOf(type) >= 0)?.short_name;
    }

    getTrueLabel(): string {
        if (this.normalizedValues?.length) {
            let trueValue = this.normalizedValues.find(v => v.value == 'true');
            if (trueValue) {
                return trueValue.label;
            }
        }
        return this.trueLabel;
    }

    getFalseLabel(): string {
        if (this.normalizedValues?.length) {
            let falseValue = this.normalizedValues.find(v => v.value == 'false');
            if (falseValue) {
                return falseValue.label;
            }
        }
        return this.falseLabel;
    }
}
