import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { METRIC_VALUES_CONTENT, SOCKET_TOPIC_DATA_VALUES, THING_VALUES } from '../common/endpoints';
import { DataItem, DataResult, Metric, SystemMetric, Value, ValueItem } from '../model/index';
import { HttpUtility } from '../utility';
import { HttpService } from './http.service';
import { SocketService } from './socket.service';

@Injectable()
export class DataService {

    private socketSubscriptions: { [subscriberId: string]: number[] } = {};

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone
    ) { }

    getValues(metricName: string, thingId: string, pageSize?: number, params?: HttpParams): Promise<{ values: Value[], nextPageToken: string }> {
        if (this.isSampleObject(thingId)) {
            let values = this.getMultipleSampleValues(metricName, true);
            values = pageSize ? values.slice(0, pageSize) : values;
            return Promise.resolve({
                values,
                nextPageToken: undefined
            });
        }
        let lastNextPageToken = null;
        let values = [];

        if (!params) {
            params = new HttpParams();
        }

        const collectValues = (values: any[], result: { values: Value[], nextPageToken: string }, metricName: string, pageSize: number, thingId: string, params: HttpParams): Promise<{ values: Value[], nextPageToken: string }> => {
            values = values.concat(result.values);
            lastNextPageToken = result.nextPageToken;
            if (result.nextPageToken && (values.length < pageSize || !pageSize)) {
                if (!params) {
                    params = new HttpParams();
                }
                params = params.set('pageToken', result.nextPageToken);
                return this.getRawValues(metricName, thingId, params).then(result => {
                    return collectValues(values, result, metricName, pageSize, thingId, params);
                });
            };

            const ret = {
                nextPageToken: lastNextPageToken,
                values: pageSize ? values.slice(0, pageSize) : values
            }

            return Promise.resolve(ret);
        }

        if (pageSize) {
            params = params.set('pageSize', '' + pageSize);
        }
        return this.getRawValues(metricName, thingId, params).then(result => {
            return collectValues(values, result, metricName, pageSize, thingId, params);
        });
    }

    downloadValueContent(metricName: string, thingId: string, date: number): void {
        const params = new HttpParams().set("metricName", metricName).set("thingId", thingId).set("date", date + "");

        this.httpService.getFileWithName(METRIC_VALUES_CONTENT, 'file', params).toPromise()
            .then(fileObj => this.httpUtility.wrapFileAndDownload(fileObj));
    }

    getLastValueByCustomerIdAndMetricName(customerId: string, metricName: string, params?: HttpParams, extractValue = true): Promise<Value> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('customerId', customerId);
        return this.getLastValueByMetricName(metricName, this.isSampleObject(customerId), params, extractValue);
    }

    getLastValueByLocationIdAndMetricName(locationId: string, metricName: string, params?: HttpParams, extractValue = true): Promise<Value> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('locationId', locationId);
        return this.getLastValueByMetricName(metricName, this.isSampleObject(locationId), params, extractValue);
    }

    getLastValueByThingIdAndMetricName(thingId: string, metricName: string, params?: HttpParams, extractValue = true): Promise<Value> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('thingId', thingId);
        return this.getLastValueByMetricName(metricName, this.isSampleObject(thingId), params, extractValue);
    }

    getLastValueByPartnerIdAndMetricName(partnerId: string, metricName: string, params?: HttpParams, extractValue = true): Promise<Value> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('partnerId', partnerId);
        return this.getLastValueByMetricName(metricName, this.isSampleObject(partnerId), params, extractValue);
    }

    private getLastValueByMetricName(metricName: string, sampleObject: boolean, params?: HttpParams, extractValue?: boolean): Promise<Value> {
        if (sampleObject) {
            return Promise.resolve(this.getOneSampleValue(metricName, extractValue, true));
        }
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('pageSize', '1');
        params = params.set('metricName', metricName);
        return firstValueFrom(
            this.httpService.get<DataResult>(THING_VALUES, params)
                .pipe(map(resp => {
                    let data = resp.data;
                    if (data && data.length && data[0].values && data[0].values.length) {
                        return {
                            timestamp: data[0].timestamp,
                            value: extractValue ? DataService.extractValue(data[0].values) : data[0].values,
                            unspecifiedChange: data[0].unspecifiedChange,
                        };
                    } else if (resp.privateData) {
                        return {
                            timestamp: null,
                            value: null,
                            unspecifiedChange: null,
                            privateData: true
                        }
                    }
                    return null;
                })));
    }

    private getRawValues(metricName: string, thingId: string, params?: HttpParams): Promise<{ values: Value[], nextPageToken: string }> {
        if (!params) {
            params = new HttpParams();
        }

        params = params.set("thingId", thingId);
        params = params.set("metricName", metricName);
        return firstValueFrom(this.httpService.get<DataResult>(THING_VALUES, params).pipe(map(dataResult => {
            const dataItems = dataResult.data;
            let values: Value[] = [];

            if (dataItems && dataItems.length) {
                values = dataItems.map(dataItem => Object.assign({}, {
                    timestamp: dataItem.timestamp,
                    value: DataService.extractValue(dataItem.values),
                    unspecifiedChange: false
                }));
            } else if (dataResult.privateData) {
                values = dataItems.map(_ => Object.assign({}, {
                    timestamp: null,
                    value: null,
                    unspecifiedChange: false,
                    privateData: true
                }));
            }

            return {
                nextPageToken: dataResult.nextPageToken,
                values
            };
        })));
    }

    private isSampleObject(objectId: string): boolean {
        const id = parseInt(objectId);
        return !isNaN(id) && id < 0;
    }

    private getMultipleSampleValues(metricName: string, stringType: boolean, n: number = 10): Value[] {
        const values: Value[] = [];
        const now = moment();
        for (let i = 0; i < n; i++) {
            const ts = now.subtract(1, 'h').valueOf();
            values[i] = this.generateSampleValue(metricName, ts, true, stringType);
        }
        return values;
    }

    private getOneSampleValue(metricName: string, extractValue: boolean, stringType: boolean): Value {
        const ts = moment().subtract(1, 'h').valueOf();
        return this.generateSampleValue(metricName, ts, extractValue, stringType);
    }

    private generateSampleValue(metricName: string, ts: number, extractValue: boolean, stringType: boolean): Value {
        const MAX = 50;
        const MIN = 10;
        let val: number;
        let values: number[];

        if (metricName === SystemMetric.CONNECTION_STATUS_METRIC_NAME) {
            values = [-1, 0, 1];
        } else if (metricName === SystemMetric.CLOUD_STATUS_METRIC_NAME) {
            values = [0, 1, 2, 3];
        }

        if (values) {
            const maxIndex = values.length - 1;
            const index = Math.ceil(Math.random() * maxIndex);
            val = values[index];
        } else {
            val = Math.ceil(Math.random() * MAX) + MIN;
        }
        if (stringType) {
            return {
                timestamp: ts,
                value: extractValue ? val + '' : [{ name: metricName, value: val + '', path: '' }],
                unspecifiedChange: false
            };
        } else {
            return {
                timestamp: ts,
                value: extractValue ? val : [{ name: metricName, value: val, path: '' }],
                unspecifiedChange: false
            };
        }
    }

    static extractValue(values: ValueItem[]): any {
        if (!values || !values.length) {
            return undefined;
        }
        return values.length > 1 ? values : values[0].value;
    }

    static mergeMetricValues(metricValues: Value[][], size: number): { [timestamp: string]: string[] } {
        const pointers: number[] = [];
        const result: { [timestamp: string]: string[] } = {};

        const allTimestamps: number[] = metricValues
            .reduce((allValues: Value[], values: Value[]) => allValues.concat(values))
            .map((value: Value) => value.timestamp);
        const timestamps = DataService.arrayUnique(allTimestamps).sort((a, b) => b - a).slice(0, size);
        metricValues = metricValues.map(value => value.sort((a, b) => b.timestamp - a.timestamp));

        metricValues.reduce((p, metric) => {
            p.push(0);
            return p;
        }, pointers);

        for (var i = 0; i < timestamps.length; i++) {
            result[timestamps[i]] = new Array(metricValues.length);
            for (var j = 0; j < pointers.length; j++) {
                const value = metricValues[j][pointers[j]];
                if (value && value.timestamp == timestamps[i]) {
                    result[timestamps[i]][j] = value.value;
                    pointers[j]++;
                }
            }
        }
        return result;
    }

    static arrayUnique(array: any[]): any[] {
        var a = array.concat();
        for (var i = 0; i < a.length; ++i) {
            for (var j = i + 1; j < a.length; ++j) {
                if (a[i] === a[j])
                    a.splice(j--, 1);
            }
        }
        return a;
    }

    resetMetric(thingId: string, metric: Metric, resetValue: string): Promise<void> {
        let params = new HttpParams();
        params = params.set("thingId", thingId);
        params = params.set("metricName", metric.name);

        let valueItem = new ValueItem();
        valueItem.value = resetValue ? resetValue : (metric.resetValue || '0');
        let dataItem = new DataItem();
        dataItem.values = [valueItem];
        let body = { data: [dataItem] };

        return firstValueFrom(this.httpService.put<void>(THING_VALUES, body, params));
    }

    subscribeToMetric(thingId: string, metricName: string, subscriberId: string): BehaviorSubject<Value> {
        let subject = new BehaviorSubject(null);
        this.getLastValueByThingIdAndMetricName(thingId, metricName)
            .then(data => {
                subject.next(data);
                if (data && data.privateData) {
                    return;
                }
                const subscriptionId = this.socketService.subscribe({
                    topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', thingId).replace('{metricName}', metricName),
                    callback: message => {
                        const data = JSON.parse(message.body);
                        if (data.unspecifedChange) {
                            this.getLastValueByThingIdAndMetricName(thingId, metricName)
                                .then(data => {
                                    this.zone.run(() => subject.next(data));
                                }).catch(() => { });
                        } else {
                            const newData: Value = {
                                unspecifiedChange: data.unspecifiedChange,
                                timestamp: data.timestamp,
                                value: DataService.extractValue(data.values)
                            };
                            this.zone.run(() => subject.next(newData));
                        }
                    }
                });
                if (this.socketSubscriptions[subscriberId]) {
                    this.socketSubscriptions[subscriberId].push(subscriptionId);
                } else {
                    this.socketSubscriptions[subscriberId] = [subscriptionId];
                }
            }).catch(() => { });
        return subject;
    }

    unsubscribeFromMetrics(subscriberId: string): void {
        let subscriptions: number[] = this.socketSubscriptions[subscriberId];
        if (subscriptions?.length) {
            subscriptions.forEach(s => {
                this.socketService.delete(s);
            });
            delete this.socketSubscriptions[subscriberId];
        }
    }

}