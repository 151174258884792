import { Component, Inject, forwardRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { OrganizationAddDialogComponent } from "../../dashboard-area/organization/organization-add-dialog/organization-add-dialog.component";
import { Metric, ThingDefinition } from "../../model";
import { MetricService } from "../../service/metric.service";

@Component({
    selector: 'data-export-widget-add-thing-definition-dialog',
    template: require('./data-export-widget-add-thing-definition-dialog.component.html')
})
export class DataExportWidgetAddThingDefinitionDialog {

    thingDefinitions: ThingDefinition[] = [];
    thingDefinitionControl = new FormControl({ value: null });
    metricsControl = new FormControl({ value: null, disabled: true });
    metrics: Metric[] = [];
    isThingContext: boolean;

    private thingDefinitionSub: Subscription;

    constructor(
        @Inject(forwardRef(() => MetricService)) private metricService: MetricService,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<OrganizationAddDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.isThingContext = !!data.currentThing;
        this.thingDefinitions = data.thingDefinitions;
        this.initSubscriptions();
        if (data.selectedElement) {
            this.thingDefinitionControl.setValue(data.selectedElement.exportThingDef.id);
            const metricIds = data.selectedElement.exportMetrics.map(m => m.id);
            this.metricsControl.setValue(metricIds);
        } else if (this.isThingContext) {
            this.thingDefinitions.filter(t => t.id == data.currentThing.thingDefinitionId);
            this.thingDefinitionControl.setValue(data.currentThing.thingDefinitionId);
        }
    }

    ngOnDestroy(): void {
        if (this.thingDefinitionSub) {
            this.thingDefinitionSub.unsubscribe();
        }
    }

    private initSubscriptions(): void {
        this.thingDefinitionSub = this.thingDefinitionControl.valueChanges.subscribe(thingDefinitionId => {
            this.metrics = [];
            this.metricsControl.reset();
            if (thingDefinitionId) {
                this.metricService.getMetricsByThingDefinitionId(thingDefinitionId).then(metrics => {
                    this.metrics = metrics;
                    this.metricsControl.enable();
                });
            } else {
                this.metricsControl.disable();
            }
        });
    }

    addThingDefinition(): void {
        const thingDefId = this.thingDefinitionControl.value;
        const thingDefinition: ThingDefinition = this.thingDefinitions.find(td => td.id == thingDefId);
        const metricIds = this.metricsControl.value;
        const metrics: Metric[] = metricIds.map(metricId => this.metrics.find(m => m.id == metricId));
        const body = {
            exportThingDef: thingDefinition,
            exportMetrics: metrics,
            metricNamesLabel: metrics.map(m => m.label || m.name).join(', ')
        }
        this.dialogRef.close(body);
    }

    isFormValid(): boolean {
        const thingDefId = this.thingDefinitionControl.value;
        const metricIds = this.metricsControl.value;
        return thingDefId && metricIds?.length;
    }

}