import { AfterContentInit, ContentChild, ContentChildren, Directive, forwardRef, Inject, Input, OnDestroy, OnInit, QueryList } from "@angular/core";
import { DetailsWidgetData, Metric, Value } from "../../model";
import { LocationMetric } from "../../model/location-metric";
import { CustomPropertyType } from '../../service/custom-property.service';
import { DatetimeFormatterPipe, FileSizeFormatterPipe, LocalizationPipe } from "../../shared/pipe";
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { DetailsWidgetService } from "./details-widget.service";
import { WidgetWithLink } from "./widget-with-link";
import { WidgetTitleComponent } from "../../shared/component/widget-title/widget-title.component";

@Directive()
export abstract class DetailsWidget<T> extends WidgetWithLink implements AfterContentInit, OnDestroy, OnInit {

    @Input() maxHeight: string;

    @Input() showHeader: boolean;

    @Input() title: string;

    @Input() startDate: number;

    @Input() endDate: number;

    @Input() columns: number = 1;

    @Input() layout: LayoutType = LayoutType.TABLE;

    @ContentChildren(COMPONENT_DEFINITION_REF) rows: QueryList<any>;

    @ContentChild(WidgetTitleComponent) widgetTitle: WidgetTitleComponent;

    visible: boolean;

    constructor(
        @Inject(forwardRef(() => DatetimeFormatterPipe)) private dateTimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => FileSizeFormatterPipe)) private fileSizeFormatterPipe: FileSizeFormatterPipe,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => DetailsWidgetService)) private detailsWidgetService: DetailsWidgetService<T>
    ) { super(); }

    data: DetailsWidgetData[];
    objectContextId: string;
    element: T;
    metrics: Promise<Metric[]> = Promise.resolve(null);
    locationMetrics: Promise<LocationMetric[]> = Promise.resolve([]);
    rowsIndexes: Array<number[]>;

    protected _metrics: Metric[] = [];
    protected _locationMetrics: LocationMetric[] = [];

    ngOnDestroy() {
        this.detailsWidgetService.destroy();
    }

    ngAfterContentInit(): void {
        this.initDetailsWidget();
    }

    protected initDetailsWidget(): void {
        if (this.visible) {
            Promise.all([this.metrics, this.locationMetrics]).then(metrics => {
                this._metrics = metrics[0] || [];
                this._locationMetrics = metrics[1] || [];
                this.data = this.detailsWidgetService.init(this.rows, this.element, this.startDate, this.endDate, metrics[0], metrics[1]);
                this.buildRowsIndexes();
                if (this.widgetTitle) {
                    this.title = this.widgetTitle.getTitle(this.element);
                }
                this.initializationDataCallback();
            });
        }
    }

    isLastSingleElement(index: number): boolean {
        if (!(this.data.length % this.columns)) {
            return false;
        }
        return index == (this.data.length - 1);
    }

    private buildRowsIndexes(): void {
        let numberOfRows = Math.ceil(this.data.length / this.columns);
        let rowsIndexes = new Array(numberOfRows).fill(null).map(() => []);

        for (let index = 0; index < this.data.length; index++) {
            let row = index % numberOfRows;
            rowsIndexes[row].push(index);
        }
        this.rowsIndexes = rowsIndexes;
    }

    abstract ngOnInit(): void;

    abstract initializationDataCallback(): void;

    isValuePresent(value: any): boolean {
        return value + '' != 'N/A';
    }

    getFileName(value: Value | string | any): any {
        try {
            let obj;
            if (value && (value as Value).value) {
                obj = JSON.parse((value as Value).value);
            } else {
                obj = JSON.parse(value as string);
            }
            return obj['fileName'];
        } catch {
            return 'N/A';
        }
    }

    getTitle(value: Value | string): string {
        try {
            let obj;
            let lastUpdateString = "";
            if (value && (value as Value).value) {
                obj = JSON.parse((value as Value).value);
                let lastUpdate = this.localizationPipe.transform("Last update");
                let date = this.dateTimeFormatterPipe.transform((value as Value).timestamp, null, null);
                lastUpdateString = `${lastUpdate}: ${date}\n`;
            } else {
                obj = JSON.parse(value as string);
            }
            let fileSize = this.localizationPipe.transform("File size");
            let length = this.fileSizeFormatterPipe.transform(obj['length']);
            return lastUpdateString + `${fileSize}: ${length}`;
        } catch {
            return null;
        }
    }

    downloadFile(metricNameOrPropertyId: string, value: Value | any, customPropertyType: CustomPropertyType, objId: string): void {
        if (value && (value as Value).value) {
            this.detailsWidgetService.downloadFileMetric(metricNameOrPropertyId, this.objectContextId, value.timestamp);
        } else {
            this.detailsWidgetService.downloadFileProperty(objId, metricNameOrPropertyId, customPropertyType);
        }
    }

    isAtLeastOneLabelVisible(indexes: number[]): boolean {
        return indexes.some(i => this.data[i].showLabel);
    }
}

export enum LayoutType {
    TABLE = "TABLE",
    ALTERNATE_ROWS = "ALTERNATE_ROWS"
}