export class ConnectionMapping {
    type: ConnectionMappingType;
    baseRestUrl: string;
    mqttBrokerUrl: string;
    username: string;
    password: string;
    payloadFormat: PayloadFormat;
    dataRetentionTtl: number;
    accountName: string;
    accountKey: string;
    namespace: string;
    thingProvisioningPolicy: ThingProvisioningPolicy;
    registryMetricPath: string;
    registryMetricName: string;
    maxParameterCountPerMessage: number;
    assetIds: string | string[];
    brokerHost: string;
    brokerVhost: string;
    brokerPort: string;
    brokerUsername: string;
    brokerPassword: string;
    backendUsername: string;
    backendPassword: string;
    truststoreCertificatePresent: boolean;
    truststorePassword: string;
    keystoreCertificatePresent: boolean;
    keystorePassword: string;
    queueName: string;
    status: { severity: string, details: string };
}

export enum ConnectionMappingType {
    EDC = 'EDC',
    SEMIOTY_THING_CONNECTOR = 'SEMIOTY_THING_CONNECTOR',
    ALLEANTIA = 'ALLEANTIA',
    LEMONBEAT = 'LEMONBEAT',
    AZURE_SERVICE_BUS = 'AZURE_SERVICE_BUS',
    JMF = 'JMF',
    AWS_IOT_CORE = 'AWS_IOT_CORE',
    HTTP_CONNECTOR = 'HTTP_CONNECTOR'
};

export enum PayloadFormat {
    JSON = 'JSON',
    UNIQLOUD = 'UNIQLOUD'
}

export enum ThingProvisioningPolicy {
    PLUG_AND_PLAY = 'PLUG_AND_PLAY'
}