import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CustomPropertyDefinition, Location, MaintenanceWork } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { ModalComponent } from '../../shared/component';

@Component({
	selector: 'maintenance-registry-details',
	template: require('./maintenance-registry-details.component.html'),
	styles: [`
    td.maintenance-actions div {
      float: right;
      border-left: 1px #d2d6de dotted;
      padding: 5px 15px;
      cursor: pointer;
    }
    td.maintenance-actions div:hover {
      color: white;
      background-color: #64c9d1;
    }
    td.maintenance-actions {
      background-color: white;
      padding: 0;
    }
  `]
})
export class MaintenanceRegistryDetailsComponent implements OnInit {

	@Input() maintenanceWork: MaintenanceWork;

	@Input() writePermission: boolean;

	@Input() displayedColumnNames: string[];

	@Input() location: Location;

	@Output() editAction = new EventEmitter();

	@Output() deleteAction = new EventEmitter();

	@ViewChild('confirmDelete') private confirmDelete: ModalComponent;

	timezone: string;
	workTypes: string;
	maintenanceWorkProperties: CustomPropertyDefinition[] = [];

	constructor(
		@Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
		@Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService
	) { }

	ngOnInit(): void {
		this.timezone = this.authenticationService.getUser().timezone;
		this.workTypes = this.authenticationService.getTenant().maintenanceWorkTypes;
		this.maintenanceWorkProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.MaintenanceWork).filter(prop => prop.type != 'FILE' &&
			(prop.maintenanceWorkTypes == null || !prop.maintenanceWorkTypes.length || prop.maintenanceWorkTypes.includes(this.maintenanceWork.type))
			&& !this.displayedColumnNames.includes('properties.' + prop.name));
	}

	edit(): void {
		this.editAction.emit(this.maintenanceWork);
	}

	openConfirmDelete(): void {
		this.confirmDelete.show();
	}

	delete(): void {
		this.deleteAction.emit(this.maintenanceWork.id);
		this.confirmDelete.hide();
	}

	cancel(): void {
		this.confirmDelete.hide();
	}

	isPropertyVisible(name): boolean {
		return !this.displayedColumnNames.includes(name);
	}
}
