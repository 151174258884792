import { Component, OnInit } from "@angular/core";
import { IconTableColumn, IconValue } from "../custom-table-column/icon-table-column";
import { ClickableTableValueComponent } from "./clickable-table-value.component";

@Component({
    selector: 'icon-table-value',
    template: `
        <fa-icon *ngIf="iconValue?.isFontAwesome && isVisible()" [icon]="iconValue?.fontAwesomeIcon" [custom-title]="title" (click)="emitButtonAction($event)"
            [ngStyle]="style" (mouseover)="changeHover(true)" (mouseleave)="changeHover(false)" [matTooltip]="tooltip"></fa-icon>
        <span *ngIf="!iconValue?.isFontAwesome && isVisible()" [innerHTML]="iconValue?.customIconHtml" [custom-title]="title" [ngClass]="iconValue?.customIconClass" (click)="emitButtonAction($event)"
            [ngStyle]="style" (mouseover)="changeHover(true)" (mouseleave)="changeHover(false)" [matTooltip]="tooltip"></span>
    `
})
export class IconTableValueComponent extends ClickableTableValueComponent<IconTableColumn> implements OnInit {

    iconValue: IconValue;
    title: string = '';
    style: { [klass: string]: any; };
    tooltip: string;

    changeHover(hover: boolean): void {
        if (hover && this.iconValue.iconStyleHover) {
            this.style = this.iconValue.iconStyleHover;
        } else {
            this.style = this.iconValue.iconStyle;
        }
    }

    ngOnInit(): void {
        this.iconValue = this.column.iconValueMap[this.getValue() + ""];
        this.style = this.iconValue ? this.iconValue.iconStyle : null;
        if (!this.iconValue) {
            this.iconValue = this.column.iconValueMap["_default"];
        }
        if (!this.column.hideTitle) {
            this.title = this.getValue();
        }
        if (this.iconValue?.tooltipPath) {
            this.tooltip = this.element[this.iconValue.tooltipPath];
        }
        this.style = this.iconValue.iconStyle;
    }
}
