import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkSession } from '../../model';
import { ContextService } from '../../service/context.service';
import { ThingContextService } from '../../service/thing-context.service';
import { ThingService } from '../../service/thing.service';
import { UiService } from '../../service/ui.service';
import { AlertWorkSessionDetailsPageDialogComponent } from '../alert-work-session-details-page-dialog/alert-work-session-details-page-dialog.component';

@Component({
    selector: 'work-session-details-page-dialog',
    template: `
        <h2 mat-dialog-title class="d-flex align-items-start">
            <div *ngIf="element" class="d-flex flex-column">
                <div [custom-label]="element?.title"></div>
                <small *ngIf="element?.description" [custom-label]="element.description"></small>
            </div>
            <button mat-icon-button mat-dialog-close class="ml-auto">
                <mat-icon>close</mat-icon>
            </button>
        </h2>
        <mat-dialog-content>
            <alert-work-session-details-page-dialog-content *ngIf="templateName && loaded" [templateName]="templateName" [thingDefinitionId]="thingDefinitionId"></alert-work-session-details-page-dialog-content>
        </mat-dialog-content>
    `
})
export class WorkSessionDetailsPageDialogComponent extends AlertWorkSessionDetailsPageDialogComponent<WorkSession> implements OnInit {

    constructor(
        @Inject(forwardRef(() => UiService)) uiService: UiService,
        @Inject(forwardRef(() => ThingService)) thingService: ThingService,
        @Inject(forwardRef(() => ContextService)) context: ContextService,
        @Inject(forwardRef(() => ThingContextService)) thingContextService: ThingContextService,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        super(uiService, thingService, context, thingContextService, data);
    }

    ngOnInit() {
        this.saveCurrentContext();
        this.init(this.elementId);
    }

    ngOnDestroy(): void {
        this.resetContext();
    }

    init(workSessionId: string) {
        this.getElementById(workSessionId).then(workSession => {
            this.element = workSession;
            this.thingDefinitionId = this.element.thing?.thingDefinitionId;
            this.templateName = this.element.templateName;
            this.updateContext(this.element.thing.id, 'Work sessions');
        });
    }

    protected getElementById(elementId: string): Promise<WorkSession> {
        if (this.isHistorical) {
            return this.thingService.getHistoricalWorkSessionById(elementId);
        } else {
            return this.thingService.getWorkSessionById(elementId);
        }
    }

    protected resetElementContext(): void {
        this.thingContextService.resetCurrentWorkSession();
    }
}