
export class ActionDefinition {
    public id: string;
    public name: string;
    public description: string;
    public topic: ActionTopic;
    public priority: string;
    public iconUrl: string;
    public userTypeIds: string[];
    public event: string;
    public alertDefinitionIds: string[];
    public duePeriodTime: number;
    public duePeriodTimeUnit: string;
    public thingDefinitionId: string;
    public userTypeFiltered: boolean;
    public activationType: ActivationType;
    public period: ActivationPeriod;
    public periodCount: number;
    public dayOfMonth: number;
    public reschedulable: boolean;
    public dayOfWeek: number;
    public technicalDescription: string;
}

export enum ActionTopic {
    CONSUMABLES = "CONSUMABLES",
    PERFORMANCE = "PERFORMANCE",
    MAINTENANCE = "MAINTENANCE",
    OPERATIONS = "OPERATIONS"
}

export enum ActionPriority {
    LOW = "LOW",
    NORMAL = "NORMAL",
    HIGH = "HIGH"
}

export enum ActivationType {
    EVENT = "EVENT",
    PERIOD = "PERIOD"
}

export enum ActivationPeriod {
    DAILY = "DAILY",
    MONTHLY = "MONTHLY",
    WEEKLY = "WEEKLY"
}